import { CoreInterfaces } from "../Models";
import * as Constants from "../Constants";

import { QuestionsConfiguration } from "./Questions.config";
import { TasksConfiguration } from "./Tasks.config";
import { AdditionalCosts } from "./AdditionalCosts.config";

export const servicesConfiguration: Array<CoreInterfaces.ServiceConfiguration> =
  [
    // General information
    {
      Code: Constants.ServiceCode.GeneralInformation,
      Questions: QuestionsConfiguration.GeneralInformation,
      Tasks: TasksConfiguration.GeneralInformation,
      IsSelectedByDefault: true,
      SideMenu: {
        IsVisible: false,
      },
      ServicesPage: {
        IsVisible: false,
        IsSelectable: false,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: false,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: false,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: null,
      AdditionalCosts: AdditionalCosts.GeneralInformation,
      ServiceRecurringType: null,
      ServiceGroup: null,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: false,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 1
    {
      Code: Constants.ServiceCode.AccountsReceivable,
      Questions: QuestionsConfiguration.AccountsReceivable,
      Tasks: TasksConfiguration.AccountsReceivable,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      CalculationVariables: {
        Amount: Constants.AccountReceivableQuestion.Q0101,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: true,
        HasAverageTime: true,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: true,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      AdditionalCosts: AdditionalCosts.AccountsReceivable,
      ServiceRecurringType: Constants.ServiceRecurringType.Monthly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 2
    {
      Code: Constants.ServiceCode.AccountsPayable,
      Questions: QuestionsConfiguration.AccountsPayable,
      IsSelectedByDefault: false,
      Tasks: TasksConfiguration.AccountsPayable,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      CalculationVariables: {
        Amount: Constants.AccountPayableQuestion.Q0201,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: true,
        HasAverageTime: true,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: true,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      AdditionalCosts: AdditionalCosts.AccountsPayable,
      ServiceRecurringType: Constants.ServiceRecurringType.Monthly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 3
    {
      Code: Constants.ServiceCode.CorporateCardManagement,
      Questions: QuestionsConfiguration.CorporateCardManagement,
      Tasks: TasksConfiguration.CorporateCardManagement,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      CalculationVariables: {
        Amount: Constants.CorporateCardManagementQuestion.Q0301,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: true,
        HasAverageTime: true,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: true,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      AdditionalCosts: AdditionalCosts.CorporateCardManagement,
      ServiceRecurringType: Constants.ServiceRecurringType.Monthly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 4
    {
      Code: Constants.ServiceCode.OtherAccountAndReconciliation,
      Questions: QuestionsConfiguration.OtherAccountAndReconciliation,
      Tasks: TasksConfiguration.OtherAccountAndReconciliation,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      CalculationVariables: {
        Amount: Constants.OtherAccountAndReconciliationQuestions.Q0404,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: true,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      AdditionalCosts: AdditionalCosts.OtherAccountAndReconciliation,
      ServiceRecurringType: Constants.ServiceRecurringType.Monthly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 5
    {
      Code: Constants.ServiceCode.PeriodReporting,
      Questions: QuestionsConfiguration.PeriodReporting,
      Tasks: TasksConfiguration.PeriodReporting,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      CalculationVariables: {
        ContactPersonQuestion: Constants.PeriodReportingQuestion.Q0513,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: true,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      AdditionalCosts: AdditionalCosts.PeriodReporting,
      ServiceRecurringType: Constants.ServiceRecurringType.Monthly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 5b
    {
      Code: Constants.ServiceCode.InterimListedCompanies,
      Questions: QuestionsConfiguration.InterimListedCompanies,
      Tasks: TasksConfiguration.InterimListedCompanies,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: false,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: {
        ContactPersonQuestion: Constants.PeriodReportingQuestion.Q0513,
      },
      AdditionalCosts: AdditionalCosts.InterimListedCompanies,
      ServiceRecurringType: Constants.ServiceRecurringType.Quarterly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 6
    {
      Code: Constants.ServiceCode.AnnualReporting,
      Questions: QuestionsConfiguration.AnnualReporting,
      Tasks: TasksConfiguration.AnnualReporting,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: true,
        HasCustomTextForTotalNumberOfHours: true,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: false,
        HasTableHeader: true,
        HasTotalCost: false,
        IsDividedByTaskServiceGroup: true,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: true,
      },
      CalculationVariables: {
        ContactPersonQuestion: Constants.AnnualReportingQuestions.Q0608,
      },
      AdditionalCosts: AdditionalCosts.AnnualReporting,
      ServiceRecurringType: Constants.ServiceRecurringType.Yearly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.AnnualReport,
          IsDefault: false,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.StatutoryAnnualReport,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.AnnualReportV2,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.CorporateIncomeTax,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.AnnualReportV3,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
      ],
    },
    // Service 6b
    {
      Code: Constants.ServiceCode.AnnualReportingListedCompanies,
      Questions: QuestionsConfiguration.AnnualReportingListedCompanies,
      Tasks: TasksConfiguration.AnnualReportingListedCompanies,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: false,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: false,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: {
        ContactPersonQuestion: Constants.AnnualReportingQuestions.Q0608,
      },
      AdditionalCosts: AdditionalCosts.AnnualReportingListedCompanies,
      ServiceRecurringType: Constants.ServiceRecurringType.Yearly,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 7
    {
      Code: Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
      Questions:
        QuestionsConfiguration.PayrollAndExpenseAndTravelInvoiceManagement,
      Tasks: TasksConfiguration.PayrollAndExpenseAndTravelInvoiceManagement,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: true,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: true,
      },
      CalculationVariables: {
        Amount:
          Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      AdditionalCosts:
        AdditionalCosts.PayrollAndExpenseAndTravelInvoiceManagement,
      ServiceRecurringType: null,
      ServiceGroup: Constants.ServiceGroup.Payroll,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.MonthlyPayrollRun,
          IsDefault: false,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.MonthlyPayrollReconciliation,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.PayrollServicesInAddition,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
        {
          Code: Constants.TaskGroup.MonthlyReportingPayroll,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },

        {
          Code: Constants.TaskGroup.AdditionalPayrollServices,
          IsDefault: false,
          HasTableHeader: false,
          AllowNewTask: true,
          HasTitleVisible: true,
        },
      ],
    },
    //Service 7 T0742->T0750
    {
      Code: Constants.ServiceCode.AnnualPayrollRoutines,
      Questions: [],
      Tasks: TasksConfiguration.AnnualPayrollRoutines,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: false,
        IsSelectable: false,
      },
      QuestionsPage: {
        IsVisible: false,
        HasPanelHeader: false,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      SideMenu: {
        IsVisible: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: null,
      AdditionalCosts: AdditionalCosts.AnnualPayrollRoutines,
      ServiceRecurringType: Constants.ServiceRecurringType.Yearly,
      ServiceGroup: Constants.ServiceGroup.Payroll,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 8
    {
      Code: Constants.ServiceCode.StartUpAccounting,
      Questions: QuestionsConfiguration.StartUpAccounting,
      Tasks: TasksConfiguration.StartUpAccounting,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: true,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: null,
      AdditionalCosts: AdditionalCosts.StartUpAccounting,
      ServiceRecurringType: Constants.ServiceRecurringType.OneTime,
      ServiceGroup: Constants.ServiceGroup.Accounting,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    // Service 9
    {
      Code: Constants.ServiceCode.StartUpPayroll,
      Questions: QuestionsConfiguration.StartUpPayroll,
      Tasks: [],
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: true,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: false,
        HasTableHeader: false,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: {
        TotalWorkCost: Constants.StartUpPayrollQuestions.Q0902,
      },
      AdditionalCosts: AdditionalCosts.StartUpPayroll,
      ServiceRecurringType: Constants.ServiceRecurringType.OneTime,
      ServiceGroup: Constants.ServiceGroup.Payroll,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    //Yearly
    {
      Code: Constants.ServiceCode.YearlyInternalDocumentationAndFormalities,
      Questions:
        QuestionsConfiguration.YearlyInternalDocumentationAndFormalities,
      Tasks: TasksConfiguration.YearlyInternalDocumentationAndFormalities,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: false,
        IsSelectable: false,
      },
      SideMenu: {
        IsVisible: false,
      },
      QuestionsPage: {
        IsVisible: false,
        HasPanelHeader: true,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: false,
        HasTableHeader: true,
        HasTotalCost: true,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: null,
      AdditionalCosts: null,
      ServiceRecurringType: Constants.ServiceRecurringType.Yearly,
      ServiceGroup: null,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: true,
          HasTitleVisible: false,
        },
      ],
    },
    //Engagement counseling
    {
      Code: Constants.ServiceCode.EngagementCounseling,
      Questions: QuestionsConfiguration.EngagementCounseling,
      Tasks: TasksConfiguration.EngagementCounseling,
      IsSelectedByDefault: false,
      ServicesPage: {
        IsVisible: true,
        IsSelectable: true,
      },
      SideMenu: {
        IsVisible: true,
      },
      QuestionsPage: {
        IsVisible: false,
        HasPanelHeader: false,
      },
      CalculationPage: {
        HasCustomTitleOnStaffingPanel: false,
        HasCustomTextForTotalNumberOfHours: false,
        HasCustomOtherTitlesOnServicePage: false,
      },
      AllocationSummaryPanel: {
        HasAveragePrice: false,
        HasAverageTime: false,
        HasAdjustmentOfCost: false,
        HasTableHeader: false,
        HasTotalCost: false,
        IsDividedByTaskServiceGroup: false,
        ShouldIncludeAdditionalCosts: false,
      },
      OtherServicesTotalsPanel: {
        IsTotalCostOverridenByAdjustment: false,
      },
      CalculationVariables: null,
      AdditionalCosts: AdditionalCosts.EngagementCounseling,
      ServiceRecurringType: null,
      ServiceGroup: null,
      ServiceTaskGroups: [
        {
          Code: Constants.TaskGroup.DefaultGroup,
          IsDefault: true,
          HasTableHeader: true,
          AllowNewTask: false,
          HasTitleVisible: false,
        },
      ],
    },
  ];
