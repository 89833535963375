import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CorePropsInterfaces } from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import { useTranslation } from "react-i18next";

export default function GtEngagementLetterMenu({
  engagementLetter,
  onToggleActiveStatus,
}: CorePropsInterfaces.GtLetterMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (
    letterSummaryAction: Constants.LetterSummaryActions
  ) => {
    setAnchorEl(null);
    switch (letterSummaryAction) {
      case Constants.LetterSummaryActions.Activate:
      case Constants.LetterSummaryActions.Deactivate: {
        onToggleActiveStatus(!engagementLetter.IsActive);
        break;
      }
    }
  };
  const options = engagementLetter.IsActive
    ? [Constants.LetterSummaryActions.Deactivate]
    : [Constants.LetterSummaryActions.Activate];

  return (
    <article className="gt-taskMenu">
      <IconButton
        className="gt-taskMenu__menuButton"
        aria-label="more"
        id="task-menu-button"
        aria-controls={open ? "task-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="task-menu"
        MenuListProps={{
          "aria-labelledby": "task-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
            {t(`General.${option}LetterStatus`)}
          </MenuItem>
        ))}
      </Menu>
    </article>
  );
}
