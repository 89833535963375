import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { CoreInterfaces, DTOs } from "./../../core/Models";
import { AppContext } from "../../contexts/AppContext";
import ServiceRow from "../../components/Services/ServiceRow";
import * as Constants from "./../../core/Constants";
import GtNavigationButtons from "src/components/Common/GtNavigationButtons";
import GtDialog from "src/components/Common/GtDialog";
import Warning from "src/components/Icons/Warning";
import { isFinalorOutdatedVersion } from "src/utils";

function ServicesPage(): JSX.Element {
  const { t } = useTranslation();
  const { globalState, dispatch } = useContext(AppContext);

  const initialPageState: CoreInterfaces.ServicesPageState = {
    title: "Pages.Services.Title",
    isServiceUnselectModalOpenState: false,
    interactionService: null,
  };
  const [pageState, setPageState] =
    useState<CoreInterfaces.ServicesPageState>(initialPageState);

  const handleServiceSelectChange = (
    serviceDTO: DTOs.ServiceDTO,
    confirmed: boolean
  ) => {
    const nextSelectionState = !serviceDTO.state.isSelected;
    if (!nextSelectionState && !confirmed) {
      updateServiceUnselectModalOpenState(true, serviceDTO);
    } else {
      const nextService: DTOs.ServiceDTO = {
        ...serviceDTO,
        state: {
          ...serviceDTO.state,
          isSelected: nextSelectionState,
        },
      };
      updateServiceUnselectModalOpenState(false, null);
      dispatch({
        type: Constants.AppStateActions.ServiceUpdated,
        payload: nextService,
      });
    }
  };

  const updateServiceUnselectModalOpenState = (
    nextVisibilityState: boolean,
    interactionService?: DTOs.ServiceDTO
  ) => {
    setPageState((currentState) => {
      const nextPageState: CoreInterfaces.ServicesPageState = {
        ...currentState,
        isServiceUnselectModalOpenState: nextVisibilityState,
        interactionService: null,
      };
      if (!!interactionService) {
        nextPageState.interactionService = interactionService;
      }
      return nextPageState;
    });
  };
  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);

  useEffect(() => {
    document.title = t(pageState.title);
  }, []);

  const serviceUnselectConfirmDTO: DTOs.GtDialogDTO = {
    data: {
      title: <Warning fontSize="medium" />,
      maxWidth: "sm",
      rightButtons: [
        <Button
          onClick={() => updateServiceUnselectModalOpenState(false)}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          onClick={() =>
            handleServiceSelectChange(pageState.interactionService, true)
          }
          variant="contained"
          color="warning"
          key="confirmBtn"
        >
          {t("General.Uncheck")}
        </Button>,
      ],
    },
    api: {
      onClose: () => updateServiceUnselectModalOpenState(false),
    },
    state: {
      isOpen: pageState.isServiceUnselectModalOpenState,
      isFullWidth: true,
    },
  };

  return (
    <>
      <article className="gt-servicesPage">
        <Typography className="gt-pageTitle" variant="h5" gutterBottom={true}>
          {t(pageState.title)}
        </Typography>
        <section className="gt-servicesPage__servicesContainer">
          {globalState?.services
            .filter(
              (serviceDTO: DTOs.ServiceDTO) =>
                serviceDTO.state.isVisibleInServicesPage
            )
            .map((serviceDTO: DTOs.ServiceDTO) => (
              <ServiceRow
                key={serviceDTO.data.code}
                isDisabled={isFinalorOutdatedVersionStatus}
                service={serviceDTO}
                onSelectionChange={(serviceDTO: DTOs.ServiceDTO) =>
                  handleServiceSelectChange(serviceDTO, false)
                }
              />
            ))}
        </section>
        <GtNavigationButtons current={0} />
      </article>
      <GtDialog gtDialogDTO={serviceUnselectConfirmDTO}>
        <article className="gt-unselectModalContent">
          <section className="gt-unselectModalContent__title">
            <div>{t("General.Warning")}</div>
            <div>{t("General.UnselectServiceWarning")}</div>
          </section>
          <section className="gt-unselectModalContent__content">
            {t("General.UnselectServiceInfo")}
          </section>
        </article>
      </GtDialog>
    </>
  );
}

export default ServicesPage;
