import { trim } from "lodash";
import { CoreInterfaces } from "./../core/Models";
import * as Constants from "src/core/Constants";
import { fetchData, formContactPersonItemFromBEEmployee } from "src/utils";

export function fetchEngagementConfigurationData(
  businessOpportunityId: string,
  engagementConfigurationId: string = null
): Promise<CoreInterfaces.EngagementConfigurationReadPack> {
  return fetchData<CoreInterfaces.EngagementConfigurationReadPack>(
    `${Constants.APIPath.EngagementConfiguration}/${businessOpportunityId}${
      engagementConfigurationId ? "/" + engagementConfigurationId : ""
    }`
  );
}

export function fetchApplicationConfigurationData(): Promise<CoreInterfaces.ApplicationConfigurationReadPack> {
  return fetchData<CoreInterfaces.ApplicationConfigurationReadPack>(
    `${Constants.APIPath.ApplicationConfiguration}`
  );
}

export function fetchCustomerContactPersons(
  url: string
): Promise<Array<CoreInterfaces.ContactPersonItem>> {
  return fetchData<Array<CoreInterfaces.ContactPerson>>(`${url}`).then(
    (serverReturn: Array<CoreInterfaces.ContactPerson>) => {
      if (!!serverReturn) {
        return serverReturn.map((item) => ({
          key: `${item.ContactId}`,
          value: `${trim(`${item.FirstName} ${item.LastName}`)} <${
            item.Email
          }>`,
          untranslatable: true,
          email: item.Email,
          firstName: item.FirstName,
          lastName: item.LastName,
          office: null,
          businessUnit: null,
        }));
      }
    }
  );
}

export function fetchGtContactPersons(): Promise<
  Array<CoreInterfaces.ContactPersonItem>
> {
  return fetchData<Array<CoreInterfaces.BEEmployee>>(
    Constants.APIPath.Employees
  ).then((serverReturn: Array<CoreInterfaces.BEEmployee>) => {
    if (!!serverReturn) {
      return serverReturn.map((item) => {
        const contactPersonItem = formContactPersonItemFromBEEmployee(item);
        if (!!contactPersonItem) {
          contactPersonItem.value = `${trim(
            `${item.FirstName} ${item.LastName}`
          )} <${item.Email}>, [${
            item.BusinessUnit === "BusinessConsultingSolutions"
              ? "BCS"
              : item.BusinessUnit
          }]`;
        }
        return contactPersonItem;
      });
    }
  });
}

export function fetchCompanyDetails(
  url: string
): Promise<CoreInterfaces.CompanyDetails> {
  return fetchData<CoreInterfaces.CompanyDetails>(`${url}`).then(
    (serverReturn: CoreInterfaces.CompanyDetails) => serverReturn
  );
}

export function fetchBusinessOpportunityInfo(
  businessOpportunityId: string
): Promise<CoreInterfaces.BusinessOpportunityInfo> {
  return fetchData<CoreInterfaces.BusinessOpportunityInfo>(
    `${Constants.APIPath.BusinessOpportunityInfo}/${businessOpportunityId}`
  );
}

export function fetchSystemAdministrationMessages(): Promise<
  Array<CoreInterfaces.SystemAdministrationMessage>
> {
  return fetchData<Array<any>>(
    `${Constants.APIPath.NotificationMessages}`,
    Constants.APIMethod.GET
  )
    .then((data) =>
      data.map((item) => ({
        id: item.Id,
        title: item.Title,
        message: item.Content,
        link: item.Link,
        linkText: item.LinkText,
        type: item.Type,
        state: {
          isShown: true,
        },
      }))
    )
    .catch(() => []);
}

export function fetchEngagementLettersSummaryView(filterData: {}): Promise<CoreInterfaces.EngagementLettersSummary> {
  return fetchData<CoreInterfaces.EngagementLettersSummary>(
    Constants.APIPath.SearchEngagementLetters,
    Constants.APIMethod.POST,
    filterData
  );
}

export async function fetchUserRoles(): Promise<
  Array<Constants.UserRoles> | undefined
> {
  try {
    const roles = await fetchData<Array<Constants.UserRoles>>(
      Constants.APIPath.UserRoles
    );

    return roles;
  } catch (error) {
    return undefined;
  }
}

export async function fetchEmployeesOffices(): Promise<
  Array<CoreInterfaces.BEEmployee> | undefined
> {
  return fetchData<Array<CoreInterfaces.BEEmployee>>(
    Constants.APIPath.EmployeesOffices
  );
}

export async function fetchOfficeManagers(): Promise<
  Array<CoreInterfaces.OfficeManager> | undefined
> {
  try {
    const officeManager = await fetchData<Array<CoreInterfaces.OfficeManager>>(
      Constants.APIPath.OfficeManagers
    );

    return officeManager;
  } catch (error) {
    return undefined;
  }
}

export function checkMaintenanceMode(): Promise<CoreInterfaces.MaintenanceReadPack> {
  const url = `${Constants.APIPath.Maintenance}`;
  return fetchData<CoreInterfaces.MaintenanceReadPack>(url);
}
