import { t } from "i18next";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import * as Constants from "../../core/Constants";
import { useContext, useState } from "react";
import { fetchData, getRelevantGtOwners } from "src/utils";
import { useSnackBar } from "src/contexts/SnackbarContext";
import { AppContext } from "src/contexts/AppContext";
import { LoadingContext } from "src/contexts/LoadingContext";

function GtChangeOwnerDialog({
  engagementLettersSummaryItem,
  onOwnerChanged,
  onChangeOwnerDialogClose,
}: CorePropsInterfaces.GtChangeOwnerDialogProps): JSX.Element {
  const { showSnackBar } = useSnackBar();
  const [owner, setOwner] = useState(null);
  const { globalState } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const changeOwnerDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.ChangeOwnerFor", {
        companyName: engagementLettersSummaryItem.CompanyName,
      }),
      maxWidth: "sm",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onChangeOwnerDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          disabled={!owner}
          onClick={() => {
            saveOwner(engagementLettersSummaryItem, owner);
          }}
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: onChangeOwnerDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  function saveOwner(
    engagementLettersSummaryItem: CoreInterfaces.EngagementLettersSummaryItem,
    owner: CoreInterfaces.ContactPersonItem
  ): void {
    updateApplicationLoadingState(true);
    const payload: CoreInterfaces.EngagementConfigurationOwnerUpdate = {
      EngagementConfigurationId: engagementLettersSummaryItem.Id,
      BusinessOpportunityId: engagementLettersSummaryItem.BusinessOpportunityId,
      NewOwner: {
        UID: owner.key,
        BusinessUnit: owner.businessUnit,
        Email: owner.email,
        FirstName: owner.firstName,
        LastName: owner.lastName,
        Office: owner.office,
      },
    };
    fetchData(Constants.APIPath.SetOwner, Constants.APIMethod.PUT, payload)
      .then(() => {
        onOwnerChanged();
      })
      .catch(() => {
        updateApplicationLoadingState(false);
        showSnackBar(t("General.AnErrorHasOccured"), "error");
      });
  }

  return (
    <GtDialog gtDialogDTO={changeOwnerDialogDTO}>
      <article className="gt-changeOwnerDialog">
        <section className="gt-changeOwnerDialog__currentOwnerSection">
          <span>{t("General.GtOwner")}</span>
          <TextField
            variant="outlined"
            value={`${engagementLettersSummaryItem.OwnerFirstName || ""} ${
              engagementLettersSummaryItem.OwnerLastName || ""
            }`}
            disabled={true}
          />
        </section>
        <section className="gt-changeOwnerDialog__newOwnerSection">
          <span>{t("General.NewOwner")}</span>
          <Select
            value={owner?.key ?? Constants.HelpfulConstants.PleaseSelect}
            onChange={(e) => {
              const newOwner = globalState.remoteData.gtContactPersons.find(
                (item) => item.key === e.target.value
              );
              setOwner(newOwner);
            }}
          >
            <MenuItem
              key={Constants.HelpfulConstants.PleaseSelect}
              value={Constants.HelpfulConstants.PleaseSelect}
            >
              {t(`Options.${Constants.HelpfulConstants.PleaseSelect}`)}
            </MenuItem>
            {getRelevantGtOwners(globalState).map(
              (gtContactPerson: CoreInterfaces.ContactPersonItem) => (
                <MenuItem
                  key={`${gtContactPerson.key}`}
                  value={gtContactPerson.key}
                >
                  {gtContactPerson.firstName} {gtContactPerson.lastName}
                </MenuItem>
              )
            )}
          </Select>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtChangeOwnerDialog;
