import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import { useQuery } from "src/core/Hooks";
import {
  fetchData,
  fetchEngagementConfigurationData,
  formEngagementVersionDTO,
  getRelevantPageForNavigation,
  isFinalorOutdatedVersion,
  updateStateOnEngagementConfigurationRead,
  areApplicationConfVersionsChanged,
} from "src/utils";
import { AppContext } from "src/contexts/AppContext";
import GtDialog from "./GtDialog";
import { Button } from "@mui/material";
import GtManageVersion from "./GtManageVersion";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "src/contexts/LoadingContext";

function GtManageVersionsDialog({
  onManageDialogClose,
  onSaveNewConfiguration,
  onImportConfigurationOpen,
  onUpdateRatesOpen,
  onHideFromImporting,
}: CorePropsInterfaces.GtManageVersionsDialogProps): JSX.Element {
  const { globalState, dispatch } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const queryParamsMap = useQuery();
  const [engagementVersions, setEngagementVersions] = useState([]);
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );
  const isHiddenFromImporting =
    globalState.currentConfiguration.isHiddenFromImporting;
  const clientId = queryParamsMap.get(Constants.QueryParams.clientId);
  const unit = queryParamsMap.get(Constants.QueryParams.unit);

  const navigate = useNavigate();
  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);

  useEffect(() => {
    fetchEngagementVersions();
  }, []);

  const maxConfigurationVersion = Math.max(
    0,
    ...engagementVersions.map((x) => x.data.version)
  );

  const selectedEngagementVersion = engagementVersions.find(
    (item) => item.state.isSelected
  );

  const manageDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.Manage"),
      maxWidth: "md",
      leftButtons: [
        <Button
          disabled={
            !selectedEngagementVersion ||
            !selectedEngagementVersion?.state.canBeDeleted
          }
          onClick={() => deleteSelectedVersion()}
          color="error"
          variant="outlined"
          key="deleteBtn"
        >
          {t("General.DeleteVersion")}
        </Button>,
        <Button
          onClick={() => onUpdateRatesOpen()}
          color="tertiary"
          variant="contained"
          key="updateRatesBtn"
          style={{
            display:
              areApplicationConfVersionsChanged(globalState) &&
              !isFinalorOutdatedVersionStatus
                ? "flex"
                : "none",
          }}
        >
          {t("General.UpdateRates")}
        </Button>,
      ],
      rightButtons: [
        <Button
          onClick={() => onHideFromImporting()}
          color="tertiary"
          variant="contained"
          key="hideEngagementBtn"
        >
          {isHiddenFromImporting ? t("General.Unhide") : t("General.Hide")}
        </Button>,
        <Button
          onClick={() => onImportConfigurationOpen()}
          color="tertiary"
          variant="contained"
          key="importConfigurationBtn"
          disabled={!globalState.currentConfiguration.id}
          style={{ display: isFinalorOutdatedVersionStatus ? "none" : "flex" }}
        >
          {t("General.ImportConfiguration")}
        </Button>,
        <Button
          onClick={() => handleNewConfigurationSave()}
          color="tertiary"
          variant="contained"
          key="confirmBtn"
        >
          {t("General.SaveAs")}
        </Button>,
        <Button
          disabled={!selectedEngagementVersion}
          onClick={() => loadConfiguration()}
          color="tertiary"
          variant="contained"
          key="loadBtn"
        >
          {t("General.Load")}
        </Button>,
      ],
    },
    api: {
      onClose: handleManageDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  function fetchEngagementVersions(): void {
    const url = `${Constants.APIPath.EngagementVersions}/${businessOpportunityId}`;
    fetchData<Array<CoreInterfaces.EngagementVersionReadPack>>(url)
      .then((data) => {
        updateApplicationLoadingState(false);
        const engagementVersions = data.map(
          (item: CoreInterfaces.EngagementVersionReadPack) => {
            const engagementVersion: DTOs.EngagementVersionDTO =
              formEngagementVersionDTO(item);
            engagementVersion.state.isDisabled =
              engagementVersion.data.version ===
              globalState.currentConfiguration.version;

            return engagementVersion;
          }
        );
        setEngagementVersions(engagementVersions);
      })
      .catch(() => []);
  }

  function setSelectedEngagementVersion(
    engagementVersion: DTOs.EngagementVersionDTO
  ): void {
    if (engagementVersion.state.isDisabled) {
      return;
    }
    setEngagementVersions(
      (engagementVersions: Array<DTOs.EngagementVersionDTO>) => {
        return engagementVersions.map((item: DTOs.EngagementVersionDTO) => {
          return item.data.engagementConfigurationId !==
            engagementVersion.data.engagementConfigurationId
            ? {
                ...item,
                state: {
                  ...item.state,
                  isSelected: false,
                },
              }
            : {
                ...engagementVersion,
                state: {
                  ...engagementVersion.state,
                  isSelected: true,
                },
              };
        });
      }
    );
  }

  async function deleteSelectedVersion(): Promise<void> {
    updateApplicationLoadingState(true);
    try {
      await fetchData(
        Constants.APIPath.EngagementConfiguration,
        Constants.APIMethod.DELETE,
        {},
        {
          id: `${selectedEngagementVersion?.data.engagementConfigurationId}`,
          businessOpportunityId,
        }
      );
      fetchEngagementVersions();
    } catch (error) {
      updateApplicationLoadingState(false);
      onManageDialogClose({
        severity: Constants.AlertSeverity.Error,
        isDisplayed: true,
        message: "General.VersionDeleteFail",
      });
    }
  }

  function handleNewConfigurationSave(): void {
    onSaveNewConfiguration(maxConfigurationVersion);
  }

  function handleManageDialogClose(): void {
    onManageDialogClose();
  }

  function loadConfiguration() {
    updateApplicationLoadingState(true);
    fetchEngagementConfigurationData(
      businessOpportunityId,
      selectedEngagementVersion?.data.engagementConfigurationId
    )
      .then((serverReturn: CoreInterfaces.EngagementConfigurationReadPack) => {
        const {
          taskAllocationConfigurationDescription,
          taskAllocationConfigurationVersion,
        } = globalState.currentConfiguration.applicationConfiguration;
        const {
          taskCostConfigurationDescription,
          taskCostConfigurationVersion,
        } = globalState.currentConfiguration.applicationConfiguration;
        const applicationConfigurationObject: CoreInterfaces.ApplicationConfigurationReadPack =
          {
            RateCartConfiguration: null,
            TaskAllocationConfiguration: {
              Description: taskAllocationConfigurationDescription,
              Version: taskAllocationConfigurationVersion,
              TaskAllocations: globalState.taskAllocations || [],
            },
            TaskCostConfiguration: {
              Description: taskCostConfigurationDescription,
              Version: taskCostConfigurationVersion,
              TaskCosts: globalState.taskCosts || [],
            },
          };
        updateStateOnEngagementConfigurationRead(
          serverReturn,
          dispatch,
          // eslint-disable-next-line
          () => {},
          applicationConfigurationObject,
          Constants.UpdateStateOnEngagementConfigurationReadScenario
            .LoadConfiguration
        );

        const importedConfigurationStatus = serverReturn.Status;
        const nextRoutesMap = {
          [Constants.ConfigurationStatus.Active]: Constants.Routes.Question,
          [Constants.ConfigurationStatus.FinalVersion]:
            Constants.Routes.EngagementDescription,
          [Constants.ConfigurationStatus.OutdatedVersion]:
            Constants.Routes.EngagementDescription,
        };
        const relevantPageForNavigation = getRelevantPageForNavigation(
          importedConfigurationStatus,
          nextRoutesMap,
          businessOpportunityId,
          clientId,
          unit,
          serverReturn.Id
        );
        if (relevantPageForNavigation) {
          navigate(relevantPageForNavigation, { replace: true });
        } else if (serverReturn?.Id) {
          const urlSearchParams = new URLSearchParams(window.location.search);
          urlSearchParams.set(
            Constants.QueryParams.engagementConfigurationId,
            serverReturn.Id
          );
          navigate(`?${urlSearchParams}`, { replace: true });
        }

        onManageDialogClose({
          severity: Constants.AlertSeverity.Success,
          isDisplayed: true,
          message: "General.ConfigurationLoadedSuccess",
        });
        dispatch({
          type: Constants.AppStateActions.RatesChangeMessageVisibilityUpdated,
          payload: true,
        });
      })
      .catch(() => {
        onManageDialogClose({
          severity: Constants.AlertSeverity.Error,
          isDisplayed: true,
          message: "General.ConfigurationLoadedFail",
        });
      })
      .finally(() => {
        updateApplicationLoadingState(false);
      });
  }

  return (
    <GtDialog gtDialogDTO={manageDialogDTO}>
      <article className="gt-manageVersions">
        <div className="gt-manageVersions__subTitle">
          {t("General.SelectVersionForLoading")}
        </div>
        <section className="gt-manageVersions__list">
          {engagementVersions.map(
            (engagementVersion: DTOs.EngagementVersionDTO) => (
              <GtManageVersion
                key={engagementVersion.data.engagementConfigurationId}
                engagementVersion={engagementVersion}
                onSelectedEngagementVersion={() =>
                  setSelectedEngagementVersion(engagementVersion)
                }
              />
            )
          )}
        </section>
      </article>
    </GtDialog>
  );
}

export default GtManageVersionsDialog;
