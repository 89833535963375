import { t } from "i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Button } from "@mui/material";
import * as Constants from "./../../core/Constants";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import { formatDate, getEngagementTotals } from "src/utils";

function GtSetFinalDocumentDialog({
  onConfirm,
  onDialogClose,
}: CorePropsInterfaces.GtSetFinalDocumentDialogProps): JSX.Element {
  const { globalState } = useContext(AppContext);

  const validFromDate = !!globalState.currentConfiguration.validFromDate
    ? formatDate(
        globalState.currentConfiguration.validFromDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      )
    : null;
  const validUntilDate = !!globalState.currentConfiguration.validUntilDate
    ? formatDate(
        globalState.currentConfiguration.validUntilDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      )
    : null;

  const finalDocumentDialogDTO: DTOs.GtDialogDTO = {
    data: {
      maxWidth: "sm",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          onClick={onConfirm}
        >
          {t("General.Create")}
        </Button>,
      ],
      title: (
        <span className="gt-finalSectionDialogIcon">
          <NoteAddOutlinedIcon />
        </span>
      ),
    },
    api: {
      onClose: onDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  let totalEngagementCost = getEngagementTotals(globalState);
  if (totalEngagementCost === 0) {
    totalEngagementCost = Number(
      process.env.REACT_APP_MINIMAL_TOTAL_ENGAGEMENT_COST
    );
  }

  return (
    <GtDialog gtDialogDTO={finalDocumentDialogDTO}>
      <article className="gt-finalSectionDialog">
        <section className="gt-finalSectionDialog__content">
          <div className="gt-finalSectionDialog__content-question">
            {t("General.CreateFinalDocumentQuestion")}
          </div>
          <div className="gt-finalSectionDialog__content-explanation">
            {t("General.CreateFinalDocumentQuestionDetail")}
          </div>
          <div className="gt-finalSectionDialog__content-explanation">
            {t("General.SumSentToUpsalesInfo")}{" "}
            <strong className="label-inverted">{`${totalEngagementCost} ${Constants.USED_CURRENCY}`}</strong>
          </div>
          <span className="gt-finalSectionDialog__content-explanation">
            {t("General.ValidFromModalText")}
            <div className="gt-finalSectionDialog__content-explanation-validity">
              <strong>
                {validFromDate}
                {!!validUntilDate && (
                  <>
                    <span className="gt-finalSectionDialog__content-explanation-validity-dateSeparator">
                      -
                    </span>
                    {validUntilDate}
                  </>
                )}
              </strong>
            </div>
          </span>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtSetFinalDocumentDialog;
