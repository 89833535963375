import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../core/Constants";
import {
  findQuestionInService,
  resolveTaskDisplayedAllocationOfResponsibility,
} from "./shared";
import {
  getFrequencyMultiplicator,
  getServiceVariable,
  getTaskCalculationVariable,
} from "./service-utils";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";

function getTaskEstimatedTimePerUnit(taskDTO: DTOs.TaskDTO): number {
  const estimatedTimePerUnit =
    taskDTO.data.adjustments.estimatedTimePerUnit ??
    taskDTO.data.estimatedTimePerUnit;
  return Number(estimatedTimePerUnit) ?? 0;
}

const calculationMethodProcessorsMap: {
  [method in Constants.TaskCalculationMethod]?: (
    serviceDTO: DTOs.ServiceDTO,
    taskDTO: DTOs.TaskDTO
  ) => number;
} = {
  [Constants.TaskCalculationMethod.AmountXTimeXPricePerHour]: (
    serviceDTO: DTOs.ServiceDTO,
    taskDTO: DTOs.TaskDTO
  ) => {
    if (
      resolveTaskDisplayedAllocationOfResponsibility(taskDTO) ===
      Constants.TaskAllocationResponsibility.Client
    ) {
      return 0;
    }
    const amountVariable = getServiceVariable(
      serviceDTO,
      Constants.CalculationVariable.Amount
    );
    const calculationVariable = getFrequencyMultiplicator(taskDTO, serviceDTO);
    const amount = calculationVariable ?? amountVariable ?? 0;
    const estimatedTimePerUnit = getTaskEstimatedTimePerUnit(taskDTO);
    return Math.ceil(amount * estimatedTimePerUnit);
  },
  [Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour]: (
    serviceDTO: DTOs.ServiceDTO,
    taskDTO: DTOs.TaskDTO
  ) => {
    if (
      resolveTaskDisplayedAllocationOfResponsibility(taskDTO) ===
      Constants.TaskAllocationResponsibility.Client
    ) {
      return 0;
    }
    const estimatedTimePerUnit = getTaskEstimatedTimePerUnit(taskDTO);
    const frequency = !!taskDTO.data.adjustments.frequency
      ? taskDTO.data.adjustments.frequency
      : taskDTO.data.frequency;
    if (!!frequency) {
      const frequencyValue = Constants.FrequencyValueMap[frequency];
      if (frequencyValue !== undefined) {
        return Math.ceil(estimatedTimePerUnit * frequencyValue);
      }
    }
    return 0;
  },
  [Constants.TaskCalculationMethod.TimeXPricePerHour]: (
    serviceDTO: DTOs.ServiceDTO,
    taskDTO: DTOs.TaskDTO
  ) => {
    if (
      resolveTaskDisplayedAllocationOfResponsibility(taskDTO) ===
      Constants.TaskAllocationResponsibility.Client
    ) {
      return 0;
    }
    const multiplicator = getFrequencyMultiplicator(taskDTO, serviceDTO);
    const estimatedTimePerUnit = getTaskEstimatedTimePerUnit(taskDTO);

    return estimatedTimePerUnit * multiplicator;
  },
  [Constants.TaskCalculationMethod.AmountXTime]: (
    serviceDTO: DTOs.ServiceDTO,
    taskDTO: DTOs.TaskDTO
  ) => {
    if (
      resolveTaskDisplayedAllocationOfResponsibility(taskDTO) ===
      Constants.TaskAllocationResponsibility.Client
    ) {
      return 0;
    }
    return 0;
  },
  [Constants.TaskCalculationMethod.FrequencyXAmountXTime]: (
    serviceDTO: DTOs.ServiceDTO,
    taskDTO: DTOs.TaskDTO
  ) => {
    if (
      resolveTaskDisplayedAllocationOfResponsibility(taskDTO) ===
      Constants.TaskAllocationResponsibility.Client
    ) {
      return 0;
    }
    const amountVariable = getTaskCalculationVariable(serviceDTO, taskDTO);
    const multiplicator = getFrequencyMultiplicator(taskDTO, serviceDTO);
    const estimatedTimePerUnit = getTaskEstimatedTimePerUnit(taskDTO);

    return Math.ceil(
      multiplicator * Number(amountVariable) * estimatedTimePerUnit
    );
  },
};

export function calculateEstimatedTimeInMinutes(
  serviceDTO: DTOs.ServiceDTO,
  taskDTO: DTOs.TaskDTO
): number {
  const taskCostProcessor =
    calculationMethodProcessorsMap[taskDTO.data.calculationMethod];
  if (!!taskCostProcessor) {
    return taskCostProcessor(serviceDTO, taskDTO);
  }

  return 0;
}

export function handleAnnualPayrollRoutinesState(
  nextServices: DTOs.ServiceDTO[]
) {
  const annualPayrollRoutines = nextServices.find(
    (service) =>
      service.data.code === Constants.ServiceCode.AnnualPayrollRoutines
  );
  annualPayrollRoutines.state.isSelected = nextServices.some(
    (eachService) =>
      eachService.data.code ===
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement &&
      eachService.state.isSelected
  );
}

export function handleYearlyServiceState(nextServices: DTOs.ServiceDTO[]) {
  const yearlyService = nextServices.find(
    (service) =>
      service.data.code ===
      Constants.ServiceCode.YearlyInternalDocumentationAndFormalities
  );
  yearlyService.state.isSelected = nextServices.some(
    (eachService) =>
      eachService.state.isSelected && eachService.state.isVisibleInServicesPage
  );
}
export function handleInterimListedCompaniesState(
  nextServices: DTOs.ServiceDTO[]
) {
  const interimListedCompanies = nextServices.find(
    (service) =>
      service.data.code === Constants.ServiceCode.InterimListedCompanies
  );

  const isMainServiceSelected = nextServices.some(
    (eachService) =>
      eachService.data.code === Constants.ServiceCode.PeriodReporting &&
      eachService.state.isSelected
  );
  interimListedCompanies.state.canBeSelectedInServicePage =
    isMainServiceSelected;
  if (!isMainServiceSelected) {
    interimListedCompanies.state.isSelected = isMainServiceSelected;
  }
}

export function handleEngagementCounselingState(
  nextServices: DTOs.ServiceDTO[]
) {
  const engagementCounselingService = nextServices.find(
    (service) =>
      service.data.code === Constants.ServiceCode.EngagementCounseling
  );

  const anyOtherServiceSelected = nextServices.some(
    (eachService) =>
      eachService.data.code !== Constants.ServiceCode.EngagementCounseling &&
      eachService.state.isSelected &&
      eachService.state.isVisibleInServicesPage
  );

  engagementCounselingService.state.canBeSelectedInServicePage =
    !anyOtherServiceSelected;

  if (anyOtherServiceSelected) {
    engagementCounselingService.state.isSelected = false;
  }

  nextServices.forEach((service) => {
    if (
      service.data.code !== Constants.ServiceCode.EngagementCounseling &&
      service.data.code !== Constants.ServiceCode.GeneralInformation
    ) {
      if (
        engagementCounselingService.state.canBeSelectedInServicePage &&
        engagementCounselingService.state.isSelected
      ) {
        service.state.isSelected = false;
        service.state.canBeSelectedInServicePage = false;
      } else {
        service.state.canBeSelectedInServicePage = true;
      }
    }
  });
  if (!engagementCounselingService.state.isSelected) {
    handleAnnualReportingListedCompaniesState(nextServices);
    handleInterimListedCompaniesState(nextServices);
  }
}

export function handleAnnualReportingListedCompaniesState(
  nextServices: DTOs.ServiceDTO[]
) {
  const annualReportingListedCompanies = nextServices.find(
    (service) =>
      service.data.code === Constants.ServiceCode.AnnualReportingListedCompanies
  );
  const isMainServiceSelected = nextServices.some(
    (eachService) =>
      eachService.data.code === Constants.ServiceCode.AnnualReporting &&
      eachService.state.isSelected
  );
  annualReportingListedCompanies.state.canBeSelectedInServicePage =
    isMainServiceSelected;
  if (!isMainServiceSelected) {
    annualReportingListedCompanies.state.isSelected = isMainServiceSelected;
  }
}

export function handleAnnualReportingState(nextServices: DTOs.ServiceDTO[]) {
  const annualReporting = nextServices.find(
    (service) => service.data.code === Constants.ServiceCode.AnnualReporting
  );
  const service4DTO = nextServices.find(
    (serviceDTO) =>
      serviceDTO.data.code ===
        Constants.ServiceCode.OtherAccountAndReconciliation &&
      serviceDTO.state.isSelected
  );
  const taskDTOs = annualReporting.data.tasks.filter(
    (taskDTO) =>
      taskDTO.data.code === Constants.AnnualReportingTasks.T0612 ||
      taskDTO.data.code === Constants.AnnualReportingTasks.T0613
  );

  if (!!service4DTO) {
    const calculationQuestionOverride = findQuestionInService(
      service4DTO,
      Constants.OtherAccountAndReconciliationQuestions.Q0405
    );

    taskDTOs.forEach((taskDTO) => {
      taskDTO.data.calculationQuestionOverride = calculationQuestionOverride;
    });
  } else {
    taskDTOs.forEach((taskDTO) => {
      taskDTO.data.calculationQuestionOverride = null;
    });
  }
}

export function setEngagementConfigurationOwner(
  intermediateState: CoreInterfaces.AppState,
  question0003: DTOs.QuestionDTO
): void {
  const employee: CoreInterfaces.ContactPersonItem =
    intermediateState.remoteData.gtContactPersons.find(
      (eachContactPerson) =>
        eachContactPerson.key === question0003.data.userValue
    );
  intermediateState.currentConfiguration.owner = employee;
}

export function isServiceTaskGroupSelected(
  questionDTO: DTOs.QuestionDTO,
  serviceTaskGroup: Constants.PartOfAnnualAccounts
) {
  return (
    questionDTO &&
    (questionDTO.data.userValue as string).includes(serviceTaskGroup)
  );
}

export function arrayMove(
  inputArray: Array<any>,
  oldIndex: number,
  newIndex: number
) {
  const arrCopy = [...inputArray];
  if (newIndex >= arrCopy.length) {
    let k = newIndex - arrCopy.length + 1;
    while (k--) {
      arrCopy.push(undefined);
    }
  }
  arrCopy.splice(newIndex, 0, arrCopy.splice(oldIndex, 1)[0]);

  return arrCopy;
}

export function onTaskOrderChange(
  serviceDTO: DTOs.ServiceDTO,
  params: GridRowOrderChangeParams,
  visibleRows: Array<
    | CoreInterfaces.CalculationServiceTableRow
    | CoreInterfaces.EngagementServiceTableRow
  >,
  dispatchFn: Function
) {
  const previousTargetTaskCode =
    params.targetIndex < params.oldIndex
      ? visibleRows[params.targetIndex - 1]?.taskDTO.data.code
      : visibleRows[params.targetIndex]?.taskDTO.data.code;
  dispatchFn({
    type: Constants.AppStateActions.TaskOrderChange,
    payload: {
      serviceDTO,
      taskDTO: params.row.taskDTO,
      previousTargetTaskCode,
    },
  });
}
