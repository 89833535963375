import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import {
  ComponentStates,
  CoreInterfaces,
  CorePropsInterfaces,
  DTOs,
} from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import { useQuery } from "src/core/Hooks";
import {
  fetchData,
  getAlterServerResponse,
  getRelevantPageForNavigation,
  updateStateOnEngagementConfigurationRead,
} from "src/utils";
import { AppContext } from "src/contexts/AppContext";
import GtDialog from "./GtDialog";
import { Button, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { applyUpdatesOnQuestionChange } from "src/utils/processors/Questions";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "src/contexts/LoadingContext";
import GtImportWarningDialog from "./GtImportWarningDialog";

function GtImportConfigurationDialog({
  onDialogClose,
  afterConfigurationImport,
}: CorePropsInterfaces.GtImportConfigurationDialogProps): JSX.Element {
  const { globalState, dispatch } = useContext(AppContext);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const queryParamsMap = useQuery();
  const navigate = useNavigate();
  const clientId = queryParamsMap.get(Constants.QueryParams.clientId);
  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );
  const unit = queryParamsMap.get(Constants.QueryParams.unit);
  const [dialogState, setDialogState] =
    useState<ComponentStates.ImportConfigrationDialogState>({
      query: "",
      autocompleteLoading: false,
      oportunity: "",
      companyOptions: [],
      companyDetails: [],
      businessOpportunityDescription: "",
      versionDetailsList: [],
      version: "",
      configurationDescription: "",
      isImportWarningDialogVisible: false,
    });

  function setDialogStateValue(key: string, value: any): void {
    setDialogState((currentState) => {
      return {
        ...currentState,
        [key]: value,
      };
    });
  }

  function setQuery(value: string): void {
    setDialogStateValue("query", value);
  }

  useEffect(() => {
    if (!dialogState.query) {
      setDialogState((currentState) => {
        return {
          ...currentState,
          companyOptions: [],
          autocompleteLoading: false,
        };
      });
    } else {
      setDialogState((currentState) => {
        return {
          ...currentState,
          companyOptions: [],
          autocompleteLoading: true,
        };
      });
      const timeOutId = setTimeout(() => {
        fetchCompanies(dialogState.query);
      }, 250);
      return () => clearTimeout(timeOutId);
    }
  }, [dialogState.query]);

  const importConfigurationDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.ImportConfiguration"),
      maxWidth: "md",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => onDialogClose()}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          disabled={!dialogState.oportunity || !dialogState.version}
          onClick={() =>
            setDialogStateValue("isImportWarningDialogVisible", true)
          }
        >
          {t("General.Apply")}
        </Button>,
      ],
    },
    api: {
      onClose: onDialogClose,
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };

  function fetchCompanies(searchBy: string): void {
    const url = `${Constants.APIPath.CompaniesWithEngagements}/${searchBy}`;
    fetchData<Array<CoreInterfaces.CompanyIdentificator>>(
      url,
      Constants.APIMethod.POST,
      {}
    )
      .then((data: Array<CoreInterfaces.CompanyIdentificator>) => {
        const results = data.map(
          (companyIdentificator: CoreInterfaces.CompanyIdentificator) => {
            return {
              label: `${companyIdentificator.CompanyName} (${companyIdentificator.OrganisationNumber})`,
              organisationNumber: +companyIdentificator.OrganisationNumber,
            };
          }
        );
        setDialogState((currentState) => {
          return {
            ...currentState,
            companyOptions: results,
            autocompleteLoading: false,
          };
        });
      })
      .catch(() => []);
  }

  function fetchCompanyData(organisationNumber: number): void {
    updateApplicationLoadingState(true);
    const url = `${Constants.APIPath.EngagementsForCompany}/${organisationNumber}`;
    fetchData<Array<CoreInterfaces.CompanyDetailsForImport>>(
      url,
      Constants.APIMethod.POST,
      {}
    )
      .then((result: Array<CoreInterfaces.CompanyDetailsForImport>) => {
        setDialogState((currentState) => {
          return {
            ...currentState,
            companyDetails: result,
          };
        });
      })
      .finally(() => {
        updateApplicationLoadingState(false);
      });
  }

  function onOportunityChange(oportunity: string): void {
    const companyDetail: CoreInterfaces.CompanyDetailsForImport =
      dialogState.companyDetails.find(
        (companyDetail: CoreInterfaces.CompanyDetailsForImport) =>
          companyDetail.BusinessOpportunityId === oportunity
      );
    setDialogState((currentState) => {
      return {
        ...currentState,
        oportunity: oportunity,
        businessOpportunityDescription:
          companyDetail.BusinessOpportunityDescription ?? "",
        versionDetailsList: companyDetail.Versions,
        version: "",
        configurationDescription: "",
      };
    });
  }

  function onVersionChange(value: string): void {
    const version: CoreInterfaces.VersionDetails =
      dialogState.versionDetailsList.find(
        (versionDetail: CoreInterfaces.VersionDetails) =>
          versionDetail.Id === value
      );
    setDialogState((currentState) => {
      return {
        ...currentState,
        version: value,
        configurationDescription: version.ConfigurationDescription ?? "",
      };
    });
  }

  function loadConfiguration(): void {
    updateApplicationLoadingState(true);
    fetchData<CoreInterfaces.EngagementConfigurationReadPack>(
      `${Constants.APIPath.EngagementConfiguration}/${dialogState.oportunity}/${dialogState.version}`,
      Constants.APIMethod.GET
    )
      .then((serverReturn: CoreInterfaces.EngagementConfigurationReadPack) => {
        const importedConfigurationStatus = serverReturn.Status;
        serverReturn = getAlterServerResponse(
          serverReturn,
          globalState,
          clientId
        );
        const {
          taskAllocationConfigurationDescription,
          taskAllocationConfigurationVersion,
        } = globalState.currentConfiguration.applicationConfiguration;
        const {
          taskCostConfigurationDescription,
          taskCostConfigurationVersion,
        } = globalState.currentConfiguration.applicationConfiguration;
        const applicationConfigurationObject: CoreInterfaces.ApplicationConfigurationReadPack =
          {
            RateCartConfiguration: null,
            TaskAllocationConfiguration: {
              Description: taskAllocationConfigurationDescription,
              Version: taskAllocationConfigurationVersion,
              TaskAllocations: globalState.taskAllocations || [],
            },
            TaskCostConfiguration: {
              Description: taskCostConfigurationDescription,
              Version: taskCostConfigurationVersion,
              TaskCosts: globalState.taskCosts || [],
            },
          };
        updateStateOnEngagementConfigurationRead(
          serverReturn,
          dispatch,
          // eslint-disable-next-line
          () => {},
          applicationConfigurationObject,
          Constants.UpdateStateOnEngagementConfigurationReadScenario
            .ImportConfiguration
        );
        const nextRoutesMap = {
          [Constants.ConfigurationStatus.Active]: Constants.Routes.Question,
          [Constants.ConfigurationStatus.FinalVersion]:
            Constants.Routes.EngagementDescription,
          [Constants.ConfigurationStatus.OutdatedVersion]:
            Constants.Routes.EngagementDescription,
        };
        const relevantPageForNavigation = getRelevantPageForNavigation(
          importedConfigurationStatus,
          nextRoutesMap,
          businessOpportunityId,
          clientId,
          unit,
          serverReturn.Id
        );
        if (relevantPageForNavigation) {
          navigate(relevantPageForNavigation, { replace: true });
        }
        afterConfigurationImport({
          severity: Constants.AlertSeverity.Success,
          isDisplayed: true,
          message: "General.ConfigurationLoadedSuccess",
        });
        dispatch({
          type: Constants.AppStateActions.RatesChangeMessageVisibilityUpdated,
          payload: true,
        });
      })
      .catch(() => {
        afterConfigurationImport({
          severity: Constants.AlertSeverity.Error,
          isDisplayed: true,
          message: "General.ConfigurationLoadedFail",
        });
      })
      .finally(() => {
        updateApplicationLoadingState(false);
      });
  }

  function cleanForm(): void {
    setDialogState({
      query: "",
      autocompleteLoading: false,
      oportunity: "",
      companyOptions: [],
      companyDetails: [],
      businessOpportunityDescription: "",
      versionDetailsList: [],
      version: "",
      configurationDescription: "",
      isImportWarningDialogVisible: false,
    });
  }

  return (
    <>
      {dialogState.isImportWarningDialogVisible && (
        <GtImportWarningDialog
          onImportWarningDialogClose={() =>
            setDialogStateValue("isImportWarningDialogVisible", false)
          }
          onImportWarningDialogConfirm={() => {
            setDialogStateValue("isImportWarningDialogVisible", false);
            loadConfiguration();
          }}
        />
      )}
      <GtDialog gtDialogDTO={importConfigurationDialogDTO}>
        <article className="gt-form">
          <section>
            <Autocomplete
              className="gt-Autocomplete"
              disablePortal
              options={dialogState.companyOptions}
              loading={dialogState.autocompleteLoading}
              loadingText={t("General.Loading")}
              onBlur={() => setDialogStateValue("companyOptions", [])}
              onChange={(event, value) => {
                if (value) {
                  fetchCompanyData(value.organisationNumber);
                } else {
                  cleanForm();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={dialogState.query}
                  placeholder={t("General.CompanyOptionsPlaceholder")}
                  onChange={(event) => setQuery(event.target.value)}
                />
              )}
            />
          </section>
          <section className="gt-form__fieldSection">
            <span className="gt-form__fieldSection-label">
              {t("General.OportunityId")}
            </span>
            <Select
              className="gt-Select"
              value={dialogState.oportunity}
              onChange={(e) => {
                onOportunityChange(e.target.value);
              }}
            >
              {dialogState.companyDetails.map(
                (companyDetail: CoreInterfaces.CompanyDetailsForImport) => (
                  <MenuItem
                    key={`oportunity${companyDetail.BusinessOpportunityId}`}
                    value={companyDetail.BusinessOpportunityId}
                  >
                    {companyDetail.BusinessOpportunityId}
                  </MenuItem>
                )
              )}
            </Select>
          </section>
          <section className="gt-form__fieldSection">
            <span className="gt-form__fieldSection-label">
              {t("General.BusinessOpportunityDescription")}
            </span>
            <TextField
              className="gt-MultilineField"
              multiline
              minRows={2}
              variant="outlined"
              value={dialogState.businessOpportunityDescription}
              disabled={true}
            />
          </section>
          <section className="gt-form__fieldSection">
            <span className="gt-form__fieldSection-label">
              {t("General.ChooseConfigurationToCopy")}
            </span>
            <Select
              className="gt-Select"
              value={dialogState.version}
              onChange={(e) => {
                onVersionChange(e.target.value);
              }}
            >
              {dialogState.versionDetailsList.map(
                (versionDetail: CoreInterfaces.VersionDetails) => (
                  <MenuItem
                    key={`oportunity${versionDetail.Id}`}
                    value={versionDetail.Id}
                  >
                    {t("General.ConfigurationVersionNumber", {
                      configurationNo: versionDetail.VersionNumber,
                    })}
                  </MenuItem>
                )
              )}
            </Select>
          </section>
          <section className="gt-form__fieldSection">
            <span className="gt-form__fieldSection-label">
              {t("General.ConfigurationDescription")}
            </span>
            <TextField
              className="gt-MultilineField"
              multiline
              minRows={2}
              variant="outlined"
              value={dialogState.configurationDescription}
              disabled={true}
            />
          </section>
        </article>
      </GtDialog>
    </>
  );
}

export default GtImportConfigurationDialog;
