import * as Constants from "../../core/Constants";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "../../core/Models";
import { MenuItem, Select } from "@mui/material";
import { ReactElement, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import {
  DataGridPro,
  GRID_REORDER_COL_DEF,
  GridColDef,
} from "@mui/x-data-grid-pro";
import GtExpandMoreIcon from "../Common/GtExpandMoreIcon";
import GtInfoTooltip from "../Common/GtInfoTooltip";
import GtMultilineInput from "../Common/GtMultilineInput";
import GtQuestionComments from "../Common/GtQuestionComments";
import GtScrollElement from "../Common/GtScrollElement";
import GtTaskFormDialog from "../Common/GtTaskFormDialog";
import GtTaskMenu from "../Common/GtTaskMenu";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { TaskDTO } from "src/core/Models/DTO.interface";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {
  isRevertDisabled,
  onTaskOrderChange,
  showConfigurationCodes,
} from "src/utils";
import { createEngagementTableRows } from "src/utils/engagement-utils";
import GtTimeDueInput from "../Common/GtTimeDueInput";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";

function EngagementServiceTasksPanel({
  serviceDTO,
  generalVariables,
  isFinalorOutdatedVersionStatus,
  onExpandChange,
  lastConfigurationModifiedDate,
  extraProcessingServices,
  rateCartConfiguration,
  dispatch,
  children,
}: CorePropsInterfaces.ServiceTasksPanelProps): JSX.Element {
  const { t } = useTranslation();
  const [isAddTaskDialogOpen, setIsOpenAddTaskDialog] = useState(false);
  const [taskServiceGroup, setTaskServiceGroup] = useState(
    Constants.TaskGroup.DefaultGroup
  );

  function onTaskFieldChange(
    taskDTO: DTOs.TaskDTO,
    fieldName: string,
    newValue: any
  ) {
    const nextTaskDTO = { ...taskDTO };
    if (fieldName === "isActive") {
      nextTaskDTO.state = {
        ...nextTaskDTO.state,
        [fieldName]: newValue,
      };
    } else if (
      newValue !== null ||
      (newValue === null && taskDTO.state.isActive)
    ) {
      if (fieldName === "timeDue") {
        nextTaskDTO.data.timeDue = newValue;
      } else {
        nextTaskDTO.data.adjustments = {
          ...nextTaskDTO.data.adjustments,
          [fieldName]: newValue,
        };
      }
    }

    dispatch({
      type: Constants.AppStateActions.TaskUpdated,
      payload: {
        serviceDTO: serviceDTO,
        taskDTO: nextTaskDTO,
      },
    });
  }

  function onTaskAdd(serviceDTO: DTOs.ServiceDTO, nextTaskDTO: DTOs.TaskDTO) {
    setIsOpenAddTaskDialog(false);
    dispatch({
      type: Constants.AppStateActions.TaskAdd,
      payload: {
        serviceDTO: serviceDTO,
        taskDTO: nextTaskDTO,
      },
    });
  }

  function onTaskDelete(taskDTO: DTOs.TaskDTO) {
    dispatch({
      type: Constants.AppStateActions.TaskDelete,
      payload: {
        serviceDTO,
        taskDTO,
      },
    });
  }

  if (!serviceDTO || !serviceDTO.state.isSelected) {
    return null;
  }

  const visibileQuestions = serviceDTO.data.questions.filter(
    (item) => item.state.isShown
  );
  const questionsWithAnswerField = visibileQuestions.filter(
    (item) => !item.state.isInfoTextVariat && item.data.inputType
  );
  const isAllFilled = questionsWithAnswerField.every(
    (item) => item.state.isFilled
  );
  const isAllQuestionAnsweredWithDefaultValue = questionsWithAnswerField
    .filter(
      (item) =>
        item.data.defaultValue &&
        item.data.defaultValue !== Constants.HelpfulConstants.PleaseSelect
    )
    .every((item) => item.data.defaultValue === item.data.userValue);

  const extraData: CoreInterfaces.ServiceTaskProcessorExtraData = {
    generalVariables,
    extraServices: extraProcessingServices,
  };
  const rows = createEngagementTableRows(serviceDTO, extraData);
  if (rows === null || !rows.length) {
    return null;
  }

  const columns = getColumnsDefinition(
    onTaskFieldChange,
    onTaskDelete,
    isFinalorOutdatedVersionStatus,
    lastConfigurationModifiedDate
  );

  return (
    <article className="gt-servicePanel">
      <GtScrollElement service={serviceDTO} />
      <Accordion
        className={`gt-accordion
        ${
          isAllFilled
            ? "gt-accordion--allQuestionsAnsweredState"
            : "gt-accordion--notAllQuestionsAnsweredState"
        }
          ${
            isAllQuestionAnsweredWithDefaultValue
              ? "gt-accordion--allQuestionsAnsweredIsDefaultState"
              : "gt-accordion--notAllQuestionsAnsweredIsDefaultState"
          }`}
        onChange={() => {
          onExpandChange();
        }}
        expanded={serviceDTO.state.isExpandedEngagementDescriptionAccordion}
      >
        <AccordionSummary
          expandIcon={<GtExpandMoreIcon isDarkColor={!isAllFilled} />}
          className="gt-accordion__header"
        >
          <div className="gt-accordion__header-container">
            <span className="gt-accordion__header-container-title">
              {t(serviceDTO.data.title)}
            </span>
            <span className="gt-accordion__header-container-expandedText">
              <span className="gt-accordion__header-container-expandedText-icon">
                {serviceDTO.data.questions.filter(
                  (item) => item.state.isShown && item.data.defaultValue
                ).length > 0 && <CircleRoundedIcon fontSize="small" />}
                {isAllFilled && (
                  <CheckCircleIcon
                    className="gt-accordion__header-container-expandedText-icon-isAllFilledCheck"
                    fontSize="small"
                  />
                )}
              </span>
              {serviceDTO.state.isExpandedEngagementDescriptionAccordion
                ? t("General.CollapseDetails")
                : t("General.ExpandDetails")}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="gt-accordion__content">
          <article className="gt-tasksPanel">
            {children && children}
            {serviceDTO.data.serviceTaskGroups
              .filter((subgroup) => {
                return rows.some(
                  (row) => row.taskDTO.data.serviceTaskGroup === subgroup.Code
                );
              })
              .map((subgroup) => (
                <article
                  key={subgroup.Code}
                  className="gt-tasksPanelTasksGroup"
                >
                  {!subgroup.IsDefault && subgroup.HasTitleVisible && (
                    <section className="gt-tasksPanelTasksGroup__header">
                      {t(subgroup.Code)}
                    </section>
                  )}
                  <section className="gt-tasksPanelTasksGroup__dataContainer">
                    <DataGridPro
                      rowReordering
                      onRowOrderChange={(params) => {
                        onTaskOrderChange(
                          serviceDTO,
                          params,
                          rows.filter(
                            (row) =>
                              row.taskDTO.data.serviceTaskGroup ===
                              subgroup.Code
                          ),
                          dispatch
                        );
                      }}
                      slots={{
                        rowReorderIcon: DensitySmallIcon,
                      }}
                      autoHeight
                      getRowClassName={(params) =>
                        getRowCssClasses(params.row.taskDTO)
                      }
                      rows={rows.filter(
                        (row) =>
                          row.taskDTO.data.serviceTaskGroup === subgroup.Code
                      )}
                      columns={[
                        {
                          ...GRID_REORDER_COL_DEF,
                          maxWidth: 30,
                          minWidth: 30,
                        },
                        ...columns,
                      ]}
                      rowHeight={50}
                      columnHeaderHeight={subgroup.HasTableHeader ? 44 : 0}
                      hideFooterPagination={true}
                    />
                    {subgroup.HasTableHeader && (
                      <div
                        style={{
                          height:
                            44 +
                            50 *
                              rows.filter(
                                (row) =>
                                  row.taskDTO.data.serviceTaskGroup ===
                                  subgroup.Code
                              ).length,
                        }}
                      >
                        <GtQuestionComments service={serviceDTO} />
                      </div>
                    )}
                  </section>
                  {subgroup.AllowNewTask && (
                    <Button
                      onClick={() => {
                        setTaskServiceGroup(subgroup.Code);
                        setIsOpenAddTaskDialog(true);
                      }}
                      className="gt-servicePanel__addTaskButton"
                      color="primary"
                      variant="contained"
                      key="addTaskBtn"
                      disabled={isFinalorOutdatedVersionStatus}
                    >
                      <AddIcon />
                      {t("General.AddNewTask")}
                    </Button>
                  )}
                </article>
              ))}
          </article>
        </AccordionDetails>
      </Accordion>

      {isAddTaskDialogOpen && (
        <GtTaskFormDialog
          serviceDTO={serviceDTO}
          onSave={(taskDTO) => onTaskAdd(serviceDTO, taskDTO)}
          onDialogClose={() => {
            setIsOpenAddTaskDialog(false);
          }}
          taskServiceGroup={taskServiceGroup}
          rateCartConfiguration={rateCartConfiguration}
        />
      )}
    </article>
  );
}

function getRowCssClasses(taskDTO: TaskDTO): string {
  const allocationOfResponsibility =
    taskDTO.data.adjustments?.allocationOfResponsibility ??
    taskDTO.data.allocationOfResponsibility;
  let cellCssClasses = !taskDTO.state.isActive
    ? "gt-tasksPanel--disabledCellState"
    : "";
  cellCssClasses +=
    allocationOfResponsibility === Constants.TaskAllocationResponsibility.Client
      ? " gt-tasksPanel--isClientTask"
      : "";
  cellCssClasses +=
    taskDTO.data.type === Constants.TaskType.Automatic
      ? " gt-tasksPanel--isAutomaticTask"
      : "";
  return cellCssClasses;
}

function getColumnsDefinition(
  taskChangeFn: Function,
  taskDeleteFn: Function,
  isFinalorOutdatedVersionStatus: boolean,
  configurationLastModifiedDate: string
): GridColDef[] {
  return [
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 30,
      minWidth: 30,
      renderCell(params) {
        return (
          <GtTaskMenu
            taskDTO={params.row.taskDTO}
            onToggleActiveStatus={(value: boolean) =>
              taskChangeFn(params.row.taskDTO, "isActive", value)
            }
            onDeleteTask={() => {
              taskDeleteFn(params.row.taskDTO);
            }}
          />
        );
      },
    },
    {
      field: "title",
      headerName: t("TableHeader.Task"),
      minWidth: 350,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <article className="gt-serviceTitleCell">
            {showConfigurationCodes() && (
              <>[{params.row.taskDTO.data.code.substring(0, 8)}]</>
            )}
            <div
              className="gt-serviceTitleCell__title"
              data-testid={params.row.taskDTO.data.code + "Task"}
            >
              {params.row.title}
            </div>
            {params.row.tooltip && (
              <GtInfoTooltip
                testIdPart={params.row.taskDTO.data.code}
                tooltipTitle={params.row.tooltip}
              />
            )}
          </article>
        );
      },
    },
    {
      field: "frequency",
      headerName: t("TableHeader.Frequency"),
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return params.row.taskDTO.data.canModifyAdjustmentFreq ? (
          <article className="gt-dataTableCellWrapper">
            <Select
              data-testid={params.row.taskDTO.data.code + "Frequency"}
              className="gt-Select"
              onChange={(_, rn: any) =>
                taskChangeFn(params.row.taskDTO, "frequency", rn.props.value)
              }
              value={params.row.frequency}
              autoWidth={true}
              disabled={
                !params.row.taskDTO.state.isActive ||
                isFinalorOutdatedVersionStatus
              }
            >
              {[
                Constants.Frequency.Daily,
                Constants.Frequency.OnceAWeek,
                Constants.Frequency.TwiceAWeek,
                Constants.Frequency.EverySecondWeek,
                Constants.Frequency.Monthly,
                Constants.Frequency.Quarterly,
                Constants.Frequency.Yearly,
                Constants.Frequency.WhenNeeded,
              ].map((eachFrequency) => (
                <MenuItem
                  key={`frequency${eachFrequency}`}
                  data-testid={
                    params.row.taskDTO.data.code + "_" + eachFrequency
                  }
                  value={eachFrequency}
                >
                  {t(`Options.${eachFrequency}`)}
                </MenuItem>
              ))}
            </Select>
            <ReplayRoundedIcon
              onClick={() =>
                taskChangeFn(params.row.taskDTO, "frequency", null)
              }
              className={`gt-dataTableCellWrapper__revertIcon 
              ${
                isRevertDisabled(params, "frequency") ||
                isFinalorOutdatedVersionStatus
                  ? "gt-dataTableCellWrapper__revertIcon--disabled"
                  : ""
              }`}
            />
          </article>
        ) : (
          <article
            className="gt-dataTableCellValue"
            data-testid={params.row.taskDTO.data.code + "Frequency"}
          >
            {t(`Options.${params.row.frequency}`)}
          </article>
        );
      },
    },

    {
      field: "allocationOfResponsibility",
      headerName: t("TableHeader.WorkDivision"),
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return params.row.taskDTO.data.canModifyAllocationResponsibility ? (
          <article className="gt-dataTableCellWrapper">
            <Select
              data-testid={params.row.taskDTO.data.code + "Responsibility"}
              className="gt-qSelect"
              onChange={(_, rn) =>
                taskChangeFn(
                  params.row.taskDTO,
                  "allocationOfResponsibility",
                  (rn as ReactElement).props.value
                )
              }
              value={params.row.allocationOfResponsibility}
              autoWidth={true}
              disabled={
                !params.row.taskDTO.state.isActive ||
                isFinalorOutdatedVersionStatus
              }
            >
              {[
                Constants.TaskAllocationResponsibility.Client,
                Constants.TaskAllocationResponsibility.GT,
              ].map((eachFrequency) => (
                <MenuItem
                  data-testid={
                    params.row.taskDTO.data.code + "_" + eachFrequency
                  }
                  key={`frequency${eachFrequency}`}
                  value={eachFrequency}
                >
                  {t(`Options.${eachFrequency}`)}
                </MenuItem>
              ))}
            </Select>
            <ReplayRoundedIcon
              onClick={() =>
                taskChangeFn(
                  params.row.taskDTO,
                  "allocationOfResponsibility",
                  null
                )
              }
              className={`gt-dataTableCellWrapper__revertIcon 
              ${
                isRevertDisabled(params, "allocationOfResponsibility") ||
                isFinalorOutdatedVersionStatus
                  ? "gt-dataTableCellWrapper__revertIcon--disabled"
                  : ""
              }`}
            />
          </article>
        ) : (
          <article
            className="gt-dataTableCellValue"
            data-testid={params.row.taskDTO.data.code + "Responsibility"}
          >
            {t(`Options.${params.row.allocationOfResponsibility}`)}
          </article>
        );
      },
    },
    {
      field: "timeDue",
      renderHeader() {
        return (
          <div className="MuiDataGrid-columnHeaderTitleContainerContent">
            <div className="MuiDataGrid-columnHeaderTitle">
              {t("TableHeader.TimeDue")}
            </div>
            <Tooltip title={t("TableHeader.TimeDueTooltip")}>
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </div>
        );
      },
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return useMemo(() => {
          return (
            <GtTimeDueInput
              value={params.row.taskDTO.data.timeDue}
              code={params.row.taskDTO.data.code}
              onChange={(val: string) =>
                taskChangeFn(params.row.taskDTO, "timeDue", val)
              }
              isDisabled={
                !params.row.taskDTO.state.isActive ||
                isFinalorOutdatedVersionStatus
              }
              updateTimestamp={configurationLastModifiedDate}
            />
          );
        }, [
          params.row.taskDTO.data.timeDue,
          !params.row.taskDTO.state.isActive || isFinalorOutdatedVersionStatus,
        ]);
      },
    },
    {
      field: "comment",
      headerName: t("TableHeader.Comment"),
      minWidth: 400,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.taskDTO.data.code + "Comment"}
          >
            <GtMultilineInput
              value={params.row.comment}
              onChange={(value: string) =>
                taskChangeFn(params.row.taskDTO, "comment", value)
              }
              maxLength={300}
              disabled={
                !params.row.taskDTO.state.isActive ||
                isFinalorOutdatedVersionStatus
              }
            />
            <ReplayRoundedIcon
              onClick={() => taskChangeFn(params.row.taskDTO, "comment", null)}
              className={`gt-dataTableCellWrapper__revertIcon 
              ${
                isRevertDisabled(params, "comment") ||
                isFinalorOutdatedVersionStatus
                  ? "gt-dataTableCellWrapper__revertIcon--disabled"
                  : ""
              }`}
            />
          </article>
        );
      },
    },
  ];
}

export default EngagementServiceTasksPanel;
