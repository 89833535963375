import { DTOs } from "src/core/Models";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export default function ServiceRow({
  service,
  isDisabled,
  onSelectionChange,
}: {
  service: DTOs.ServiceDTO;
  isDisabled: boolean;
  onSelectionChange: Function;
}): JSX.Element {
  const { t } = useTranslation();
  const serviceCannotBeSelected =
    service.state.isVisibleInServicesPage &&
    !service.state.canBeSelectedInServicePage;

  return (
    <article
      className={`gt-serviceRow ${
        service.state.isSelected ? "gt-serviceRow--selectedState" : ""
      }
      ${serviceCannotBeSelected ? "gt-serviceRow--disabledState" : ""}`}
      onClick={() =>
        !isDisabled && !serviceCannotBeSelected && onSelectionChange(service)
      }
    >
      <span className="gt-serviceRow__title">{t(service.data.title)}</span>
      <span className="gt-serviceRow__icon">
        <span className="gt-serviceRow__icon-selected">
          <CheckCircleIcon fontSize="small" />
        </span>
        <span className="gt-serviceRow__icon-unselected">
          {!serviceCannotBeSelected && (
            <RadioButtonUncheckedIcon fontSize="small" />
          )}
        </span>
      </span>
    </article>
  );
}
