import * as Constants from "../../core/Constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { CoreInterfaces, CorePropsInterfaces, DTOs } from "src/core/Models";
import {
  isRevertDisabled,
  getVisibleTaskRowsForService,
  showConfigurationCodes,
  onTaskOrderChange,
} from "src/utils";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import {
  DataGridPro,
  GRID_REORDER_COL_DEF,
  GridColDef,
} from "@mui/x-data-grid-pro";
import GtInfoTooltip from "../Common/GtInfoTooltip";
import GtQuestionComments from "../Common/GtQuestionComments";
import GtScrollElement from "../Common/GtScrollElement";
import GtTaskFormDialog from "../Common/GtTaskFormDialog";
import GtTaskMenu from "../Common/GtTaskMenu";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import ServiceProcessors from "src/utils/processors/Services";
import { getDefaultTableRow } from "src/utils/processors/Services/Calculation";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import GtEstimatedTimeInput from "../Common/GtEstimatedTimeInput";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";

function CalculationServiceTasksPanel({
  serviceDTO,
  generalVariables,
  isFinalorOutdatedVersionStatus,
  lastConfigurationModifiedDate,
  extraProcessingServices,
  dispatch,
  children,
  rateCartConfiguration,
}: CorePropsInterfaces.ServiceTasksPanelProps): JSX.Element {
  const { t } = useTranslation();
  const [isAddTaskDialogOpen, setIsOpenAddTaskDialog] = useState(false);
  const [taskServiceGroup, setTaskServiceGroup] = useState(
    Constants.TaskGroup.DefaultGroup
  );

  function onTaskChange(
    serviceDTO: DTOs.ServiceDTO,
    nextTaskDTO: DTOs.TaskDTO
  ) {
    dispatch({
      type: Constants.AppStateActions.TaskUpdated,
      payload: {
        serviceDTO: serviceDTO,
        taskDTO: nextTaskDTO,
      },
    });
  }

  function onTaskAdd(serviceDTO: DTOs.ServiceDTO, nextTaskDTO: DTOs.TaskDTO) {
    setIsOpenAddTaskDialog(false);
    dispatch({
      type: Constants.AppStateActions.TaskAdd,
      payload: {
        serviceDTO: serviceDTO,
        taskDTO: nextTaskDTO,
      },
    });
  }

  if (!serviceDTO || !serviceDTO.state.isSelected) {
    return null;
  }

  const visibileQuestions = serviceDTO.data.questions.filter(
    (item) => item.state.isShown
  );
  const questionsWithAnswerField = visibileQuestions.filter(
    (item) => !item.state.isInfoTextVariat && item.data.inputType
  );
  const isAllFilled = questionsWithAnswerField.every(
    (item) => item.state.isFilled
  );
  const isAllQuestionAnsweredWithDefaultValue = questionsWithAnswerField
    .filter(
      (item) =>
        item.data.defaultValue &&
        item.data.defaultValue !== Constants.HelpfulConstants.PleaseSelect
    )
    .every((item) => item.data.defaultValue === item.data.userValue);

  function onTaskFieldChange(
    taskDTO: DTOs.TaskDTO,
    fieldName: string,
    newValue: any
  ) {
    const nextTaskDTO = {
      ...taskDTO,
    };
    if (fieldName === "isActive") {
      nextTaskDTO.state = {
        ...nextTaskDTO.state,
        [fieldName]: newValue,
      };
    } else if (
      newValue !== null ||
      (newValue === null && taskDTO.state.isActive)
    ) {
      nextTaskDTO.data.adjustments = {
        ...nextTaskDTO.data.adjustments,
        [fieldName]: newValue,
      };
    }
    onTaskChange(serviceDTO, nextTaskDTO);
  }

  function onTaskDelete(taskDTO: DTOs.TaskDTO) {
    dispatch({
      type: Constants.AppStateActions.TaskDelete,
      payload: {
        serviceDTO,
        taskDTO,
      },
    });
  }

  const extraData: CoreInterfaces.ServiceTaskProcessorExtraData = {
    generalVariables,
    extraServices: extraProcessingServices,
  };

  const rows = createTableRows(serviceDTO, extraData);
  if (!rows || !rows.length) {
    return null;
  }
  const columns = getColumnsDefinition(
    rateCartConfiguration,
    onTaskFieldChange,
    onTaskDelete,
    lastConfigurationModifiedDate
  );

  return (
    <article className="gt-servicePanel">
      <GtScrollElement service={serviceDTO} />
      <Accordion
        className={`gt-accordion gt-accordion--liteVariant
        ${
          isAllFilled
            ? "gt-accordion--allQuestionsAnsweredState"
            : "gt-accordion--notAllQuestionsAnsweredState"
        }
        
      ${
        isAllQuestionAnsweredWithDefaultValue
          ? "gt-accordion--allQuestionsAnsweredIsDefaultState"
          : "gt-accordion--notAllQuestionsAnsweredIsDefaultState"
      }`}
        expanded={true}
      >
        <AccordionSummary className="gt-accordion__header">
          <div className="gt-accordion__header-container">
            <span className="gt-accordion__header-container-title">
              {t("General.DetailsServiceTitle", {
                serviceTitle: t(serviceDTO.data.title),
              })}
            </span>
            <span>
              <span className="gt-accordion__header-container-expandedText-icon">
                {serviceDTO.data.questions.filter(
                  (item) => item.state.isShown && item.data.defaultValue
                ).length > 0 && <CircleRoundedIcon fontSize="small" />}
                {isAllFilled && (
                  <CheckCircleIcon
                    className="gt-accordion__header-container-expandedText-icon-isAllFilledCheck"
                    fontSize="small"
                  />
                )}
              </span>
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="gt-accordion__content">
          <article className="gt-tasksPanel">
            {children && children}

            {serviceDTO.data.serviceTaskGroups
              .filter((subgroup) => {
                return rows.some(
                  (row) => row.taskDTO.data.serviceTaskGroup === subgroup.Code
                );
              })
              .map((subgroup) => (
                <article
                  key={subgroup.Code}
                  className="gt-tasksPanelTasksGroup"
                >
                  {!subgroup.IsDefault && subgroup.HasTitleVisible && (
                    <section className="gt-tasksPanelTasksGroup__header">
                      {t(subgroup.Code)}
                    </section>
                  )}
                  <section className="gt-tasksPanelTasksGroup__dataContainer">
                    <DataGridPro
                      rowReordering
                      onRowOrderChange={(params) => {
                        onTaskOrderChange(
                          serviceDTO,
                          params,
                          rows.filter(
                            (row) =>
                              row.taskDTO.data.serviceTaskGroup ===
                              subgroup.Code
                          ),
                          dispatch
                        );
                      }}
                      slots={{
                        rowReorderIcon: DensitySmallIcon,
                      }}
                      autoHeight
                      getRowClassName={(params) =>
                        getRowCssClasses(params.row.taskDTO)
                      }
                      rows={rows.filter(
                        (row) =>
                          row.taskDTO.data.serviceTaskGroup === subgroup.Code
                      )}
                      columns={[
                        {
                          ...GRID_REORDER_COL_DEF,
                          maxWidth: 30,
                          minWidth: 30,
                        },
                        ...columns,
                      ]}
                      rowHeight={44}
                      columnHeaderHeight={subgroup.HasTableHeader ? 48 : 0}
                      hideFooterPagination={true}
                    />
                    {subgroup.HasTableHeader && (
                      <div
                        style={{
                          height:
                            48 +
                            44 *
                              rows.filter(
                                (row) =>
                                  row.taskDTO.data.serviceTaskGroup ===
                                  subgroup.Code
                              ).length,
                        }}
                      >
                        <GtQuestionComments service={serviceDTO} />
                      </div>
                    )}
                  </section>
                  {subgroup.AllowNewTask && (
                    <Button
                      onClick={() => {
                        setTaskServiceGroup(subgroup.Code);
                        setIsOpenAddTaskDialog(true);
                      }}
                      className="gt-servicePanel__addTaskButton"
                      color="primary"
                      variant="contained"
                      key="addTaskBtn"
                      disabled={isFinalorOutdatedVersionStatus}
                    >
                      <AddIcon />
                      {t("General.AddNewTask")}
                    </Button>
                  )}
                </article>
              ))}
          </article>
        </AccordionDetails>
      </Accordion>
      {isAddTaskDialogOpen && (
        <GtTaskFormDialog
          serviceDTO={serviceDTO}
          onSave={(taskDTO) => onTaskAdd(serviceDTO, taskDTO)}
          onDialogClose={() => {
            setIsOpenAddTaskDialog(false);
          }}
          taskServiceGroup={taskServiceGroup}
          rateCartConfiguration={rateCartConfiguration}
        />
      )}
    </article>
  );
}

function getRowCssClasses(taskDTO: DTOs.TaskDTO): string {
  const allocationOfResponsibility =
    taskDTO.data.adjustments?.allocationOfResponsibility ??
    taskDTO.data.allocationOfResponsibility;
  let cellCssClasses = !taskDTO.state.isActive
    ? "gt-tasksPanel--disabledCellState"
    : "";
  cellCssClasses +=
    allocationOfResponsibility === Constants.TaskAllocationResponsibility.Client
      ? " gt-tasksPanel--isClientTask"
      : "";
  cellCssClasses +=
    taskDTO.data.type === Constants.TaskType.Automatic
      ? " gt-tasksPanel--isAutomaticTask"
      : "";

  cellCssClasses +=
    taskDTO.data.visibility.includes(
      Constants.TaskVisibilityArea.Calculation
    ) && taskDTO.data.visibility.length == 1
      ? " gt-tasksPanel--isVisibleOnlyInCalculation"
      : "";

  return cellCssClasses;
}

function getColumnsDefinition(
  rateCartConfiguration: CoreInterfaces.RateCartConfiguration,
  taskChangeFn: Function,
  taskDeleteFn: Function,
  configurationLastModifiedDate: string
): GridColDef[] {
  return [
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 30,
      minWidth: 30,
      renderCell(params) {
        return (
          <GtTaskMenu
            taskDTO={params.row.taskDTO}
            onToggleActiveStatus={(value: boolean) =>
              taskChangeFn(params.row.taskDTO, "isActive", value)
            }
            onDeleteTask={() => {
              taskDeleteFn(params.row.taskDTO);
            }}
          />
        );
      },
    },
    {
      field: "title",
      headerName: t("TableHeader.Task"),
      minWidth: 350,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "gt-dataTableTaskTitle",
      renderCell(params) {
        return (
          <article className="gt-serviceTitleCell">
            {showConfigurationCodes() && (
              <>[{params.row.taskDTO.data.code.substring(0, 8)}]</>
            )}
            <div
              className="gt-serviceTitleCell__title"
              data-testid={params.row.taskDTO.data.code + "Task"}
            >
              {params.row.title}
            </div>
            {params.row.tooltip && (
              <GtInfoTooltip
                testIdPart={params.row.taskDTO.data.code}
                tooltipTitle={params.row.tooltip}
              />
            )}
          </article>
        );
      },
    },
    {
      field: "frequency",
      headerName: t("TableHeader.Frequency"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <article data-testid={params.row.taskDTO.data.code + "Frequency"}>
            {t(`Options.${params.row.frequency}`)}
          </article>
        );
      },
    },
    {
      field: "allocationOfResponsibility",
      headerName: t("TableHeader.WorkDivision"),
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <article
            data-testid={params.row.taskDTO.data.code + "Responsibility"}
          >
            {t(`Options.${params.row.allocationOfResponsibility}`)}
          </article>
        );
      },
    },
    {
      field: "calculationComment",
      headerName: t("TableHeader.Comment"),
      minWidth: 400,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper gt-dataTableCellWrapper--calculationComment"
            data-testid={params.row.taskDTO.data.code + "CalculationComment"}
          >
            {params.row.calculationComment}
          </article>
        );
      },
    },
    {
      field: "estimatedTimePerUnit",
      headerName: t("TableHeader.EstimatedTimeUnitsInMinutes"),
      width: 110,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) =>
        `gt-dataTableCalculationMethod${row.taskDTO.data.calculationMethod}`,
      renderCell(params) {
        return (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.taskDTO.data.code + "EstimatedTimePerUnit"}
          >
            {params.row.allocationOfResponsibility ===
              Constants.TaskAllocationResponsibility.GT && (
              <GtEstimatedTimeInput
                params={params}
                taskChangeFn={taskChangeFn}
                updateTimestamp={configurationLastModifiedDate}
              />
            )}
          </article>
        );
      },
    },
    {
      field: "staffLevel",
      headerName: t("General.StaffLevel"),
      width: 260,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) =>
        `gt-dataTableCalculationMethod${row.taskDTO.data.calculationMethod}`,
      renderCell(params) {
        return params.row.taskDTO.data.canModifyStaffLevel ? (
          <article
            className="gt-dataTableCellWrapper"
            data-testid={params.row.taskDTO.data.code + "StaffLevel"}
          >
            {params.row.allocationOfResponsibility ===
              Constants.TaskAllocationResponsibility.GT && (
              <>
                <Select
                  className="gt-qSelect"
                  onChange={(_, rn: any) =>
                    taskChangeFn(
                      params.row.taskDTO,
                      "staffLevel",
                      rn.props.value
                    )
                  }
                  value={params.row.staffLevel}
                  autoWidth={true}
                  disabled={!params.row.taskDTO.state.isActive}
                >
                  {rateCartConfiguration?.RateCarts.map((eachRateCart) => (
                    <MenuItem
                      key={`staffLevel${eachRateCart.StaffLevelCode}`}
                      value={eachRateCart.StaffLevelCode}
                    >
                      {t(`Options.${eachRateCart.StaffLevelCode}`)}
                    </MenuItem>
                  ))}
                </Select>
                <ReplayRoundedIcon
                  onClick={() =>
                    taskChangeFn(params.row.taskDTO, "staffLevel", null)
                  }
                  className={`gt-dataTableCellWrapper__revertIcon 
                      ${
                        isRevertDisabled(params, "staffLevel")
                          ? "gt-dataTableCellWrapper__revertIcon--disabled"
                          : ""
                      }`}
                />
              </>
            )}
          </article>
        ) : (
          <article
            className="gt-dataTableCellValue"
            data-testid={params.row.taskDTO.data.code + "StaffLevel"}
          >
            {t(`Options.${params.row.staffLevel}`)}
          </article>
        );
      },
    },
    {
      field: "sumTimeInHrsAndMinutes",
      headerName: t("TableHeader.SumTimeInHoursAndMinutes"),
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: ({ row }) =>
        `gt-dataTableCalculationMethod${row.taskDTO.data.calculationMethod}`,
      renderCell(params) {
        return (
          <article
            data-testid={
              params.row.taskDTO.data.code + "SumTimeInHrsAndMinutes"
            }
          >
            {params.row.allocationOfResponsibility ===
              Constants.TaskAllocationResponsibility.GT &&
              params.row.sumTimeInHrsAndMinutes}
          </article>
        );
      },
    },
  ];
}

function createTableRows(
  serviceDTO: DTOs.ServiceDTO,
  extraData: CoreInterfaces.ServiceTaskProcessorExtraData
): Array<CoreInterfaces.CalculationServiceTableRow> {
  const rowsArr: Array<CoreInterfaces.CalculationServiceTableRow> = [];
  if (!serviceDTO.state.isSelected) {
    return rowsArr;
  }
  const serviceProcessor = ServiceProcessors.Calculation[serviceDTO.data.code];
  if (!serviceProcessor) {
    return rowsArr;
  }

  return getVisibleTaskRowsForService<CoreInterfaces.CalculationServiceTableRow>(
    serviceDTO,
    serviceProcessor(serviceDTO, extraData),
    Constants.TaskVisibilityArea.Calculation,
    getDefaultTableRow
  );
}

export default CalculationServiceTasksPanel;
