import { CoreInterfaces, DTOs } from "./../core/Models";
import * as Constants from "./../core/Constants";
import { servicesConfiguration } from "./../core/Configurations/Services.config";
import * as StateProcessors from "./../core/Context/state-processors";
import { dateToISO, isValidDate } from "./utils";
import { questionValueProcessors } from "./processors/QuestionValueProcessors";

function formQuestionDTO(
  questionConfiguration: CoreInterfaces.QuestionConfiguration
): DTOs.QuestionDTO {
  const object: DTOs.QuestionDTO = {
    data: {
      code: questionConfiguration.Code,
      text: `Questions.${questionConfiguration.Code}.Text`,
      translationParams: {},
      inputType: questionConfiguration.InputType,
      defaultValue: questionConfiguration.DefaultValue,
      integration: `Questions.${questionConfiguration.Code}.Integration`,
      comment: "",
      tooltipText: `Questions.${questionConfiguration.Code}.TooltipText`,
      options: [],
      price: 0, // to be added from config
      time: 0, // to be added from config
      userValue: questionConfiguration.DefaultValue,
      userPrice: 0,
      userTime: 0,
      inputConstraints: {},
    },
    state: {
      isShown: true,
      isFilled: false,
      isInfoTextVariat: questionConfiguration.IsInfoTextVariat,
      isVisibleInUI: questionConfiguration.IsVisible,
    },
  };

  if (
    (questionConfiguration.InputType === Constants.QuestionInputType.Select ||
      questionConfiguration.InputType ===
        Constants.QuestionInputType.Checkbox) &&
    !!questionConfiguration.Options
  ) {
    object.data.options = questionConfiguration.Options.map((option) => ({
      key: option.Code,
      value: `Options.${option.Code}`,
    }));
    if (questionConfiguration.DefaultValue) {
      const selectedOption = object.data.options.find(
        (eachOption) => eachOption.key === questionConfiguration.DefaultValue
      );
      if (!!selectedOption) {
        object.data.userValue = selectedOption.key;
      }
    }
  }

  if (
    questionConfiguration.InputType === Constants.QuestionInputType.Numeric ||
    questionConfiguration.InputType === Constants.QuestionInputType.Currency ||
    questionConfiguration.InputType === Constants.QuestionInputType.RangeNumber
  ) {
    object.data.inputConstraints.min =
      questionConfiguration.InputConstraints.min;
    object.data.inputConstraints.max =
      questionConfiguration.InputConstraints.max;
    object.data.inputConstraints.maxLength =
      questionConfiguration.InputConstraints.maxLength;
  }

  if (
    questionConfiguration.InputType === Constants.QuestionInputType.Text ||
    questionConfiguration.InputType === Constants.QuestionInputType.Multiline
  ) {
    object.data.inputConstraints.maxLength =
      questionConfiguration.InputConstraints.maxLength;
  }
  if (questionConfiguration.InputType === Constants.QuestionInputType.Date) {
    object.data.inputConstraints.format =
      questionConfiguration.InputConstraints.format;
  }
  /**
   * Instantiate the FullDate questions with an array of nulls to prevent the #17310 issue
   * Normally this should be fixed via default value of the question, but this is a string at the moment.
   * If we modify it's type we should consider the questions that was previously saved, and this might affect the previously saved engagements
   */
  if (
    questionConfiguration.InputType === Constants.QuestionInputType.FullDate
  ) {
    object.data.userValue = [null, null];
  }

  return object;
}

export function formTaskDTO(
  taskConfiguration?: CoreInterfaces.TaskConfiguration
): DTOs.TaskDTO {
  const object: DTOs.TaskDTO = {
    data: {
      code: null,
      type: null,
      title: null,
      timeDue: null,
      tooltip: null,
      calculationTooltip: null,
      visibility: [],
      frequency: null,
      allocationOfResponsibility: null,
      canModifyAdjustmentFreq: true,
      canModifyAllocationResponsibility: true,
      canModifyStaffLevel: true,
      comment: null,
      calculationMethod: null,
      calculationQuestion: null,
      calculationQuestionOverride: null,
      estimatedTimePerUnit: 0,
      staffLevel: null,
      serviceTaskGroup: null,
      adjustments: {
        frequency: null,
        allocationOfResponsibility: null,
        comment: null,
        estimatedTimePerUnit: null,
        staffLevel: null,
      },
      sumTimeInHrsAndMinutes: 0,
    },
    state: {
      isVisible: true,
      isActive: false,
    },
  };

  if (!!taskConfiguration) {
    object.data.code = taskConfiguration.Code;
    object.data.type = taskConfiguration.Type;
    object.data.title = `Tasks.${taskConfiguration.Code}.Text`;
    object.data.timeDue = "";
    object.data.tooltip = `Tasks.${taskConfiguration.Code}.TooltipText`;
    object.data.calculationTooltip = `Tasks.${taskConfiguration.Code}.CalculationTooltipText`;
    object.data.visibility = taskConfiguration.Visibility;
    object.data.frequency = taskConfiguration.DefaultFreq;
    object.data.allocationOfResponsibility =
      taskConfiguration.DefaultAllocationOfResponsability;
    object.data.canModifyAdjustmentFreq =
      taskConfiguration.CanModifyAdjustmentFreq;
    object.data.canModifyAllocationResponsibility =
      taskConfiguration.CanModifyAllocationResponsibility;
    object.data.canModifyStaffLevel = taskConfiguration.CanModifyStaffLevel;
    object.data.comment = `Tasks.${taskConfiguration.Code}.Comment`;
    object.data.calculationMethod = taskConfiguration.CalculationMethod;
    object.data.calculationQuestion = taskConfiguration.CalculationQuestion;
    object.data.estimatedTimePerUnit = taskConfiguration.EstimatedTimePerUnit;
    object.data.serviceTaskGroup = taskConfiguration.ServiceTaskGroup;
    object.data.staffLevel = taskConfiguration.StaffLevel;
    object.data.sumTimeInHrsAndMinutes = 0;
    object.state.isActive = taskConfiguration.IsActive;
  }

  return object;
}

function formServiceDTO(
  serviceConfiguration: CoreInterfaces.ServiceConfiguration
): DTOs.ServiceDTO {
  const object: DTOs.ServiceDTO = {
    data: {
      code: serviceConfiguration.Code,
      title: `Services.${serviceConfiguration.Code}.Title`,
      questions: serviceConfiguration.Questions.map((questionConfiguration) =>
        formQuestionDTO(questionConfiguration)
      ),
      tasks: serviceConfiguration.Tasks.map((taskConfiguration) =>
        formTaskDTO(taskConfiguration)
      ),
      calculationVariables: {
        amount: null,
        contactPerson: null,
        totalWorkCost: null,
      },
      staffingAdjustments: {
        cost: null,
        extendedPriceListPanelCost: null,
      },
      allocationSummaryPanel: {
        hasAveragePrice:
          serviceConfiguration.AllocationSummaryPanel.HasAveragePrice,
        hasAverageTime:
          serviceConfiguration.AllocationSummaryPanel.HasAverageTime,
        hasAdjustmentOfCost:
          serviceConfiguration.AllocationSummaryPanel.HasAdjustmentOfCost,
        hasTableHeader:
          serviceConfiguration.AllocationSummaryPanel.HasTableHeader,
        hasTotalCost: serviceConfiguration.AllocationSummaryPanel.HasTotalCost,
        isDividedByTaskServiceGroup:
          serviceConfiguration.AllocationSummaryPanel
            .IsDividedByTaskServiceGroup,
        shouldIncludeAdditionalCosts:
          serviceConfiguration.AllocationSummaryPanel
            .ShouldIncludeAdditionalCosts,
      },
      calculationPage: {
        hasCustomTitleOnServicePanel:
          serviceConfiguration.CalculationPage.HasCustomTitleOnStaffingPanel,
        calculationPanelTitle: `Services.${serviceConfiguration.Code}.Title`,
        staffingTableHeaderTitle: `General.StaffLevel`,
        totalCostText: `Services.${serviceConfiguration.Code}.Title`,
        sumText: "TableHeader.Sum",
      },
      otherServicesTotalsPanel: {
        isTotalCostOverridenByAdjustment:
          serviceConfiguration.OtherServicesTotalsPanel
            .IsTotalCostOverridenByAdjustment,
      },
      additionalCosts: [],
      recurringType: serviceConfiguration.ServiceRecurringType,
      serviceGroup: serviceConfiguration.ServiceGroup,
      serviceTaskGroups: serviceConfiguration.ServiceTaskGroups,
      priceList: null,
    },
    state: {
      isSelected: serviceConfiguration.IsSelectedByDefault,
      isVisibleInServicesPage: serviceConfiguration.ServicesPage.IsVisible,
      isVisibleInQuestionPage: serviceConfiguration.QuestionsPage.IsVisible,
      isVisibleInSideMenu: serviceConfiguration.SideMenu.IsVisible,
      isExpandedQuestionAccordion: true,
      isExpandedEngagementDescriptionAccordion: true,
      isExpandedPriceAndTimeCalculationAccordion: true,
      canBeSelectedInServicePage:
        serviceConfiguration.ServicesPage.IsSelectable,
    },
  };

  if (
    !!serviceConfiguration.AdditionalCosts &&
    serviceConfiguration.AdditionalCosts.length
  ) {
    object.data.additionalCosts = serviceConfiguration.AdditionalCosts.map(
      (additionalCostConfig) => {
        return formAdditionalCostDTO(additionalCostConfig);
      }
    );
  }

  if (serviceConfiguration.CalculationPage.HasCustomTitleOnStaffingPanel) {
    object.data.calculationPage.staffingTableHeaderTitle = `Services.${serviceConfiguration.Code}.StaffingTableHeader`;
  }

  if (serviceConfiguration.CalculationPage.HasCustomOtherTitlesOnServicePage) {
    object.data.calculationPage.calculationPanelTitle = `Services.${serviceConfiguration.Code}.TableHeader`;
    object.data.calculationPage.totalCostText = `Services.${serviceConfiguration.Code}.TotalSumText`;
  }

  if (serviceConfiguration.CalculationPage.HasCustomTextForTotalNumberOfHours) {
    object.data.calculationPage.sumText = `Services.${serviceConfiguration.Code}.TotalNumberOfHours`;
  }

  if (serviceConfiguration.CalculationVariables) {
    object.data.calculationVariables.amount =
      serviceConfiguration.CalculationVariables.Amount;
    object.data.calculationVariables.contactPerson =
      serviceConfiguration.CalculationVariables.ContactPersonQuestion;
    object.data.calculationVariables.totalWorkCost =
      serviceConfiguration.CalculationVariables.TotalWorkCost;
  }

  return object;
}

export function formServiceDTOs(
  servicesConfiguration: Array<CoreInterfaces.ServiceConfiguration>,
  serviceHandlingFn: Function = null
): Array<DTOs.ServiceDTO> {
  return (servicesConfiguration || []).map((eachServiceConfiguration) => {
    const service = formServiceDTO(eachServiceConfiguration);
    if (!!serviceHandlingFn) {
      serviceHandlingFn(service);
    }
    return service;
  });
}

function formAdditionalCostDTO(
  additionalCostConfig: CoreInterfaces.AdditionalCostConfiguration
): DTOs.AdditionalCostDTO {
  const object: DTOs.AdditionalCostDTO = {
    data: {
      code: additionalCostConfig.Code,
      text: `AdditionalCosts.${additionalCostConfig.Code}.Text`,
      cost: additionalCostConfig.Cost,
      tooltip: `AdditionalCosts.${additionalCostConfig.Code}.TooltipText`,
      valueQuestionCode: additionalCostConfig.ValueQuestionCode,
      numberOfUnits: additionalCostConfig.NumberOfUnits,
      description: "",
      adjustments: {
        Cost: null,
        ExtendedPriceListPanelCost: null,
      },
      serviceTaskGroup: additionalCostConfig.ServiceTaskGroup,
      isFrontendObject: false,
    },
    state: {
      isVisible: true,
      isUsedInSoftwareServiceCostsTable:
        additionalCostConfig.UsedInSoftwareServiceCostsTable,
      isUsedInSoftwareServiceFixedPriceTable:
        additionalCostConfig.UsedInSoftwareServiceFixedPriceTable,
    },
  };
  if (
    !!additionalCostConfig.Adjustments &&
    !!additionalCostConfig.Adjustments.Cost
  ) {
    object.data.adjustments.Cost = additionalCostConfig.Adjustments.Cost;
  }
  return object;
}

export function formServiceDTOsFromServiceConfigurationWrite(
  engagementConfigurationPack: CoreInterfaces.EngagementConfigurationReadPack,
  applicationConfiguration: CoreInterfaces.ApplicationConfigurationReadPack,
  serviceHandlingFn: Function
): Array<DTOs.ServiceDTO> {
  let cleanServiceDTOs: Array<DTOs.ServiceDTO> = [];
  if (!!engagementConfigurationPack?.Services?.length) {
    const serviceWriteConfiguration = engagementConfigurationPack.Services;
    cleanServiceDTOs = formServiceDTOs(servicesConfiguration);
    if (!!applicationConfiguration) {
      cleanServiceDTOs =
        StateProcessors.updateServicesOnApplicationConfigurationChange(
          cleanServiceDTOs,
          applicationConfiguration
        );
    }

    if (cleanServiceDTOs.length) {
      for (const service of cleanServiceDTOs) {
        const serverServiceConfig = serviceWriteConfiguration.find(
          (eachServiceConfig) => eachServiceConfig.Code === service.data.code
        );
        if (!!serverServiceConfig) {
          //tasks
          for (const taskDTO of service.data.tasks) {
            const taskConfig = serverServiceConfig.Tasks.find(
              (taskConfig) => taskConfig.Code === taskDTO.data.code
            );
            if (!!taskConfig) {
              // handle task data
              taskDTO.data.title = taskConfig.Title;
              taskDTO.data.frequency = taskConfig.Frequency;
              taskDTO.data.estimatedTimePerUnit =
                taskConfig.EstimatedTimePerUnit;
              taskDTO.data.allocationOfResponsibility =
                taskConfig.AllocationOfResponsability;
              taskDTO.data.staffLevel = taskConfig.StaffLevel;
              taskDTO.data.comment = taskConfig.Comment;
              taskDTO.data.adjustments.frequency =
                taskConfig.Adjustments.Frequency;
              taskDTO.data.adjustments.allocationOfResponsibility =
                taskConfig.Adjustments.AllocationOfResponsability;
              taskDTO.data.adjustments.staffLevel =
                taskConfig.Adjustments.StaffLevel;
              taskDTO.data.adjustments.comment = taskConfig.Adjustments.Comment;
              taskDTO.data.adjustments.estimatedTimePerUnit =
                taskConfig.Adjustments.EstimatedTimePerUnit;
              taskDTO.data.timeDue = taskConfig.TimeDue;
              taskDTO.state.isActive = taskConfig.IsActive;
            }
          }

          const manuallyAddedTasks = serverServiceConfig.Tasks.filter(
            (taskConfig) => taskConfig.Type === Constants.TaskType.ManuallyAdded
          );
          for (const taskConfig of manuallyAddedTasks) {
            const taskDTO = formTaskDTO();
            taskDTO.data.code = taskConfig.Code;
            taskDTO.data.type = taskConfig.Type;
            taskDTO.data.title = taskConfig.Title;
            taskDTO.data.timeDue = taskConfig.TimeDue;
            taskDTO.data.visibility = taskConfig.Visibility;
            taskDTO.data.frequency = taskConfig.Frequency;
            taskDTO.data.allocationOfResponsibility =
              taskConfig.AllocationOfResponsability;
            taskDTO.data.canModifyAdjustmentFreq = false;
            taskDTO.data.canModifyAllocationResponsibility = true;
            taskDTO.data.canModifyStaffLevel = true;
            taskDTO.data.comment = taskConfig.Comment;
            taskDTO.data.calculationMethod =
              Constants.TaskCalculationMethod.TimeXPricePerHour;
            taskDTO.data.estimatedTimePerUnit = taskConfig.EstimatedTimePerUnit;
            taskDTO.data.staffLevel = taskConfig.StaffLevel;
            taskDTO.data.sumTimeInHrsAndMinutes =
              taskConfig.SumTimeInHrsAndMinutes;
            taskDTO.data.adjustments.frequency =
              taskConfig.Adjustments.Frequency;
            taskDTO.data.adjustments.allocationOfResponsibility =
              taskConfig.Adjustments.AllocationOfResponsability;
            taskDTO.data.adjustments.comment = taskConfig.Adjustments.Comment;
            taskDTO.data.adjustments.estimatedTimePerUnit =
              taskConfig.Adjustments.EstimatedTimePerUnit;
            taskDTO.data.adjustments.staffLevel =
              taskConfig.Adjustments.StaffLevel;
            taskDTO.data.serviceTaskGroup = taskConfig.ServiceTaskGroup;
            taskDTO.state.isActive = taskConfig.IsActive;

            if (
              serverServiceConfig.ServiceRecurringType ===
              Constants.ServiceRecurringType.Monthly
            ) {
              taskDTO.data.canModifyAdjustmentFreq = true;
              taskDTO.data.calculationMethod =
                Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour;
            }

            const index = serverServiceConfig.Tasks.findIndex(
              (taskConfig) => taskConfig.Code === taskDTO.data.code
            );
            service.data.tasks = [
              ...service.data.tasks.slice(0, index),
              taskDTO,
              ...service.data.tasks.slice(index),
            ];
          }

          //questions
          for (const questionDTO of service.data.questions) {
            const questionConfig = serverServiceConfig.Questions.find(
              (questionConfig) => questionConfig.Code === questionDTO.data.code
            );
            if (!!questionConfig) {
              questionDTO.data.defaultValue = questionConfig.DefaultValue;
              questionDTO.state.isFilled = questionConfig.UserInput.IsFilled;
              questionDTO.data.comment = questionConfig.UserInput.Comment;

              const questionDataProcessor =
                questionValueProcessors[questionDTO.data.code];
              if (!!questionDataProcessor) {
                questionDataProcessor(questionDTO, questionConfig);
              } else {
                questionDTO.data.userValue = questionConfig.UserInput.Answer;
                if (
                  questionDTO.data.inputType ===
                    Constants.QuestionInputType.Date &&
                  questionConfig.UserInput.Answer !== null &&
                  questionConfig.UserInput.Answer !== ""
                ) {
                  questionDTO.data.userValue = new Date(
                    questionConfig.UserInput.Answer
                  );
                }
                if (
                  questionDTO.data.inputType ===
                  Constants.QuestionInputType.FullDate
                ) {
                  const dateStrings = JSON.parse(
                    questionConfig.UserInput.Answer
                  );
                  if (Array.isArray(dateStrings) && dateStrings.length > 0) {
                    const dateObjects = dateStrings.map((dateString: any) => {
                      if (dateString !== null) {
                        return new Date(dateString);
                      } else {
                        return null;
                      }
                    });
                    questionDTO.data.userValue = dateObjects;
                  }
                }

                if (
                  questionDTO.data.inputType ===
                    Constants.QuestionInputType.License ||
                  questionDTO.data.inputType ===
                    Constants.QuestionInputType.ContactPersonUserDetails ||
                  questionDTO.data.inputType ===
                    Constants.QuestionInputType.MultipleSelect
                ) {
                  try {
                    questionDTO.data.userValue = JSON.parse(
                      questionConfig.UserInput.Answer
                    );
                  } catch (error) {
                    console.log(
                      "We found an error while parsing the value: ",
                      error
                    );
                    questionDTO.data.userValue = [] as unknown as string;
                  }
                }
              }
            }
          }
          //additional costs
          if (!!service.data.additionalCosts) {
            for (const additionalServiceCostDTO of service.data
              .additionalCosts) {
              const additionalCostConfig =
                serverServiceConfig.AdditionalCosts.find(
                  (eachAdditionalCostConfig) =>
                    eachAdditionalCostConfig.Code ===
                    additionalServiceCostDTO.data.code
                );
              if (!!additionalCostConfig) {
                additionalServiceCostDTO.data.cost = additionalCostConfig.Cost;
                if (
                  additionalCostConfig.Adjustments &&
                  additionalCostConfig.Adjustments.Cost
                ) {
                  additionalServiceCostDTO.data.adjustments.Cost =
                    additionalCostConfig.Adjustments.Cost;
                }
              }
            }
          }

          //service
          service.data.staffingAdjustments.cost =
            serverServiceConfig.StaffingAdjustments.Cost;
          service.data.staffingAdjustments.extendedPriceListPanelCost =
            serverServiceConfig.StaffingAdjustments.ExtendedPriceListPanelCost;
          service.state.isSelected = serverServiceConfig.IsSelected;
          service.state.isExpandedQuestionAccordion =
            serverServiceConfig.IsExpandedQuestionAccordion;
          service.state.isExpandedEngagementDescriptionAccordion =
            serverServiceConfig.IsExpandedEngagementDescriptionAccordion;
          service.state.isExpandedPriceAndTimeCalculationAccordion =
            serverServiceConfig.IsExpandedPriceAndTimeCalculationAccordion;
          serviceHandlingFn(service);
        }
      }
    }
  }

  return cleanServiceDTOs;
}

function formTaskWritePack(
  taskDTOs: Array<DTOs.TaskDTO>
): Array<CoreInterfaces.TaskConfigurationWrite> {
  const tasksPack = (taskDTOs || []).map((taskDTO) => {
    const pack: CoreInterfaces.TaskConfigurationWrite = {
      Code: taskDTO.data.code,
      Type: taskDTO.data.type,
      Title: taskDTO.data.title,
      Frequency: taskDTO.data.frequency,
      AllocationOfResponsability: taskDTO.data.allocationOfResponsibility,
      Comment: taskDTO.data.comment,
      TimeDue: taskDTO.data.timeDue,
      EstimatedTimePerUnit: taskDTO.data.estimatedTimePerUnit,
      StaffLevel: taskDTO.data.staffLevel,
      ServiceTaskGroup: taskDTO.data.serviceTaskGroup,
      SumTimeInHrsAndMinutes: taskDTO.data.sumTimeInHrsAndMinutes,
      Visibility: taskDTO.data.visibility,
      IsActive: taskDTO.state.isActive,
      Adjustments: {
        Frequency: taskDTO.data.adjustments.frequency,
        AllocationOfResponsability:
          taskDTO.data.adjustments.allocationOfResponsibility,
        Comment: taskDTO.data.adjustments.comment,
        EstimatedTimePerUnit: taskDTO.data.adjustments.estimatedTimePerUnit
          ? +taskDTO.data.adjustments.estimatedTimePerUnit
          : null,
        StaffLevel: taskDTO.data.adjustments.staffLevel,
      },
    };
    return pack;
  });
  return tasksPack;
}

function formQuestionWritePack(
  questionDTOs: Array<DTOs.QuestionDTO>
): Array<CoreInterfaces.QuestionConfigurationWrite> {
  const questionsPack = (questionDTOs || []).map((questionDTO) => {
    const pack: CoreInterfaces.QuestionConfigurationWrite = {
      Code: questionDTO.data.code,
      DefaultValue: questionDTO.data.defaultValue,
      UserInput: {
        Answer: questionDTO.data.userValue as unknown as string,
        Comment: questionDTO.data.comment,
        IsFilled: questionDTO.state.isFilled,
      },
    };
    if (
      questionDTO.data.inputType === Constants.QuestionInputType.License ||
      questionDTO.data.inputType ===
        Constants.QuestionInputType.ContactPersonUserDetails ||
      questionDTO.data.inputType ===
        Constants.QuestionInputType.MultipleSelect ||
      questionDTO.data.inputType === Constants.QuestionInputType.FullDate ||
      questionDTO.data.inputType === Constants.QuestionInputType.RangeNumber
    ) {
      pack.UserInput.Answer = JSON.stringify(questionDTO.data.userValue);
    }
    if (questionDTO.data.inputType === Constants.QuestionInputType.Date) {
      pack.UserInput.Answer = isValidDate(questionDTO.data.userValue as Date)
        ? (questionDTO.data.userValue as Date).toISOString()
        : "";
    }
    return pack;
  });
  return questionsPack;
}

function formAdditionalCostsWritePack(
  additionalCosts: Array<DTOs.AdditionalCostDTO>
): Array<CoreInterfaces.TaskCostWrite> {
  const additionalCostsToProcess: Array<DTOs.AdditionalCostDTO> =
    additionalCosts.filter(
      (additionalCost) => !additionalCost.data.isFrontendObject
    ) ?? [];
  const taskCosts: Array<CoreInterfaces.TaskCostWrite> = [];
  for (const additionalCostDTO of additionalCostsToProcess) {
    taskCosts.push({
      Cost: additionalCostDTO.data.cost,
      Code: additionalCostDTO.data.code,
      Adjustments: {
        Cost: additionalCostDTO.data.adjustments.Cost,
      },
    });
  }
  return taskCosts;
}

export function formServiceConfigurationWritePack(
  globalState: CoreInterfaces.AppState,
  businessOpportunityId: string,
  businessUnit: string,
  clientId: string,
  description: string,
  version: number,
  engagementLetterMetadata: CoreInterfaces.EngagementLetterMetadata,
  digitalSigningDetails: CoreInterfaces.DigitalSigningDetails,
  totalCost: number,
  engagementDocumentsLanguage: Constants.Languages = null
): CoreInterfaces.EngagementConfigurationWritePack {
  const servicesPack = globalState.services
    .filter((serviceDTO) => serviceDTO.state.isSelected)
    .map((serviceDTO) => {
      const pack: CoreInterfaces.ServiceConfigurationWrite = {
        Code: serviceDTO.data.code,
        Tasks: formTaskWritePack(serviceDTO.data.tasks),
        Questions: formQuestionWritePack(serviceDTO.data.questions),
        IsSelected: serviceDTO.state.isSelected,
        IsExpandedQuestionAccordion:
          serviceDTO.state.isExpandedQuestionAccordion,
        IsExpandedEngagementDescriptionAccordion:
          serviceDTO.state.isExpandedEngagementDescriptionAccordion,
        IsExpandedPriceAndTimeCalculationAccordion:
          serviceDTO.state.isExpandedPriceAndTimeCalculationAccordion,
        StaffingAdjustments: {
          Cost: serviceDTO.data.staffingAdjustments.cost,
          ExtendedPriceListPanelCost:
            serviceDTO.data.staffingAdjustments.extendedPriceListPanelCost,
        },
        AdditionalCosts: formAdditionalCostsWritePack(
          serviceDTO.data.additionalCosts
        ),
        ServiceRecurringType: serviceDTO.data.recurringType,
        ServiceGroup: serviceDTO.data.serviceGroup,
      };
      return pack;
    });

  const configurationWritePack: CoreInterfaces.EngagementConfigurationWritePack =
    {
      Id: globalState.currentConfiguration.id,
      EngagementLetterParentId:
        globalState.currentConfiguration.engagementLetterParentId,
      Version: version,
      Services: servicesPack,
      BusinessOpportunityId: businessOpportunityId,
      BusinessUnit: businessUnit,
      ClientId: clientId,
      Description: description,
      Status: globalState.currentConfiguration.id
        ? globalState.currentConfiguration.status
        : null,
      CompanyName: globalState.generalVariables.companyName,
      OrganisationNumber: globalState.generalVariables.organisationNo,
      TaskAllocationConfigurationVersion:
        globalState.currentConfiguration.applicationConfiguration
          ?.taskAllocationConfigurationVersion,
      TaskCostConfigurationVersion:
        globalState.currentConfiguration.applicationConfiguration
          ?.taskCostConfigurationVersion,
      RateCartConfiguration:
        globalState.currentConfiguration.applicationConfiguration
          ?.rateCartConfiguration,
      BusinessOpportunityDescription:
        globalState.remoteData.businessOpportunityInfo.Description,
      CreatedBy: globalState.currentConfiguration.createdBy,
      CreatedDate: globalState.currentConfiguration.createdDate,
      SigningDate: globalState.currentConfiguration.signingDate,
      SigningStatus: globalState.currentConfiguration.signingStatus,
      LastAccessedTime: globalState.currentConfiguration.lastAccessedTime,
      LastModifiedDate: globalState.currentConfiguration.lastModifiedDate,
      Consent: globalState.currentConfiguration.consent,
      DigitalSigningDetails: digitalSigningDetails,
      TotalCost: totalCost,
      TotalWorkingHours: globalState.currentConfiguration.totalWorkingHours,
      CrmRowId: globalState.currentConfiguration.crmRowId,
      EngagementLetterProperties: null,
      LastUserChangesDate: !!globalState.currentConfiguration
        .lastUserChangesDate
        ? globalState.currentConfiguration.lastUserChangesDate.toISOString()
        : null,
      Owner: null,
      ValidFromDate: !!globalState.currentConfiguration.validFromDate
        ? globalState.currentConfiguration.validFromDate.toISOString()
        : null,
      ValidUntilDate: !!globalState.currentConfiguration.validUntilDate
        ? globalState.currentConfiguration.validUntilDate.toISOString()
        : null,
      IsHiddenFromImporting:
        globalState.currentConfiguration.isHiddenFromImporting,
      Language: globalState.currentConfiguration.engagementDocumentsLanguage,
    };

  if (engagementLetterMetadata) {
    configurationWritePack.EngagementLetterProperties = {
      CreatedBy: formBEEmployee(engagementLetterMetadata.createdBy),
      CreatedDate: dateToISO(engagementLetterMetadata.createdDate),
    };
  }
  if (globalState.currentConfiguration.owner) {
    configurationWritePack.Owner = formBEEmployeeFromContactPersonItem(
      globalState.currentConfiguration.owner
    );
  }
  if (engagementDocumentsLanguage) {
    configurationWritePack.Language = engagementDocumentsLanguage;
  }
  if (globalState.unsavedChangesTimestamp) {
    const unsavedChangesTimestamp = new Date();
    configurationWritePack.LastUserChangesDate = dateToISO(
      unsavedChangesTimestamp
    );
  }

  return configurationWritePack;
}

export function formEngagementVersionDTO(
  item: CoreInterfaces.EngagementVersionReadPack
): DTOs.EngagementVersionDTO {
  return {
    data: {
      createdBy: item.CreatedBy,
      createdDate: item.CreatedDate,
      description: item.Description,
      engagementConfigurationId: item.EngagementConfigurationId,
      version: item.Version,
      signingStatus: item.SigningStatus,
      signingDate: item.SigningDate,
      status: item.Status,
    },
    state: {
      isSelected: false,
      isDisabled: false,
      canBeDeleted:
        item.SigningStatus !== Constants.SigningStatus.DigitallySigned &&
        item.SigningStatus !== Constants.SigningStatus.ManuallySigned,
    },
  };
}

export function formEmployee(
  employeeReadPack: CoreInterfaces.BEEmployee
): CoreInterfaces.Employee {
  if (employeeReadPack) {
    const object: CoreInterfaces.Employee = {
      Id: null,
      FirstName: employeeReadPack.FirstName,
      LastName: employeeReadPack.LastName,
      Email: employeeReadPack.Email,
      UID: employeeReadPack.UID,
      Office: employeeReadPack.Office,
      BusinessUnit: employeeReadPack.BusinessUnit,
    };
    return object;
  }
  return null;
}

export function formBEEmployee(
  employee: CoreInterfaces.Employee
): CoreInterfaces.BEEmployee {
  if (employee) {
    const object: CoreInterfaces.BEEmployee = {
      FirstName: employee.FirstName,
      LastName: employee.LastName,
      Email: employee.Email,
      UID: employee.UID,
      Office: employee.Office,
      BusinessUnit: employee.BusinessUnit,
    };
    return object;
  }
  return null;
}

export function formBEEmployeeFromContactPersonItem(
  employee: CoreInterfaces.ContactPersonItem
): CoreInterfaces.BEEmployee {
  if (employee) {
    const object: CoreInterfaces.BEEmployee = {
      FirstName: employee.firstName,
      LastName: employee.lastName,
      Email: employee.email,
      UID: employee.key,
      Office: employee.office,
      BusinessUnit: employee.businessUnit,
    };
    return object;
  }
  return null;
}

export function formContactPersonItemFromBEEmployee(
  beEmployee: CoreInterfaces.BEEmployee
): CoreInterfaces.ContactPersonItem {
  if (beEmployee) {
    const object: CoreInterfaces.ContactPersonItem = {
      firstName: beEmployee.FirstName,
      lastName: beEmployee.LastName,
      email: beEmployee.Email,
      key: beEmployee.UID,
      office: beEmployee.Office,
      businessUnit: beEmployee.BusinessUnit,
      value: null,
    };
    return object;
  }
  return null;
}
