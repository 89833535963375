import { CoreInterfaces, DTOs } from "./../../../core/Models";
import * as Constants from "./../../../core/Constants";
import { t } from "i18next";
import { findQuestionInService } from "src/utils/shared";

export const GeneralSectionProcessors: CoreInterfaces.QuestionProcessors = (
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
) => {
  const isService10Selected = appState.services.some(
    (service) =>
      service.data.code === Constants.ServiceCode.EngagementCounseling &&
      service.state.isSelected
  );
  return {
    [Constants.GeneralInformationQuestion.Q0001]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.userValue = appState.remoteData?.companyDetails?.OrgNr;
    },
    [Constants.GeneralInformationQuestion.Q0002]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.userValue = appState.remoteData?.companyDetails?.Name;
    },
    [Constants.GeneralInformationQuestion.Q0003]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = appState.remoteData?.gtContactPersons || [];
    },
    [Constants.GeneralInformationQuestion.Q0004]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const services: Array<Constants.ServiceCode> = [
        Constants.ServiceCode.AccountsReceivable,
        Constants.ServiceCode.AccountsPayable,
        Constants.ServiceCode.CorporateCardManagement,
        Constants.ServiceCode.OtherAccountAndReconciliation,
        Constants.ServiceCode.PeriodReporting,
        Constants.ServiceCode.InterimListedCompanies,
        Constants.ServiceCode.AnnualReporting,
        Constants.ServiceCode.AnnualReportingListedCompanies,
        Constants.ServiceCode.StartUpAccounting,
      ];
      const hasMatchingServices = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          services.includes(eachService.data.code)
      );
      questionDTO.state.isShown = hasMatchingServices;
      questionDTO.data.options = appState.remoteData?.gtContactPersons || [];
    },
    [Constants.GeneralInformationQuestion.Q0005]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const services: Array<Constants.ServiceCode> = [
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
        Constants.ServiceCode.StartUpPayroll,
      ];
      const hasMatchingServices = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          services.includes(eachService.data.code)
      );
      questionDTO.state.isShown = hasMatchingServices;
      questionDTO.data.options = appState.remoteData?.gtContactPersons || [];
    },
    [Constants.GeneralInformationQuestion.Q0007]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const services: Array<Constants.ServiceCode> = [
        Constants.ServiceCode.GeneralInformation,
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
        Constants.ServiceCode.AnnualPayrollRoutines,
        Constants.ServiceCode.StartUpPayroll,
        Constants.ServiceCode.YearlyInternalDocumentationAndFormalities,
      ];
      questionDTO.state.isShown = appState.services.some(
        (eachService) =>
          eachService.state.isSelected &&
          !services.includes(eachService.data.code)
      );
    },
    [Constants.GeneralInformationQuestion.Q0008]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0007 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.GeneralInformationQuestion.Q0007
      );
      questionDTO.state.isShown =
        !isService10Selected &&
        q0007?.state.isShown &&
        q0007?.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.GeneralInformationQuestion.Q0009]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      let isService7Selected = false;
      let isService9Selected = false;
      let areOtherServicesSelected = false;

      for (const serviceDTO of appState.services) {
        if (serviceDTO.state.isSelected) {
          if (
            serviceDTO.data.code ===
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
          ) {
            isService7Selected = true;
          }
          if (serviceDTO.data.code === Constants.ServiceCode.StartUpPayroll) {
            isService9Selected = true;
          }
          if (
            serviceDTO.state.isVisibleInServicesPage &&
            serviceDTO.data.code !==
              Constants.ServiceCode
                .PayrollAndExpenseAndTravelInvoiceManagement &&
            serviceDTO.data.code !== Constants.ServiceCode.StartUpPayroll
          ) {
            areOtherServicesSelected = true;
          }
        }
      }
      questionDTO.state.isShown =
        !isService10Selected &&
        !(
          (isService7Selected || isService9Selected) &&
          !areOtherServicesSelected
        );
    },
    [Constants.GeneralInformationQuestion.Q0010]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = !isService10Selected;
    },
    [Constants.GeneralInformationQuestion.Q0011]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = !isService10Selected;
    },
    [Constants.GeneralInformationQuestion.Q0012]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0011 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.GeneralInformationQuestion.Q0011
      );
      questionDTO.state.isShown =
        q0011?.state.isShown && q0011?.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.GeneralInformationQuestion.Q0014]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const isService7Selected = appState.services.some(
        (serviceDTO) =>
          serviceDTO.data.code ===
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement &&
          serviceDTO.state.isSelected
      );
      const isService9Selected = appState.services.some(
        (serviceDTO) =>
          serviceDTO.data.code === Constants.ServiceCode.StartUpPayroll &&
          serviceDTO.state.isSelected
      );
      const areOtherServicesSelected =
        appState.services.filter(
          (serviceDTO) =>
            serviceDTO.data.code !==
              Constants.ServiceCode
                .PayrollAndExpenseAndTravelInvoiceManagement &&
            serviceDTO.data.code !== Constants.ServiceCode.StartUpPayroll &&
            serviceDTO.state.isSelected &&
            serviceDTO.state.isVisibleInServicesPage
        ).length > 0;
      questionDTO.state.isShown =
        !isService10Selected &&
        !(
          isService7Selected &&
          isService9Selected &&
          !areOtherServicesSelected
        );
    },
    [Constants.GeneralInformationQuestion.Q0015]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0014 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.GeneralInformationQuestion.Q0014
      );
      const isService7Selected = appState.services.some(
        (serviceDTO) =>
          serviceDTO.data.code ===
            Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement &&
          serviceDTO.state.isSelected
      );
      const isService9Selected = appState.services.some(
        (serviceDTO) =>
          serviceDTO.data.code === Constants.ServiceCode.StartUpPayroll &&
          serviceDTO.state.isSelected
      );
      const areOtherServicesSelected =
        appState.services.filter(
          (serviceDTO) =>
            serviceDTO.data.code !==
              Constants.ServiceCode
                .PayrollAndExpenseAndTravelInvoiceManagement &&
            serviceDTO.data.code !== Constants.ServiceCode.StartUpPayroll &&
            serviceDTO.state.isSelected &&
            serviceDTO.state.isVisibleInServicesPage
        ).length > 0;

      questionDTO.state.isShown =
        (!q0014.state.isShown ||
          (q0014.state.isShown &&
            q0014.data.userValue === Constants.YesNo.No)) &&
        !isService10Selected &&
        !(
          isService7Selected &&
          isService9Selected &&
          !areOtherServicesSelected
        );
    },
    [Constants.GeneralInformationQuestion.Q0016]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0022 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.GeneralInformationQuestion.Q0022
      );
      questionDTO.state.isShown =
        q0022?.state.isShown && q0022?.data.userValue === Constants.YesNo.No;
    },
    [Constants.GeneralInformationQuestion.Q0018]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0033 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0033
      );
      questionDTO.data.options = [
        ...(appState.remoteData?.customerContactPersons || []),
      ];
      questionDTO.state.isShown = !(
        q0033.state.isShown && q0033.data.userValue === Constants.YesNo.Yes
      );
    },
    [Constants.GeneralInformationQuestion.Q0019]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.userValue =
        appState.remoteData?.companyDetails?.Addresses[0]?.StreetAddress;
    },
    [Constants.GeneralInformationQuestion.Q0020]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.userValue =
        appState.remoteData?.companyDetails?.Addresses[0]?.ZipCode;
    },
    [Constants.GeneralInformationQuestion.Q0021]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.userValue =
        appState.remoteData?.companyDetails?.Addresses[0]?.City;
    },
    [Constants.GeneralInformationQuestion.Q0022]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = !isService10Selected;
      const questionToBeUpdated: Array<Constants.QuestionCode> = [
        Constants.GeneralInformationQuestion.Q0023,
        Constants.GeneralInformationQuestion.Q0029,
        Constants.GeneralInformationQuestion.Q0032,
        Constants.GeneralInformationQuestion.Q0033,
        Constants.GeneralInformationQuestion.Q0036,
        Constants.GeneralInformationQuestion.Q0043,
      ];
      serviceDTO.data.questions.forEach((eachQuestion) => {
        if (questionToBeUpdated.includes(eachQuestion.data.code)) {
          eachQuestion.state.isShown =
            questionDTO.state.isShown &&
            questionDTO.data.userValue === Constants.YesNo.Yes;
        }
      });
    },
    [Constants.GeneralInformationQuestion.Q0024]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      const q0023 = serviceDTO.data.questions.find(
        (q) => q.data.code === Constants.GeneralInformationQuestion.Q0023
      );
      questionDTO.state.isShown =
        q0022.state.isShown &&
        q0022.data.userValue === Constants.YesNo.Yes &&
        q0023?.state.isShown &&
        q0023?.data.userValue === Constants.FeeExpression.Amount;
    },
    [Constants.GeneralInformationQuestion.Q0025]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      const q0023 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0023
      );
      questionDTO.state.isShown =
        q0022.state.isShown &&
        q0022.data.userValue === Constants.YesNo.Yes &&
        q0023?.state.isShown &&
        q0023?.data.userValue === Constants.FeeExpression.Interval;
    },
    [Constants.GeneralInformationQuestion.Q0030]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      const q0029 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0029
      );
      questionDTO.state.isShown =
        q0022.state.isShown &&
        q0022.data.userValue === Constants.YesNo.Yes &&
        q0029?.state.isShown &&
        q0029?.data.userValue === Constants.FeeExpression.Current;
    },
    [Constants.GeneralInformationQuestion.Q0031]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      const q0029 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0029
      );

      questionDTO.state.isShown =
        isService10Selected ||
        (q0022.state.isShown &&
          q0022.data.userValue === Constants.YesNo.Yes &&
          q0029?.state.isShown &&
          q0029?.data.userValue === Constants.FeeExpression.Period);
    },
    [Constants.GeneralInformationQuestion.Q0034]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = [
        ...(appState.remoteData?.customerContactPersons || []),
      ];
      const q0033 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0033
      );
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      questionDTO.state.isShown =
        q0022.state.isShown &&
        q0022.data.userValue === Constants.YesNo.Yes &&
        q0033?.state.isShown &&
        q0033?.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.GeneralInformationQuestion.Q0035]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0033 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0033
      );
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      questionDTO.state.isShown =
        q0022.state.isShown &&
        q0022.data.userValue === Constants.YesNo.Yes &&
        q0033?.state.isShown &&
        q0033?.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.GeneralInformationQuestion.Q0036]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.data.options = [
        ...(appState.remoteData?.customerContactPersons || []),
      ];
    },
    [Constants.GeneralInformationQuestion.Q0037]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      const q0033 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0033
      );
      const q0022 = findQuestionInService(
        serviceDTO,
        Constants.GeneralInformationQuestion.Q0022
      );
      questionDTO.state.isShown =
        q0022.state.isShown &&
        q0022.data.userValue === Constants.YesNo.Yes &&
        q0033.state.isShown &&
        q0033.data.userValue === Constants.YesNo.Yes;
    },
    [Constants.GeneralInformationQuestion.Q0038]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = isService10Selected;
    },
    [Constants.GeneralInformationQuestion.Q0039]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = isService10Selected;
    },
    [Constants.GeneralInformationQuestion.Q0040]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = isService10Selected;
    },
    [Constants.GeneralInformationQuestion.Q0041]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = isService10Selected;
    },
    [Constants.GeneralInformationQuestion.Q0042]: (
      questionDTO: DTOs.QuestionDTO
    ): void => {
      questionDTO.state.isShown = isService10Selected;
    },
  };
};
