import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../core/Constants";
import { findQuestionInService } from "./shared";
import { calculateEstimatedTimeInMinutes } from "./task-utils";
import { formatMinutesToHoursAndMinutes } from "./helpers";
import { ceil, round } from "./utils";
import { t } from "i18next";
import { priceListConfig } from "./../core/Configurations/PriceList.config";
import { areAllFieldsCompleted, getQuestionValue } from "./question-utils";
import { ServiceRecurringType } from "./../core/Constants";

import ServiceProcessors from "./processors/Services";
import { applyUpdatesOnQuestionChange } from "./processors/Questions";

// calculation
export function getServiceVariable(
  serviceDTO: DTOs.ServiceDTO,
  variableName: Constants.CalculationVariable
): any {
  if (
    serviceDTO.data.calculationVariables &&
    serviceDTO.data.calculationVariables[variableName]
  ) {
    const question = serviceDTO.data.questions.find(
      (questionDTO) =>
        questionDTO.data.code ===
        serviceDTO.data.calculationVariables[variableName]
    );
    if (question) {
      return question.data.userValue;
    }
  }
  return null;
}

export function getTaskCalculationVariable(
  serviceDTO: DTOs.ServiceDTO,
  taskDTO: DTOs.TaskDTO
): any {
  if (taskDTO.data.calculationQuestion) {
    const question = findQuestionInService(
      serviceDTO,
      taskDTO.data.calculationQuestion
    );
    if (question) {
      return question.data.userValue;
    }
  }
  return null;
}

export function getServiceStaffLevelUsageMap(
  serviceDTO: DTOs.ServiceDTO,
  taskGroups: Array<Constants.TaskGroup> = []
): CoreInterfaces.ServiceStaffLevelUsageMap<number> {
  const staffLevelUsageMap: CoreInterfaces.ServiceStaffLevelUsageMap<number> =
    {};
  const usedTasks = serviceDTO.data.tasks.filter((taskDTO) => {
    return (
      taskDTO.state.isVisible &&
      taskDTO.state.isActive &&
      taskGroups.includes(taskDTO.data.serviceTaskGroup)
    );
  });
  usedTasks.forEach((taskDTO) => {
    const staffLevel =
      taskDTO.data.adjustments.staffLevel ??
      (taskDTO.data.staffLevel as Constants.StaffLevel);
    const taskGroup = taskDTO.data.serviceTaskGroup;

    if (staffLevel && taskGroup) {
      staffLevelUsageMap[staffLevel] = staffLevelUsageMap[staffLevel] || {};
      const totalTimeForStaffLevel =
        (staffLevelUsageMap[staffLevel][taskGroup] || 0) +
        calculateEstimatedTimeInMinutes(serviceDTO, taskDTO);
      if (totalTimeForStaffLevel !== 0) {
        staffLevelUsageMap[staffLevel][taskGroup] = totalTimeForStaffLevel;
      }
    }
  });

  // cleanup 0 values
  for (const staffLevel in staffLevelUsageMap) {
    for (const taskGroup in staffLevelUsageMap[
      staffLevel as Constants.StaffLevel
    ]) {
      if (
        staffLevelUsageMap[staffLevel as Constants.StaffLevel][taskGroup] === 0
      ) {
        delete staffLevelUsageMap[staffLevel as Constants.StaffLevel][
          taskGroup
        ];
      }
    }
    if (
      Object.keys(staffLevelUsageMap[staffLevel as Constants.StaffLevel])
        .length === 0
    ) {
      delete staffLevelUsageMap[staffLevel as Constants.StaffLevel];
    }
  }

  return staffLevelUsageMap;
}

/**
 *
 * @param appState
 * @param serviceDTO
 * @param taskGroups
 * @returns an object of type CoreInterfaces.ServiceMonthlyStats
 * @description This function returns an object of type CoreInterfaces.ServiceMonthlyStats. This contains the calculated data for a service ( like: additionalCosts, softwareServiceCosts, workTime, totalPrice) that is used to be displayed in various parts of the application
 * This uses various layers of data ( staffLevel allocation, additionalCosts) in order to calculate the workTime, totalPrice, additionalCosts
 */
export function getServiceStats(
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO,
  taskGroups?: Constants.TaskGroup[]
): CoreInterfaces.ServiceMonthlyStats {
  let mapTaskGroupsToTaskCodes = serviceDTO.data.serviceTaskGroups.map(
    (taskgroup) => taskgroup.Code
  );
  if (!!taskGroups) {
    mapTaskGroupsToTaskCodes = taskGroups;
  }
  const staffLevelUsageMap = getServiceStaffLevelUsageMap(
    serviceDTO,
    mapTaskGroupsToTaskCodes
  );

  const serviceTotalWorkCostVariable = getServiceVariable(
    serviceDTO,
    Constants.CalculationVariable.TotalWorkCost
  );
  const totalWorkCostVariable = Number(serviceTotalWorkCostVariable);

  const recurringMultiplier =
    Constants.RecurringTypeMultiplierMap[serviceDTO.data.recurringType] ?? 12;
  let totalWorkCost = 0;
  let totalWorkTimeInMinutesPerMonth = 0;
  let workCost = 0;
  const adjustments = serviceDTO.data.staffingAdjustments.cost ?? 0;
  const rateCartConfiguration =
    appState.currentConfiguration?.applicationConfiguration
      ?.rateCartConfiguration;

  for (const k in staffLevelUsageMap) {
    const staffLevelCode = k as Constants.StaffLevel;
    const rateCart = rateCartConfiguration.RateCarts.find(
      (eachRateCart) => eachRateCart.StaffLevelCode === staffLevelCode
    );
    if (!!rateCart) {
      const workMinutes = staffLevelUsageMap[staffLevelCode];
      for (const taskGroupWorkMinutes in workMinutes) {
        const workHours = ceil(workMinutes[taskGroupWorkMinutes]) / 60;
        workCost = workHours * rateCart.RateOnHour;
        totalWorkTimeInMinutesPerMonth += workMinutes[taskGroupWorkMinutes];
        totalWorkCost += workCost;
      }
    }
  }
  const additionalCostsTotal = getServiceAdditionalCostsTotal(serviceDTO);

  let additionalCostsAdjustment = 0;
  serviceDTO.data.additionalCosts.forEach((additionalCost) => {
    if (additionalCost.state.isVisible) {
      if (!!additionalCost.data.adjustments.Cost) {
        additionalCostsAdjustment += additionalCost.data.adjustments.Cost;
      } else {
        additionalCostsAdjustment += additionalCost.data.cost;
      }
    }
  });

  const extendedPriceListPanelCost =
    serviceDTO.data.staffingAdjustments.extendedPriceListPanelCost || 0;
  const totalPrice =
    totalWorkCost +
    totalWorkCostVariable +
    additionalCostsTotal +
    (adjustments || 0);
  const totalPricePerMonth = ceil(totalPrice);
  const totalPricePerYear = recurringMultiplier * totalPricePerMonth;
  const totalWorkTimeInMinutesPerYear =
    recurringMultiplier * round(totalWorkTimeInMinutesPerMonth);
  const object: CoreInterfaces.ServiceMonthlyStats = {
    additionalCostsAdjustment: additionalCostsAdjustment,
    workTimeInMinutesPerMonth: totalWorkTimeInMinutesPerMonth,
    workTimePricePerMonth: totalWorkCost + adjustments,
    softwareServicesCostPerMonth: additionalCostsTotal,
    totalPricePerMonth,
    totalPricePerYear,
    workTimeInMinutesPerYear: totalWorkTimeInMinutesPerYear,
    extendedPriceListPanelCostAdjustment: extendedPriceListPanelCost,
  };
  return object;
}

export function getServiceAdditionalCostsTotal(
  serviceDTO: DTOs.ServiceDTO
): number {
  let additionalCostsTotal = 0;
  const visibleAdditionalCostDTOs = serviceDTO.data.additionalCosts.filter(
    (eachAdditionalCostDTO) => eachAdditionalCostDTO.state.isVisible
  );
  for (const additionalCostDTO of visibleAdditionalCostDTOs) {
    let usedValue = null;
    if (!!additionalCostDTO.data.numberOfUnits) {
      usedValue = additionalCostDTO.data.numberOfUnits;
    } else {
      if (!!additionalCostDTO.data.valueQuestionCode) {
        const usedQuestion = findQuestionInService(
          serviceDTO,
          additionalCostDTO.data.valueQuestionCode
        );
        if (!!usedQuestion && !!usedQuestion.data.userValue) {
          usedValue = usedQuestion.data.userValue;
        }
      }
    }
    const rowTotal = Number(usedValue) * additionalCostDTO.data.cost;
    additionalCostsTotal += rowTotal;
  }
  return additionalCostsTotal;
}

export function getServicePriceAccordingToPricelist(
  service71DTO: DTOs.ServiceDTO
): number {
  if (!!service71DTO.data.priceList) {
    const amountStr = getServiceVariable(
      service71DTO,
      Constants.CalculationVariable.Amount
    );

    if (!!amountStr) {
      const amount = Number(amountStr);
      const applicablePriceList = priceListConfig[service71DTO.data.priceList];
      if (!!applicablePriceList) {
        const q0705 = findQuestionInService(
          service71DTO,
          Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0705
        );
        const q0756 = findQuestionInService(
          service71DTO,
          Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0756
        );
        const q0705Value = getQuestionValue(q0705);
        const q0756Value = getQuestionValue(q0756);
        const applicableThreshold = applicablePriceList.Thresholds.find(
          (thresholdConfig) =>
            thresholdConfig.AmountMin <= amount &&
            thresholdConfig.AmountMax >= amount
        );
        if (!!applicableThreshold) {
          let price = applicableThreshold.BasicEmployeePrice;
          if (
            !!applicableThreshold.FlexHRMPrice &&
            !!q0756.state.isShown &&
            q0756Value === Constants.YesNo.Yes
          ) {
            price += applicableThreshold.FlexHRMPrice;
          }
          if (
            !!applicableThreshold.WorkerPrice &&
            !!q0705.state.isShown &&
            q0705Value === Constants.YesNo.Yes
          ) {
            price += applicableThreshold.WorkerPrice;
          }

          if (
            applicablePriceList.PriceListDataType ===
            Constants.PriceListDataType.DynamicPrice
          ) {
            price *= amount;
          }

          return price;
        }
      }
    }
  }
  return 0;
}

function getServiceTaskCostPrice(
  serviceDTO: DTOs.ServiceDTO,
  taskCostCode: string
): number {
  const additionalCostDTO = serviceDTO.data.additionalCosts.find(
    (eachAdditionalCosts) => eachAdditionalCosts.data.code === taskCostCode
  );
  if (additionalCostDTO) {
    return additionalCostDTO.data.cost;
  }
  return 0;
}
/**
 *
 * @param globalState
 * @param serviceRecurringType
 * @returns an array of CoreInterfaces.RecurringServicesTableRow objects containing the calculated time and cost for each recurring service.
 * @description This function iterates over all services, and filter only those which are selected, visible in services page which belongs to Accounting group.
 *  It determines the number of hours and cost for each of them and returns an array of CoreInterfaces.RecurringServicesTableRow objects
 */
export function getRecurringServicePanelTableRows(
  globalState: CoreInterfaces.AppState,
  serviceRecurringType: Constants.ServiceRecurringType
): Array<CoreInterfaces.RecurringServicesTableRow> {
  const recurringServices = globalState.services.filter(
    (eachService) =>
      eachService.state.isSelected &&
      eachService.data.serviceGroup === Constants.ServiceGroup.Accounting &&
      eachService.data.recurringType === serviceRecurringType &&
      eachService.state.isVisibleInServicesPage
  );

  let totalMinutesPerPeriod = 0;
  let totalWorkTimePricePerPeriod = 0;
  let totalSoftwareServicesPerPeriod = 0;
  let totalPricePerPeriod = 0;
  let totalPricePerYear = 0;
  let totalMinutesPerYear = 0;
  const rows: Array<CoreInterfaces.RecurringServicesTableRow> = [];

  for (const serviceDTO of recurringServices) {
    const recurringServiceStats = getServiceStats(globalState, serviceDTO);
    totalMinutesPerPeriod += recurringServiceStats.workTimeInMinutesPerMonth;
    totalWorkTimePricePerPeriod += ceil(
      recurringServiceStats.workTimePricePerMonth
    );
    totalSoftwareServicesPerPeriod +=
      recurringServiceStats.softwareServicesCostPerMonth;
    totalPricePerPeriod += recurringServiceStats.totalPricePerMonth;
    totalPricePerYear += recurringServiceStats.totalPricePerYear;
    totalMinutesPerYear += recurringServiceStats.workTimeInMinutesPerYear;

    const row: CoreInterfaces.RecurringServicesTableRow = {
      title: t(serviceDTO.data.title),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(
        recurringServiceStats.workTimeInMinutesPerMonth
      ),
      workTimePricePerPeriod: `${ceil(
        recurringServiceStats.workTimePricePerMonth
      )} ${Constants.USED_CURRENCY}`,
      minutesPerPeriodUnderlineValue: null,
      softwareServicesPerPeriod: `${ceil(
        recurringServiceStats.softwareServicesCostPerMonth
      )} ${Constants.USED_CURRENCY}`,
      totalPricePerPeriod: `${ceil(recurringServiceStats.totalPricePerMonth)} ${
        Constants.USED_CURRENCY
      }`,
      totalPricePerPeriodUnderlineValue: null,
      totalPricePerYear: `${ceil(recurringServiceStats.totalPricePerYear)} ${
        Constants.USED_CURRENCY
      }`,
      totalPricePerYearUnderlineValue: null,
      totalHoursPerYear: formatMinutesToHoursAndMinutes(
        recurringServiceStats.workTimeInMinutesPerYear
      ),
      id: `recurringSummaryServicesPanelService${serviceDTO.data.code}`,
    };

    rows.push(row);
  }

  if (rows.length) {
    const summaryRow: CoreInterfaces.RecurringServicesTableRow = {
      title: t(
        `General.Total${serviceRecurringType}RecurringServicesForAccounting`
      ),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(totalMinutesPerPeriod),
      workTimePricePerPeriod: `${ceil(totalWorkTimePricePerPeriod)} ${
        Constants.USED_CURRENCY
      }`,
      minutesPerPeriodUnderlineValue: totalMinutesPerPeriod,
      softwareServicesPerPeriod: `${ceil(totalSoftwareServicesPerPeriod)} ${
        Constants.USED_CURRENCY
      }`,
      totalPricePerPeriod: `${ceil(totalPricePerPeriod)} ${
        Constants.USED_CURRENCY
      }`,
      totalPricePerPeriodUnderlineValue: totalPricePerPeriod,
      totalPricePerYear: `${ceil(totalPricePerYear)} ${
        Constants.USED_CURRENCY
      }`,
      totalPricePerYearUnderlineValue: totalPricePerYear,
      totalHoursPerYear: formatMinutesToHoursAndMinutes(totalMinutesPerYear),
      id: Constants.CustomDataGridColumnId.RecurringServicesTotal,
    };
    rows.push(summaryRow);
  }

  return rows;
}

export function getRecurringServicesStaffLevelUsageMap(
  globalState: CoreInterfaces.AppState,
  serviceGroup: Constants.ServiceGroup,
  serviceRecurringType: Constants.ServiceRecurringType = null,
  taskGroups: Array<Constants.TaskGroup>
): any {
  const recurringServices = globalState.services.filter(
    (eachService) =>
      eachService.state.isSelected &&
      eachService.data.serviceGroup === serviceGroup &&
      (serviceRecurringType === null
        ? true
        : eachService.data.recurringType === serviceRecurringType) &&
      eachService.state.isVisibleInServicesPage
  );

  const staffLevelUsageMap: {
    [key in Constants.StaffLevel]?: number;
  } = {};
  for (const recurringService of recurringServices) {
    const serviceStaffLevelUsageMap = getServiceStaffLevelUsageMap(
      recurringService,
      taskGroups
    );

    for (const staffLevel in serviceStaffLevelUsageMap) {
      staffLevelUsageMap[staffLevel as Constants.StaffLevel] =
        staffLevelUsageMap[staffLevel as Constants.StaffLevel] || 0;
      let totalTimeForStaffLevel = 0;
      for (const taskGroup of taskGroups) {
        totalTimeForStaffLevel +=
          serviceStaffLevelUsageMap[staffLevel as Constants.StaffLevel][
            taskGroup
          ] || 0;
      }

      staffLevelUsageMap[staffLevel as Constants.StaffLevel] +=
        totalTimeForStaffLevel;
    }
  }

  return staffLevelUsageMap;
}

/**
 *
 * @param globalState
 * @returns array of CoreInterfaces.PayrollServicesTableRow objects
 * @description This function calculates the numbers that needs to be displayed on the PayrollMonthlyRecurringServicesPanel, for "Payroll, expense/travel claims incl vacation year-end and year-end change" service
 */
export function getPayrollServicePanelTableRows(
  globalState: CoreInterfaces.AppState
): Array<CoreInterfaces.PayrollServicesTableRow> {
  const rows: Array<CoreInterfaces.PayrollServicesTableRow> = [];
  const usedServices = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
  ]);

  const additionalPayrollServiceRows =
    createServiceStaffingAllocationSummaryPanelTableRows(
      globalState.currentConfiguration?.applicationConfiguration
        ?.rateCartConfiguration,
      usedServices[
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
      ],
      [
        Constants.TaskGroup.PayrollServicesInAddition,
        Constants.TaskGroup.AdditionalPayrollServices,
      ]
    );
  if (
    usedServices[
      Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
    ].state.isSelected
  ) {
    const service71 =
      usedServices[
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
      ];

    let totalMinutesPerPeriod = 0;
    let totalPricePerPeriod = 0;
    let totalPricePerYear = 0;
    let totalMinutesPerYear = 0;

    const service71PriceListPrice =
      getServicePriceAccordingToPricelist(service71);
    let chargePerPayrollRun = 0;
    if (service71.data.priceList === Constants.PriceList.PriceList4) {
      chargePerPayrollRun = getServiceTaskCostPrice(
        service71,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
          .T0774
      );
    }

    const recurringServiceStats = getServiceStats(globalState, service71, [
      Constants.TaskGroup.MonthlyPayrollReconciliation,
      Constants.TaskGroup.MonthlyPayrollRun,
      Constants.TaskGroup.MonthlyReportingPayroll,
    ]);
    const service7DTO = globalState.services.find(
      (service) =>
        service.data.code ===
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement
    );
    const serviceStaffingAllocationExtendedSummaryPanelRows =
      getServiceStaffingAllocationExtendedSummaryPanelRows(
        globalState,
        service7DTO,
        [
          Constants.TaskGroup.MonthlyPayrollRun,
          Constants.TaskGroup.MonthlyPayrollReconciliation,
          Constants.TaskGroup.MonthlyReportingPayroll,
        ]
      );

    const pricePerPayslipTotalRow =
      serviceStaffingAllocationExtendedSummaryPanelRows.find(
        (row) => row.id === Constants.CustomDataGridColumnId.TotalCost
      ) as CoreInterfaces.AllocationServiceExtendedSummaryRow;

    const adjustment =
      recurringServiceStats.extendedPriceListPanelCostAdjustment;
    totalMinutesPerPeriod += recurringServiceStats.workTimeInMinutesPerMonth;
    totalMinutesPerYear += recurringServiceStats.workTimeInMinutesPerYear;

    const row: CoreInterfaces.PayrollServicesTableRow = {
      title: t(
        "Services.PayrollAndExpenseAndTravelInvoiceManagement.MonthlyServiceRecurringTextForPayroll"
      ),
      hoursPerPeriod: formatMinutesToHoursAndMinutes(
        recurringServiceStats.workTimeInMinutesPerMonth
      ),
      minutesPerPeriodUnderlineValue: null,
      pricePerPeriod: null,
      pricePerPayslip: `${
        pricePerPayslipTotalRow?.pricePerPayslipUnderlineValue || 0
      } ${Constants.Currency.kr}`,
      chargePerPayrollRun: null,
      totalPricePerPeriod: null,
      totalPricePerPeriodUnderlineValue: null,
      totalPricePerYear: `${ceil(recurringServiceStats.totalPricePerYear)} ${
        Constants.USED_CURRENCY
      }`,
      totalPricePerYearUnderlineValue: null,
      totalHoursPerYear: formatMinutesToHoursAndMinutes(
        recurringServiceStats.workTimeInMinutesPerYear
      ),
      id: `recurringSummaryServicesPanelService${service71.data.code}`,
    };

    const pricePerMonthPerService =
      service71PriceListPrice + adjustment + chargePerPayrollRun;

    totalPricePerPeriod += pricePerMonthPerService;
    totalPricePerYear +=
      Constants.RecurringTypeMultiplierMap[ServiceRecurringType.Monthly] *
      pricePerMonthPerService;

    row.pricePerPeriod = `${ceil(service71PriceListPrice + adjustment)} ${
      Constants.USED_CURRENCY
    }`;
    row.chargePerPayrollRun = `${ceil(chargePerPayrollRun)} ${
      Constants.USED_CURRENCY
    }`;
    row.totalPricePerPeriod = `${ceil(pricePerMonthPerService)} ${
      Constants.USED_CURRENCY
    }`;
    row.totalPricePerYear = `${ceil(
      Constants.RecurringTypeMultiplierMap[ServiceRecurringType.Monthly] *
        pricePerMonthPerService
    )} ${Constants.USED_CURRENCY}`;

    rows.push(row);

    const additionalPayrollServiceTotalsRow = additionalPayrollServiceRows.find(
      (eachRow) => eachRow.id === Constants.CustomDataGridColumnId.TotalCost
    ) as CoreInterfaces.AllocationServiceCalculationSummaryTotalCostRow;
    if (!!additionalPayrollServiceTotalsRow) {
      const additionalPayrollTotalMinutesPerYear =
        12 * additionalPayrollServiceTotalsRow.totalWorkMinutes;
      const additionalPayrollTotalPricePerYear =
        12 * round(additionalPayrollServiceTotalsRow.sumUnderlineValue);
      totalMinutesPerPeriod +=
        additionalPayrollServiceTotalsRow.totalWorkMinutes;
      totalMinutesPerYear += additionalPayrollTotalMinutesPerYear;
      totalPricePerPeriod +=
        additionalPayrollServiceTotalsRow.sumUnderlineValue;
      totalPricePerYear += additionalPayrollTotalPricePerYear;
      const additionalPayrollServiceRow: CoreInterfaces.PayrollServicesTableRow =
        {
          title: t(
            "Services.PayrollAndExpenseAndTravelInvoiceManagement.TotalSumText"
          ),
          hoursPerPeriod: formatMinutesToHoursAndMinutes(
            additionalPayrollServiceTotalsRow.totalWorkMinutes
          ),
          minutesPerPeriodUnderlineValue: null,
          pricePerPeriod: null,
          pricePerPayslip: null,
          chargePerPayrollRun: null,
          totalPricePerPeriod: `${round(
            additionalPayrollServiceTotalsRow.sumUnderlineValue
          )} ${Constants.USED_CURRENCY}`,
          totalPricePerPeriodUnderlineValue: round(
            additionalPayrollServiceTotalsRow.sumUnderlineValue
          ),
          totalPricePerYear: `${ceil(additionalPayrollTotalPricePerYear)} ${
            Constants.USED_CURRENCY
          }`,
          totalPricePerYearUnderlineValue: additionalPayrollTotalPricePerYear,
          totalHoursPerYear: formatMinutesToHoursAndMinutes(
            additionalPayrollTotalMinutesPerYear
          ),
          id: `recurringSummaryServicesPayrollPanelService${Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement}`,
        };
      rows.push(additionalPayrollServiceRow);
    }

    if (rows.length) {
      const summaryRow: CoreInterfaces.PayrollServicesTableRow = {
        title: t(`General.TotalMonthlyRecurringServicesForPayroll`),
        hoursPerPeriod: formatMinutesToHoursAndMinutes(totalMinutesPerPeriod),
        minutesPerPeriodUnderlineValue: totalMinutesPerPeriod,
        pricePerPeriod: null,
        pricePerPayslip: null,
        chargePerPayrollRun: null,
        totalPricePerPeriod: `${ceil(totalPricePerPeriod)} ${
          Constants.USED_CURRENCY
        }`,
        totalPricePerPeriodUnderlineValue: totalPricePerPeriod,
        totalPricePerYear: `${ceil(totalPricePerYear)} ${
          Constants.USED_CURRENCY
        }`,
        totalPricePerYearUnderlineValue: totalPricePerYear,
        totalHoursPerYear: formatMinutesToHoursAndMinutes(totalMinutesPerYear),
        id: Constants.CustomDataGridColumnId.PayrollServicesTotal,
      };
      rows.push(summaryRow);
    }
  }

  return rows;
}

export function getPayrollSystem(globalState: CoreInterfaces.AppState): string {
  let result = null;
  const payrollAndExpenseAndTravelInvoiceManagementService =
    globalState.services.find(
      (service) =>
        service.data.code ===
          Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement &&
        service.state.isSelected
    );
  if (payrollAndExpenseAndTravelInvoiceManagementService) {
    switch (payrollAndExpenseAndTravelInvoiceManagementService.data.priceList) {
      case Constants.PriceList.PriceList1:
      case Constants.PriceList.PriceList2: {
        result = t(`General.${Constants.PayrollSystems.Fortnox}`);
        break;
      }
      case Constants.PriceList.PriceList3:
      case Constants.PriceList.PriceList4: {
        result = t(`General.${Constants.PayrollSystems.HogiaLonPlus}`);
        break;
      }
    }
  }

  return result;
}

export function resolveService7Pricelist(service7DTO: DTOs.ServiceDTO): void {
  const q0701 = findQuestionInService(
    service7DTO,
    Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701
  );
  const q0702 = findQuestionInService(
    service7DTO,
    Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0702
  );
  const q0703 = findQuestionInService(
    service7DTO,
    Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0703
  );
  if (q0702.state.isShown && q0702.data.userValue === Constants.YesNo.Yes) {
    service7DTO.data.priceList = Constants.PriceList.PriceList1;
  }
  if (
    !q0702.state.isShown ||
    (q0702.state.isShown && q0702.data.userValue !== Constants.YesNo.Yes)
  ) {
    if (Number(q0701.data.userValue) <= 10) {
      const q0704 = findQuestionInService(
        service7DTO,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0704
      );
      if (
        !!q0704 &&
        q0704.state.isShown &&
        q0703.state.isShown &&
        q0703.data.userValue === Constants.YesNo.No &&
        (!q0702.state.isShown ||
          (q0702.state.isShown && q0702.data.userValue !== Constants.YesNo.Yes))
      ) {
        if (
          q0704.data.userValue === Constants.CollectiveAgreement.NoAgreement
        ) {
          service7DTO.data.priceList = Constants.PriceList.PriceList2;
        } else {
          service7DTO.data.priceList = Constants.PriceList.PriceList3;
        }
      }
    }
    if (
      Number(q0701.data.userValue) > 10 ||
      q0703.data.userValue === Constants.YesNo.Yes
    ) {
      service7DTO.data.priceList = Constants.PriceList.PriceList4;
    }
  }
}

/**
 *
 * @param rateCartConfiguration
 * @param serviceDTO
 * @param taskGroups
 * @returns an array of CoreInterfaces.AllocationServiceCalculationSummaryRow objects
 * @description This function returns the time and cost for each staff level which is used on the specified service.
 */
export function createServiceStaffingAllocationSummaryPanelTableRows(
  rateCartConfiguration: CoreInterfaces.RateCartConfiguration,
  serviceDTO: DTOs.ServiceDTO,
  taskGroups?: Array<Constants.TaskGroup>
): Array<CoreInterfaces.AllocationServiceCalculationSummaryRow> {
  const rowsArr: Array<CoreInterfaces.AllocationServiceCalculationSummaryRow> =
    [];
  if (!serviceDTO.state.isSelected) {
    return rowsArr;
  }

  const serviceProcessor =
    ServiceProcessors.CalculationSummary[serviceDTO.data.code];
  if (!serviceProcessor) {
    return rowsArr;
  }
  return serviceProcessor(rateCartConfiguration, serviceDTO, taskGroups);
}
// end calculation

export function getVisibleTaskRowsForService<T>(
  serviceDTO: DTOs.ServiceDTO,
  taskProcessors: {
    [taskCode in Constants.TaskCode]?: (taskDTO: DTOs.TaskDTO) => T;
  },
  visibility: Constants.TaskVisibilityArea,
  getDefaultTableRowFn: Function
): Array<T> {
  const rows: Array<T> = [];
  const visibleTasks = serviceDTO.data.tasks.filter(
    (item) => item.data.visibility && item.data.visibility.includes(visibility)
  );
  for (const taskDTO of visibleTasks) {
    if (taskDTO.state.isVisible) {
      const taskProcessor = taskProcessors[taskDTO.data.code];
      let taskRow = getDefaultTableRowFn(serviceDTO, taskDTO);
      if (!!taskProcessor) {
        taskRow = taskProcessor(taskDTO);
      }
      if (!!taskRow) {
        rows.push(taskRow);
      }
    }
  }
  return rows;
}

//get multiplicator
export function getFrequencyMultiplicator(
  taskDTO: DTOs.TaskDTO,
  serviceDTO: DTOs.ServiceDTO
) {
  let multiplicator = 0;
  switch (taskDTO.data.calculationMethod) {
    case Constants.TaskCalculationMethod.AmountXTimeXPricePerHour: {
      if (
        !!taskDTO.data.calculationQuestionOverride &&
        taskDTO.data.calculationQuestionOverride.state.isShown
      ) {
        multiplicator = taskDTO.data.calculationQuestionOverride.data.userValue
          ? parseFloat(
              taskDTO.data.calculationQuestionOverride.data.userValue as string
            )
          : 0;
      } else {
        if (!!taskDTO.data.calculationQuestion) {
          multiplicator = getTaskCalculationVariable(serviceDTO, taskDTO) ?? 0;
        } else {
          multiplicator =
            getServiceVariable(
              serviceDTO,
              Constants.CalculationVariable.Amount
            ) ?? 0;
        }
      }
      break;
    }
    case Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour:
    case Constants.TaskCalculationMethod.FrequencyXAmountXTime: {
      const frequency = !!taskDTO.data.adjustments.frequency
        ? taskDTO.data.adjustments.frequency
        : taskDTO.data.frequency;
      if (!!frequency) {
        const frequencyValue = Constants.FrequencyValueMap[frequency];
        if (
          taskDTO.data.type === Constants.TaskType.ManuallyAdded &&
          (serviceDTO.data.recurringType as string) === frequency
        ) {
          multiplicator = 1;
        } else {
          multiplicator = frequencyValue !== undefined ? frequencyValue : 0;
        }
      }
      break;
    }
    case Constants.TaskCalculationMethod.TimeXPricePerHour: {
      if (!!taskDTO.data.calculationQuestion) {
        multiplicator = getTaskCalculationVariable(serviceDTO, taskDTO) ?? 1;
      } else {
        multiplicator = 1;
      }
      break;
    }
  }

  return multiplicator;
}

function getIsServiceCompleted(service: DTOs.ServiceDTO): boolean {
  const visibileQuestions = service.data.questions.filter(
    (item) => item.state.isShown
  );

  const questionsWithAnswerField = visibileQuestions.filter(
    (item) => !item.state.isInfoTextVariat && item.data.inputType
  );

  const isAllFilled = questionsWithAnswerField.every(
    (item) => item.state.isFilled && areAllFieldsCompleted(item)
  );

  return isAllFilled;
}

export function getAreAllServicesCompleted(
  services: Array<DTOs.ServiceDTO>
): boolean {
  return services.every((service: DTOs.ServiceDTO) =>
    getIsServiceCompleted(service)
  );
}

export function isDigitallySigningChecked(
  appState: CoreInterfaces.AppState
): boolean {
  const q0017Value = findQuestionInService(
    appState.services.find(
      (service) =>
        service.data.code === Constants.ServiceCode.GeneralInformation
    ),
    Constants.GeneralInformationQuestion.Q0017
  )?.data?.userValue;

  return q0017Value === Constants.YesNo.Yes;
}

export function mapServiceCodesToServicesDTOs(
  globalState: CoreInterfaces.AppState,
  serviceCodes: Array<Constants.ServiceCode>
): {
  [key in Constants.ServiceCode]?: DTOs.ServiceDTO;
} {
  const object: { [key in Constants.ServiceCode]?: DTOs.ServiceDTO } = {};
  for (const serviceDTO of globalState.services) {
    if (serviceCodes.includes(serviceDTO.data.code)) {
      object[serviceDTO.data.code] = serviceDTO;
    }
  }
  return object;
}

export function isOutdatedVersion(
  globalState: CoreInterfaces.AppState
): boolean {
  return (
    globalState.currentConfiguration.status ===
    Constants.ConfigurationStatus.OutdatedVersion
  );
}

export function isFinalorOutdatedVersion(
  globalState: CoreInterfaces.AppState
): boolean {
  return (
    globalState.currentConfiguration.status ===
      Constants.ConfigurationStatus.FinalVersion ||
    globalState.currentConfiguration.status ===
      Constants.ConfigurationStatus.OutdatedVersion
  );
}

export function sanitizeWorkMinutes(workMinutes: number): number {
  return isNaN(workMinutes) ? 0 : workMinutes;
}

export function calculateTaskGroupSum(
  workMinutes: CoreInterfaces.ServiceStaffLevelUsageMap<number>,
  taskGroups: Array<string>
): number {
  let totalSum = 0;

  taskGroups.forEach((taskGroup) => {
    for (const staffLevel in workMinutes) {
      const staffLevelMinutes = workMinutes[staffLevel as Constants.StaffLevel];
      if (staffLevelMinutes && staffLevelMinutes[taskGroup]) {
        const taskGroupMinutes = staffLevelMinutes[taskGroup];
        totalSum += taskGroupMinutes;
      }
    }
  });
  return totalSum;
}

export function resolveService6FixedPrice(
  globalState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO
) {
  const visibleAdditionalCostDTOs = serviceDTO.data.additionalCosts.filter(
    (eachAdditionalCostDTO) =>
      eachAdditionalCostDTO.state.isUsedInSoftwareServiceFixedPriceTable &&
      eachAdditionalCostDTO.state.isVisible
  );

  const rows: Array<CoreInterfaces.FixedPriceTableRow> = [];

  let total = 0;
  let totalBasePriceAdjustment = 0;
  let totalNumberOfHours = 0;
  let hoursAndMinutesStatutoryAnnualAccounts = 0;
  let hoursAndMinutesAnnualAccounts = 0;
  let hoursAndMinutesCorporateIncomeTax = 0;
  let hoursAndMinutes = 0;

  let referenceCostAnnualAccounts = 0;
  let referenceCostStatutoryAnnualAccounts = 0;
  let referenceCostCorporateIncomeTax = 0;
  let referenceCostWork = 0;

  if (visibleAdditionalCostDTOs.length > 0) {
    const serviceProcessor =
      ServiceProcessors.CalculationSummary[serviceDTO.data.code];
    if (serviceProcessor) {
      const rows = serviceProcessor(
        globalState.currentConfiguration?.applicationConfiguration
          ?.rateCartConfiguration,
        serviceDTO,
        serviceDTO.data.serviceTaskGroups.map((taskGroup) => taskGroup.Code)
      ) as Array<CoreInterfaces.AnnualReportingSummaryTableRow>;

      for (const row of rows) {
        if (row.id === Constants.CustomDataGridColumnId.TotalWorkCost) {
          hoursAndMinutesStatutoryAnnualAccounts =
            row.hoursAndMinutesStatutoryAnnualAccountsUnderlineValue;
          hoursAndMinutesAnnualAccounts =
            row.hoursAndMinutesAnnualAccountsUnderlineValue;
          hoursAndMinutesCorporateIncomeTax =
            row.hoursAndMinutesCorporateIncomeTaxUnderlineValue;
        } else {
          //each staffLevel with its pricePerHour
          referenceCostAnnualAccounts +=
            row.hoursAndMinutesAnnualAccountsUnderlineValue *
            parseInt(row.pricePerHour);
          referenceCostStatutoryAnnualAccounts +=
            row.hoursAndMinutesStatutoryAnnualAccountsUnderlineValue *
            parseInt(row.pricePerHour);
          referenceCostCorporateIncomeTax +=
            row.hoursAndMinutesCorporateIncomeTaxUnderlineValue *
            parseInt(row.pricePerHour);
        }
      }
    }

    for (const additionalCostDTO of visibleAdditionalCostDTOs) {
      total += additionalCostDTO.data.cost;
      switch (additionalCostDTO.data.serviceTaskGroup) {
        case Constants.TaskGroup.AnnualReport:
          hoursAndMinutes = hoursAndMinutesAnnualAccounts;
          referenceCostWork = round(referenceCostAnnualAccounts / 60);
          break;
        case Constants.TaskGroup.StatutoryAnnualReport:
          hoursAndMinutes = hoursAndMinutesStatutoryAnnualAccounts;
          referenceCostWork = round(referenceCostStatutoryAnnualAccounts / 60);
          break;
        case Constants.TaskGroup.CorporateIncomeTax:
          hoursAndMinutes = hoursAndMinutesCorporateIncomeTax;
          referenceCostWork = round(referenceCostCorporateIncomeTax / 60);
          break;
      }
      totalNumberOfHours += hoursAndMinutes;

      const row = {
        title: t(additionalCostDTO.data.text),
        numberOfHours: formatMinutesToHoursAndMinutes(hoursAndMinutes),
        basePrice: `${additionalCostDTO.data.cost} ${Constants.USED_CURRENCY}`,
        basePriceAdjustment: `${additionalCostDTO.data.adjustments.Cost || ""}`,
        referenceCostWork: `${referenceCostWork} ${Constants.USED_CURRENCY}`,
        id: `additionalCostTask${additionalCostDTO.data.code}`,
        additionalCostCode: additionalCostDTO.data.code,
        serviceDTO: serviceDTO,
      };
      rows.push(row);
      totalBasePriceAdjustment += additionalCostDTO.data.adjustments.Cost;
    }

    if (rows.length > 0) {
      // total service costs
      const row = {
        title: "",
        basePrice: `${ceil(total)} ${Constants.USED_CURRENCY}`,
        basePriceAdjustment: `${totalBasePriceAdjustment} ${Constants.USED_CURRENCY}`,
        referenceCostWork: "",
        numberOfHours: formatMinutesToHoursAndMinutes(totalNumberOfHours),
        id: Constants.CustomDataGridColumnId.AdditionalCostsTotalCost,
        additionalCostCode: null as Constants.AdditionalCostConfigurationCode,
        serviceDTO: serviceDTO,
      };
      rows.push(row);
    }
    return rows;
  }
}

export function getEngagementMonthlyStats(
  appState: CoreInterfaces.AppState
): CoreInterfaces.EngagementMonthlyPartialStats {
  const serviceTotalsPack: CoreInterfaces.EngagementMonthlyPartialStats = {
    totalPricePerMonth: 0,
    totalWorkTime: 0,
  };

  const monthlyRecurringServicesTotalsRow: CoreInterfaces.RecurringServicesTableRow =
    getRecurringServicePanelTableRows(
      appState,
      Constants.ServiceRecurringType.Monthly
    ).find(
      (row) =>
        row.id === Constants.CustomDataGridColumnId.RecurringServicesTotal
    );
  const payrollRecurringServicesTotalsRow: CoreInterfaces.PayrollServicesTableRow =
    getPayrollServicePanelTableRows(appState).find(
      (row) => row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
    );

  if (!!monthlyRecurringServicesTotalsRow) {
    serviceTotalsPack.totalWorkTime +=
      monthlyRecurringServicesTotalsRow.minutesPerPeriodUnderlineValue;
    serviceTotalsPack.totalPricePerMonth +=
      monthlyRecurringServicesTotalsRow.totalPricePerPeriodUnderlineValue;
  }
  if (!!payrollRecurringServicesTotalsRow) {
    serviceTotalsPack.totalWorkTime +=
      payrollRecurringServicesTotalsRow.minutesPerPeriodUnderlineValue;
    serviceTotalsPack.totalPricePerMonth +=
      payrollRecurringServicesTotalsRow.totalPricePerPeriodUnderlineValue;
  }

  return serviceTotalsPack;
}

// this sums the total price/year for the first 5 tables
export function getEngagementTotals(appState: CoreInterfaces.AppState): number {
  let total = 0;

  const monthlyRecurringServicesTotalsRow: CoreInterfaces.RecurringServicesTableRow =
    getRecurringServicePanelTableRows(
      appState,
      Constants.ServiceRecurringType.Monthly
    ).find(
      (row) =>
        row.id === Constants.CustomDataGridColumnId.RecurringServicesTotal
    );
  const quarterlyRecurringServicesTotalsRow: CoreInterfaces.RecurringServicesTableRow =
    getRecurringServicePanelTableRows(
      appState,
      Constants.ServiceRecurringType.Quarterly
    ).find(
      (row) =>
        row.id === Constants.CustomDataGridColumnId.RecurringServicesTotal
    );
  const payrollRecurringServicesTotalsRow: CoreInterfaces.PayrollServicesTableRow =
    getPayrollServicePanelTableRows(appState).find(
      (row) => row.id === Constants.CustomDataGridColumnId.PayrollServicesTotal
    );

  const otherServices = appState.services.filter((eachService) => {
    return (
      [
        Constants.ServiceRecurringType.Yearly,
        Constants.ServiceRecurringType.OneTime,
      ].includes(eachService.data.recurringType) && eachService.state.isSelected
    );
  });

  for (const eachService of otherServices) {
    const serviceStats = getServiceStats(appState, eachService);
    if (
      eachService.data.otherServicesTotalsPanel.isTotalCostOverridenByAdjustment
    ) {
      total += serviceStats.additionalCostsAdjustment;
    } else {
      total += serviceStats.totalPricePerYear;
    }
  }
  if (!!monthlyRecurringServicesTotalsRow) {
    total += monthlyRecurringServicesTotalsRow.totalPricePerYearUnderlineValue;
  }
  if (!!quarterlyRecurringServicesTotalsRow) {
    total +=
      quarterlyRecurringServicesTotalsRow.totalPricePerYearUnderlineValue;
  }
  if (!!payrollRecurringServicesTotalsRow) {
    total += payrollRecurringServicesTotalsRow.totalPricePerYearUnderlineValue;
  }

  return ceil(total);
}

export function getServiceStaffingAllocationExtendedSummaryPanelRows(
  appState: CoreInterfaces.AppState,
  serviceDTO: DTOs.ServiceDTO,
  taskGroups?: Array<Constants.TaskGroup>
): Array<CoreInterfaces.AllocationServiceCalculationSummaryRow> {
  const rowsArr: Array<CoreInterfaces.AllocationServiceCalculationSummaryRow> =
    [];
  if (!serviceDTO.state.isSelected) {
    return rowsArr;
  }

  const serviceProcessor =
    ServiceProcessors.CalculationSummary[serviceDTO.data.code];
  if (!serviceProcessor) {
    return rowsArr;
  }
  const rowSums = [0, 0];

  const allRows = serviceProcessor(
    appState.currentConfiguration?.applicationConfiguration
      ?.rateCartConfiguration,
    serviceDTO,
    taskGroups
  ) as Array<CoreInterfaces.AllocationServiceCalculationSummaryItemRow>;

  const rows: Array<CoreInterfaces.AllocationServiceCalculationSummaryItemRow> =
    allRows.filter(
      (row) =>
        row.type === Constants.SummaryRowType.Allocation ||
        row.type === Constants.SummaryRowType.TotalCost
    );
  rows
    .filter((row) => row.type === Constants.SummaryRowType.Allocation)
    .forEach((row) => (rowSums[0] += row.sumUnderlineValue));

  let pricePerPayslipSum = 0;
  const service7Amount = getServiceVariable(
    serviceDTO,
    Constants.CalculationVariable.Amount
  );
  const licensesCost = getServiceAdditionalCostsTotal(serviceDTO);
  const licensesCostRow: CoreInterfaces.AllocationServiceCalculationSummaryRow =
    {
      id: Constants.CustomDataGridColumnId.AdditionalCostsTotalCost,
      sum: `${licensesCost} ${Constants.Currency.kr}`,
      sumUnderlineValue: licensesCost,
      title: t("General.LicenseAndProgramCosts"),
      tooltip: t("General.LicenseAndProgramCostsTooltip"),
      pricePerHour: null,
      hoursAndMinutes: null,
      averagePricePerCustomerInvoice: null,
      averageTimePerCustomerInvoice: null,
      type: null,
    };
  rowSums[0] += licensesCost;

  let t0774Row: CoreInterfaces.AllocationServiceExtendedSummaryRow = null;
  FileReader;
  const priceListCost = getServicePriceAccordingToPricelist(serviceDTO);
  let priceListRowPricePerPayslip = "";
  if (service7Amount) {
    priceListRowPricePerPayslip = `${ceil(priceListCost / service7Amount)} ${
      Constants.Currency.kr
    }`;
    pricePerPayslipSum += ceil(priceListCost / service7Amount);
  }
  const priceListRow: CoreInterfaces.AllocationServiceExtendedSummaryRow = {
    id: Constants.CustomDataGridColumnId.PriceListCostRow,
    sum: null,
    sumUnderlineValue: null,
    fixedPriceFromPriceList: `${priceListCost} ${Constants.Currency.kr}`,
    pricePerPayslip: priceListRowPricePerPayslip,
    pricePerPayslipUnderlineValue: 0,
    title: t("General.PriceAccordingToPricelist"),
    tooltip: null,
    pricePerHour: null,
    hoursAndMinutes: null,
    averagePricePerCustomerInvoice: null,
    averageTimePerCustomerInvoice: null,
    type: null,
  };
  rowSums[1] += priceListCost;
  if (
    serviceDTO.data.priceList &&
    serviceDTO.data.priceList === Constants.PriceList.PriceList4
  ) {
    const additionalCost0774 = serviceDTO.data.additionalCosts.find(
      (additionalCostDTO) =>
        additionalCostDTO.data.code ===
        Constants.PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts
          .T0774
    );
    if (additionalCost0774 !== null) {
      t0774Row = {
        id: Constants.CustomDataGridColumnId.T0774Row,
        sum: null,
        sumUnderlineValue: null,
        fixedPriceFromPriceList: `${additionalCost0774.data.cost} ${Constants.Currency.kr}`,
        pricePerPayslip: `${additionalCost0774.data.cost} ${Constants.Currency.kr}`,
        pricePerPayslipUnderlineValue: 0,
        title: t("General.StartMonthlySalaryRunPricelist4"),
        tooltip: null,
        pricePerHour: null,
        hoursAndMinutes: null,
        averagePricePerCustomerInvoice: null,
        averageTimePerCustomerInvoice: null,
        type: null,
      };
      rowSums[1] += additionalCost0774.data.cost;
    }
  }

  rows.push(licensesCostRow);
  !!t0774Row && rows.push(t0774Row);
  rows.push(priceListRow);

  const [allocationCost, priceListCost1] = rowSums;

  const sumRow: CoreInterfaces.AllocationServiceExtendedSummaryRow = {
    id: Constants.CustomDataGridColumnId.SumRow,
    sum: `${ceil(allocationCost)} ${Constants.Currency.kr}`,
    sumUnderlineValue: null,
    fixedPriceFromPriceList: `${priceListCost1} ${Constants.Currency.kr}`,
    pricePerPayslip: null,
    pricePerPayslipUnderlineValue: 0,
    title: t("General.Sum"),
    tooltip: null,
    pricePerHour: null,
    hoursAndMinutes: null,
    averagePricePerCustomerInvoice: null,
    averageTimePerCustomerInvoice: null,
    type: null,
  };

  rows.push(sumRow);

  // adjustment of cost
  if (serviceDTO.data.allocationSummaryPanel.hasAdjustmentOfCost) {
    const extendedPriceListPanelCostAdjustment =
      serviceDTO.data.staffingAdjustments.extendedPriceListPanelCost || "";
    let extendedPriceListPanelPayslipCostForAdjustments = 0;
    if (service7Amount) {
      extendedPriceListPanelPayslipCostForAdjustments = ceil(
        (extendedPriceListPanelCostAdjustment || 0) / service7Amount
      );
      pricePerPayslipSum += extendedPriceListPanelPayslipCostForAdjustments;
    }

    const adjustmentsRow: CoreInterfaces.AllocationServiceExtendedSummaryRow = {
      id: Constants.CustomDataGridColumnId.AdjustmentOfCost,
      title: t("General.AdjustmentOfCost"),
      tooltip: null,
      sum: null,
      sumUnderlineValue: null,
      fixedPriceFromPriceList: `${extendedPriceListPanelCostAdjustment}`,
      pricePerPayslip: `${extendedPriceListPanelPayslipCostForAdjustments} ${Constants.Currency.kr}`,
      pricePerPayslipUnderlineValue: 0,
      pricePerHour: null,
      hoursAndMinutes: null,
      averagePricePerCustomerInvoice: null,
      averageTimePerCustomerInvoice: null,
      type: null,
    };
    rows.push(adjustmentsRow);
  }

  // total sum
  if (serviceDTO.data.allocationSummaryPanel.hasAdjustmentOfCost) {
    const totalSumRow: CoreInterfaces.AllocationServiceExtendedSummaryRow = {
      id: Constants.CustomDataGridColumnId.TotalCost,
      title: t("General.Sum"),
      tooltip: null,
      sum: null,
      sumUnderlineValue: null,
      fixedPriceFromPriceList: `${ceil(
        rowSums[1] +
          serviceDTO.data.staffingAdjustments.extendedPriceListPanelCost
      )} ${Constants.Currency.kr}`,
      pricePerPayslip: `${pricePerPayslipSum} ${Constants.Currency.kr}`,
      pricePerPayslipUnderlineValue: pricePerPayslipSum,
      pricePerHour: null,
      hoursAndMinutes: null,
      averagePricePerCustomerInvoice: null,
      averageTimePerCustomerInvoice: null,
      type: null,
    };
    rows.push(totalSumRow);
  }

  return rows;
}

export function updateQuestionInService(
  services: Array<DTOs.ServiceDTO>,
  question: DTOs.QuestionDTO,
  serviceCode: Constants.ServiceCode
) {
  return services.map((service) =>
    service.data.code === serviceCode
      ? {
          ...service,
          data: {
            ...service.data,
            questions: service.data.questions.map((eachQuestion) =>
              eachQuestion.data.code === question.data.code
                ? question
                : eachQuestion
            ),
          },
        }
      : service
  );
}

export function buildNextState(
  state: CoreInterfaces.AppState,
  nextStateQuestion: DTOs.QuestionDTO,
  serviceToUpdate: Constants.ServiceCode
) {
  const updatedServices = updateQuestionInService(
    state.services,
    nextStateQuestion,
    serviceToUpdate
  );
  const nextState = { ...state, services: updatedServices };
  applyUpdatesOnQuestionChange(
    nextState,
    updatedServices.find((service) => service.data.code === serviceToUpdate)
  );
  return nextState;
}

export function handleEngagementMetadataResetWhenConfigurationChange(
  oldState: CoreInterfaces.AppState,
  newState: CoreInterfaces.AppState
): void {
  let shouldResetEngagementLetterMetadata = false;
  const oldService10 = oldState.services.find(
    (s) => s.data.code === Constants.ServiceCode.EngagementCounseling
  );
  const newService10 = newState.services.find(
    (s) => s.data.code === Constants.ServiceCode.EngagementCounseling
  );
  if (oldService10.state.isSelected !== newService10.state.isSelected) {
    shouldResetEngagementLetterMetadata = true;
  } else {
    const oldGeneralService = oldState.services.find(
      (s) => s.data.code === Constants.ServiceCode.GeneralInformation
    );
    const newGeneralService = newState.services.find(
      (s) => s.data.code === Constants.ServiceCode.GeneralInformation
    );
    if (newService10.state.isSelected) {
      const oldQuestion40 = findQuestionInService(
        oldGeneralService,
        Constants.GeneralInformationQuestion.Q0040
      );
      const newQuestion40 = findQuestionInService(
        newGeneralService,
        Constants.GeneralInformationQuestion.Q0040
      );
      if (oldQuestion40.data.userValue !== newQuestion40.data.userValue) {
        shouldResetEngagementLetterMetadata = true;
      }
    } else {
      const oldQuestion22 = findQuestionInService(
        oldGeneralService,
        Constants.GeneralInformationQuestion.Q0022
      );
      const newQuestion22 = findQuestionInService(
        newGeneralService,
        Constants.GeneralInformationQuestion.Q0022
      );
      if (
        oldQuestion22.state.isShown !== newQuestion22.state.isShown ||
        oldQuestion22.data.userValue !== newQuestion22.data.userValue
      ) {
        shouldResetEngagementLetterMetadata = true;
      }
    }
  }

  if (shouldResetEngagementLetterMetadata) {
    newState.currentConfiguration.engagementLetterMetadata = null;
  }
}

export function applyChangesTimestampOnNextState(
  nextState: CoreInterfaces.AppState
): void {
  const currentDate = new Date();
  nextState.areUnsavedChanges = true;
  nextState.unsavedChangesTimestamp = currentDate.getTime();
  if (nextState.currentConfiguration) {
    nextState.currentConfiguration.lastUserChangesDate = currentDate;
  }
}
