import { CoreInterfaces, DTOs } from "./../core/Models";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import * as Constants from "./../core/Constants";
import expressions from "angular-expressions";
import {
  contactPersonMapper,
  contactPersonWithEmailMapper,
  findQuestionInService,
  mapUserResponseToCustomerContactPersons,
  formatOrganisationNo,
} from "./shared";
import {
  getFrequencyMultiplicator,
  getPayrollSystem,
  mapServiceCodesToServicesDTOs,
} from "./service-utils";
import { createEngagementTableRows } from "./engagement-utils";
import i18n from "src/i18n";
import { fetchPdfFile } from "./api";
import { formatDate } from "./utils";
import { mapQuestionCodesToQuestionDTOs } from "./question-utils";
import { t } from "i18next";
import { isServiceTaskGroupSelected } from "./task-utils";
import { resolveDigitalSignerNameForEngagementLetter } from "./generate-documents";
import { v4 as uuid } from "uuid";

function angularParser(tag: any) {
  if (tag === ".") {
    return {
      get: (s: any) => s,
    };
  }
  const expr = expressions.compile(tag.replace(/(’|“|”)/g, "'"));
  return {
    get: (s: any) => expr(s),
  };
}

function loadFile(url: string, callback: (err: Error, data: string) => void) {
  PizZipUtils.getBinaryContent(url, callback);
}

function replaceErrors(key: string, value: any) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (
      error: any,
      key: string
    ) {
      error[key] = (value as any)[key];
      return error;
    },
    {});
  }
  return value;
}

export async function generateEngagementDescriptionDocx(
  globalState: CoreInterfaces.AppState,
  t: Function,
  language: string
) {
  return generateEngagementDescriptionWordDocument(globalState, t, language);
}

export async function generateEngagementDescriptionWordDocument(
  globalState: CoreInterfaces.AppState,
  t: Function,
  language: string
): Promise<any> {
  const initialLanguage = i18n.language;
  await i18n.changeLanguage(language.toLowerCase());
  return new Promise((resolve, reject) => {
    loadFile(
      `/resources/template_${language}.docx`,
      (error: Error, content: string) => {
        if (error) {
          i18n.changeLanguage(initialLanguage);
          return reject(error);
        } else {
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            parser: angularParser,
          });
          const templateData = formDocxTemplaterPack(globalState, t);
          doc.setData(templateData);
          try {
            doc.render();
            i18n.changeLanguage(initialLanguage);
            return resolve(doc);
          } catch (error: any) {
            i18n.changeLanguage(initialLanguage);
            return reject(error);
          }
        }
      }
    );
  });
}

export async function generatePdfDocument(
  globalState: CoreInterfaces.AppState,
  dispatchFn: Function,
  t: Function,
  language: string,
  updateApplicationLoadingStateFn: (
    loadingState: boolean,
    loadingMessage: string
  ) => void
): Promise<void> {
  return generateWordDocument(
    globalState,
    dispatchFn,
    t,
    language,
    t("General.GeneratingDocument"),
    (doc, filename) => {
      const formdata = new FormData();
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      formdata.append("file", out);
      return fetchPdfFile(
        Constants.APIPath.ConvertToPdf,
        Constants.APIMethod.POST,
        formdata
      ).then((response) => {
        saveAs(response, filename);
      });
    },
    updateApplicationLoadingStateFn
  );
}

export async function generateWordDocument(
  globalState: CoreInterfaces.AppState,
  dispatchFn: Function,
  t: Function,
  language: string,
  loadingMessage: string,
  callbackFn: (doc: any, filename: string) => Promise<any>,
  updateApplicationLoadingStateFn: (
    loadingState: boolean,
    loadingMessage: string
  ) => void
): Promise<void> {
  updateApplicationLoadingStateFn(true, loadingMessage);
  dispatchFn({
    type: Constants.AppStateActions.LoadingBlurredStateUpdate,
    payload: {
      isPageBlurred: true,
    },
  });
  const initialLanguage = i18n.language;
  await i18n.changeLanguage(language.toLowerCase());
  loadFile(
    `/resources/template_${language}.docx`,
    (error: Error, content: string) => {
      if (error) {
        i18n.changeLanguage(initialLanguage);
        updateApplicationLoadingStateFn(false, null);
        throw error;
      }
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        parser: angularParser,
      });
      const templateData = formDocxTemplaterPack(globalState, t);
      doc.setData(templateData);
      try {
        doc.render();
      } catch (error: any) {
        i18n.changeLanguage(initialLanguage);
        console.log(JSON.stringify({ error: error }, replaceErrors));

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error: any) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
        }
        throw error;
      }
      return callbackFn(
        doc,
        t("General.Filename", {
          companyName: globalState.generalVariables.companyName,
          orgNr: globalState.generalVariables.organisationNo,
        })
      )
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          i18n.changeLanguage(initialLanguage);
          updateApplicationLoadingStateFn(false, null);
          dispatchFn({
            type: Constants.AppStateActions.LoadingBlurredStateUpdate,
            payload: {
              isPageBlurred: false,
            },
          });
        });
    }
  );
}

export function generateEngagementDocument(
  documentType: Constants.EngagementDocumentType,
  templateData: object,
  language: string
): Promise<any> {
  const fileName = `template_${documentType}_${language}`;
  const generateFilePromise = new Promise((resolve, reject) => {
    loadFile(`/resources/${fileName}.docx`, (error: Error, content: string) => {
      if (error) {
        reject("Cannot read template file");
      } else {
        try {
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            parser: angularParser,
          });
          doc.setData(templateData);
          doc.render();
          resolve(doc);
        } catch (error: any) {
          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error: any) {
                return error.properties.explanation;
              })
              .join("\n");
            reject(errorMessages);
          } else {
            reject(error);
          }
        }
      }
    });
  });
  return generateFilePromise;
}

function getContactPerson(
  contactPersonList: Array<CoreInterfaces.ContactPersonItem>,
  service: DTOs.ServiceDTO,
  questionCode: Constants.QuestionCode
): string {
  const contactPersonQuestion = findQuestionInService(service, questionCode);
  const contactPerson = contactPersonList.find(
    (item) => item.key === contactPersonQuestion.data.userValue
  );
  if (!!contactPerson) {
    return `${contactPerson.firstName} ${contactPerson.lastName}`;
  }
  return "";
}

function getQuestionValueAsNumber(
  service: DTOs.ServiceDTO,
  questionCode: Constants.QuestionCode
): number {
  const question = findQuestionInService(service, questionCode);
  const value = question?.state?.isShown ? +question.data.userValue : 0;

  return value;
}

function formDocxTemplaterPack(
  globalState: CoreInterfaces.AppState,
  t: Function
): CoreInterfaces.DocxTemplaterPack {
  const servicesMap = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.AccountsReceivable,
    Constants.ServiceCode.AccountsPayable,
    Constants.ServiceCode.CorporateCardManagement,
    Constants.ServiceCode.OtherAccountAndReconciliation,
    Constants.ServiceCode.PeriodReporting,
    Constants.ServiceCode.InterimListedCompanies,
    Constants.ServiceCode.AnnualReporting,
    Constants.ServiceCode.AnnualReportingListedCompanies,
    Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
    Constants.ServiceCode.AnnualPayrollRoutines,
    Constants.ServiceCode.StartUpAccounting,
    Constants.ServiceCode.StartUpPayroll,
  ]);
  const accountReceivableService = servicesMap.AccountsReceivable;
  const accountPayableService = servicesMap.AccountsPayable;
  const corporateCardManagementService = servicesMap.CorporateCardManagement;
  const otherAccountingAndReconciliationService =
    servicesMap.OtherAccountAndReconciliation;
  const periodReportingService = servicesMap.PeriodReporting;
  const interimReportingListedCompaniesService =
    servicesMap.InterimListedCompanies;
  const annualAccoutingService = servicesMap.AnnualReporting;
  const annualReportingListedCompaniesService =
    servicesMap.AnnualReportingListedCompanies;
  const payrollAndExpenseAndTravelInvoiceManagementService =
    servicesMap.PayrollAndExpenseAndTravelInvoiceManagement;
  const annualPayrollRoutines = servicesMap.AnnualPayrollRoutines;

  const result: CoreInterfaces.DocxTemplaterPack = {
    BusinessArea: t(
      `Options.BusinessArea.EngagementDescriptionDocument.${globalState.currentConfiguration.businessUnit}`
    ),
    CompanyName: globalState.generalVariables.companyName,
    OrganisationNumber: formatOrganisationNo(
      globalState.generalVariables.organisationNo
    ),
    AccountingSystem: globalState.generalVariables.accountingSystem,
    PayrollSystem: getPayrollSystem(globalState) ?? "",
    ValidFromDate: formatDate(
      new Date(globalState.currentConfiguration.validFromDate),
      Constants.HelpfulConstants.DateFormatYearMonthAndDay
    ),
    ValidUntilDate: globalState.currentConfiguration.validUntilDate
      ? formatDate(
          new Date(globalState.currentConfiguration.validUntilDate),
          Constants.HelpfulConstants.DateFormatYearMonthAndDay
        )
      : null,
    CurrentYear: new Date().getFullYear(),
    AccountReceivable: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        accountReceivableService,
        Constants.AccountReceivableQuestion.Q0120
      ),
      NumberOfUnits: getQuestionValueAsNumber(
        accountReceivableService,
        Constants.AccountReceivableQuestion.Q0101
      ),
      IsVisible: accountReceivableService.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(
        globalState,
        accountReceivableService,
        t
      ),
    },
    AccountPayable: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        accountPayableService,
        Constants.AccountPayableQuestion.Q0221
      ),
      NumberOfUnits: getQuestionValueAsNumber(
        accountPayableService,
        Constants.AccountPayableQuestion.Q0201
      ),
      IsVisible: accountPayableService.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(globalState, accountPayableService, t),
    },
    CorporateCardManagement: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        corporateCardManagementService,
        Constants.CorporateCardManagementQuestion.Q0311
      ),
      Tasks: formDocxTemplaterTasksPack(
        globalState,
        corporateCardManagementService,
        t
      ),
      NumberOfCorporateCards: getQuestionValueAsNumber(
        corporateCardManagementService,
        Constants.CorporateCardManagementQuestion.Q0301
      ),
      NumberOfReceipts: getQuestionValueAsNumber(
        corporateCardManagementService,
        Constants.CorporateCardManagementQuestion.Q0302
      ),
      IsVisible: corporateCardManagementService.state.isSelected,
    },
    OtherAccountingAndReconciliation: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        otherAccountingAndReconciliationService,
        Constants.OtherAccountAndReconciliationQuestions.Q0407
      ),
      NumberOfVouchers: getQuestionValueAsNumber(
        otherAccountingAndReconciliationService,
        Constants.OtherAccountAndReconciliationQuestions.Q0404
      ),
      NumberOfBalanceAccounts: getQuestionValueAsNumber(
        otherAccountingAndReconciliationService,
        Constants.OtherAccountAndReconciliationQuestions.Q0405
      ),
      IsVisible: otherAccountingAndReconciliationService.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(
        globalState,
        otherAccountingAndReconciliationService,
        t
      ),
    },
    MonthlyQuartelyReporting: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        periodReportingService,
        Constants.PeriodReportingQuestion.Q0513
      ),
      IsVisible: periodReportingService.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(globalState, periodReportingService, t),
    },
    InterimReportingListedCompanies: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        periodReportingService,
        Constants.PeriodReportingQuestion.Q0513
      ),
      IsVisible: periodReportingService.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(
        globalState,
        interimReportingListedCompaniesService,
        t
      ),
    },
    AnnualAccouting: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        annualAccoutingService,
        Constants.AnnualReportingQuestions.Q0608
      ),
      NumberOfTransactions: getQuestionValueAsNumber(
        annualAccoutingService,
        Constants.AnnualReportingQuestions.Q0602
      ),
      NumberOfBalanceAccounts: getFrequencyMultiplicator(
        annualAccoutingService.data.tasks.find(
          (taskDTO) =>
            taskDTO.data.code === Constants.AnnualReportingTasks.T0612
        ),
        annualAccoutingService
      ),
      IsVisible: annualAccoutingService.state.isSelected,
      AnnualReportTasks: formDocxTemplaterTasksPack(
        globalState,
        annualAccoutingService,
        t,
        Constants.TaskGroup.AnnualReport
      ),
      AnnualReportV2Tasks: formDocxTemplaterTasksPack(
        globalState,
        annualAccoutingService,
        t,
        Constants.TaskGroup.AnnualReportV2
      ),
      AnnualReportV3Tasks: formDocxTemplaterTasksPack(
        globalState,
        annualAccoutingService,
        t,
        Constants.TaskGroup.AnnualReportV3
      ),
      CorporateIncomeTaxTasks: formDocxTemplaterTasksPack(
        globalState,
        annualAccoutingService,
        t,
        Constants.TaskGroup.CorporateIncomeTax
      ),
      StatutoryAnnualReportTasks: formDocxTemplaterTasksPack(
        globalState,
        annualAccoutingService,
        t,
        Constants.TaskGroup.StatutoryAnnualReport
      ),
    },
    AnnualAccoutsFiscalYearClosingListedCompanies: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        annualAccoutingService,
        Constants.AnnualReportingQuestions.Q0608
      ),
      IsVisible: annualAccoutingService.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(
        globalState,
        annualReportingListedCompaniesService,
        t
      ),
    },
    Payroll: {
      ContactPerson: getContactPerson(
        globalState.remoteData.customerContactPersons,
        payrollAndExpenseAndTravelInvoiceManagementService,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0763
      ),
      IsVisible:
        payrollAndExpenseAndTravelInvoiceManagementService.state.isSelected,
      NumberOfPayslips: getQuestionValueAsNumber(
        payrollAndExpenseAndTravelInvoiceManagementService,
        Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0701
      ),
      AdditionalPayrollServicesTasks: formDocxTemplaterTasksPack(
        globalState,
        payrollAndExpenseAndTravelInvoiceManagementService,
        t,
        Constants.TaskGroup.AdditionalPayrollServices
      ),
      MonthlyPayrollRunTasks: formDocxTemplaterTasksPack(
        globalState,
        payrollAndExpenseAndTravelInvoiceManagementService,
        t,
        Constants.TaskGroup.MonthlyPayrollRun
      ),
      MonthlyPayrollReconciliationTasks: formDocxTemplaterTasksPack(
        globalState,
        payrollAndExpenseAndTravelInvoiceManagementService,
        t,
        Constants.TaskGroup.MonthlyPayrollReconciliation
      ),
      PayrollServicesInAdditionTasks: formDocxTemplaterTasksPack(
        globalState,
        payrollAndExpenseAndTravelInvoiceManagementService,
        t,
        Constants.TaskGroup.PayrollServicesInAddition
      ),
      MonthlyReportingPayrollTasks: formDocxTemplaterTasksPack(
        globalState,
        payrollAndExpenseAndTravelInvoiceManagementService,
        t,
        Constants.TaskGroup.MonthlyReportingPayroll
      ),
    },
    AnnualPayrollRoutines: {
      ContactPerson: null,
      IsVisible: annualPayrollRoutines.state.isSelected,
      Tasks: formDocxTemplaterTasksPack(globalState, annualPayrollRoutines, t),
    },
  };

  return result;
}

function formDocxTemplaterTasksPack(
  globalState: CoreInterfaces.AppState,
  service: DTOs.ServiceDTO,
  t: Function,
  taskGroup: Constants.TaskGroup = Constants.TaskGroup.DefaultGroup
): Array<CoreInterfaces.DocxTemplaterTaskPack> {
  const extraData: CoreInterfaces.ServiceTaskProcessorExtraData = {
    generalVariables: globalState.generalVariables,
    extraServices: globalState.services,
  };
  const rows = createEngagementTableRows(service, extraData);
  return rows
    .filter(
      (row) =>
        row.taskDTO.data.serviceTaskGroup === taskGroup &&
        row.taskDTO.state.isActive
    )
    .map((row) => {
      return <CoreInterfaces.DocxTemplaterTaskPack>{
        Description: row.title,
        Frequency: t(`Options.${row.frequency}`),
        AllocationOfResponsability: t(
          `Options.${row.allocationOfResponsibility}`
        ),
        TimeDue: row.taskDTO.data.timeDue,
        Comment: row.comment,
      };
    });
}

function extractContactPersonUserDetails(
  globalState: CoreInterfaces.AppState,
  question: DTOs.QuestionDTO
): Array<CoreInterfaces.SigneeDetailsDocxPack> {
  if (question.state.isShown) {
    if (
      Array.isArray(question.data.userValue) &&
      question.data.userValue.length > 0
    ) {
      return (
        question.data.userValue as CoreInterfaces.ContactPersonUserDetails[]
      ).map((userDetails: CoreInterfaces.ContactPersonUserDetails) => {
        const contactPerson =
          globalState.remoteData.customerContactPersons.find(
            (contactPerson) => contactPerson.key === userDetails.ContactPersonId
          );
        return {
          Name: `${contactPerson.firstName} ${contactPerson.lastName}`,
          PersonalNumber: userDetails.PersonalNumber,
          IsSigned: userDetails.IsSigned,
          Uuid: userDetails.Uuid,
        };
      });
    }
  }
  return [
    {
      Name: "",
      PersonalNumber: "",
      IsSigned: false,
      Uuid: uuid(),
    },
  ];
}

export function distributeSigneesAcrossTwoArrays(
  contactPersonSignees: Array<string>
) {
  const array1: Array<string> = [];
  const array2: Array<string> = [];
  contactPersonSignees.map((value, index) => {
    if (index % 2 === 0) {
      array1.push(value);
    } else {
      array2.push(value);
    }
  });
  return [array1, array2];
}

export function handleTemplateEngagementLetter(
  globalState: CoreInterfaces.AppState,
  genericService: DTOs.ServiceDTO,
  language: string
) {
  const {
    Q0003,
    Q0014,
    Q0017,
    Q0018,
    Q0023,
    Q0024,
    Q0025,
    Q0029,
    Q0030,
    Q0031,
    Q0032,
    Q0033,
    Q0034,
    Q0035,
    Q0036,
    Q0037,
  } = Constants.GeneralInformationQuestion;
  const questionDTOMap = mapQuestionCodesToQuestionDTOs(genericService, [
    Q0003,
    Q0014,
    Q0017,
    Q0018,
    Q0023,
    Q0024,
    Q0025,
    Q0029,
    Q0030,
    Q0031,
    Q0032,
    Q0033,
    Q0034,
    Q0035,
    Q0036,
    Q0037,
  ]);
  const servicesMap = mapServiceCodesToServicesDTOs(globalState, [
    Constants.ServiceCode.GeneralInformation,
    Constants.ServiceCode.AccountsReceivable,
    Constants.ServiceCode.AccountsPayable,
    Constants.ServiceCode.CorporateCardManagement,
    Constants.ServiceCode.OtherAccountAndReconciliation,
    Constants.ServiceCode.PeriodReporting,
    Constants.ServiceCode.InterimListedCompanies,
    Constants.ServiceCode.AnnualReporting,
    Constants.ServiceCode.AnnualReportingListedCompanies,
    Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement,
    Constants.ServiceCode.AnnualPayrollRoutines,
    Constants.ServiceCode.StartUpAccounting,
    Constants.ServiceCode.StartUpPayroll,
  ]);
  const periodReportingService = servicesMap.PeriodReporting;
  const annualReportingService = servicesMap.AnnualReporting;
  const payrollAndExpenseAndTravelInvoiceManagementService =
    servicesMap.PayrollAndExpenseAndTravelInvoiceManagement;

  const gTPreparesServices =
    periodReportingService.state.isSelected &&
    periodReportingService.data.questions.some(
      (question) =>
        [
          Constants.PeriodReportingQuestion.Q0504,
          Constants.PeriodReportingQuestion.Q0506,
          Constants.PeriodReportingQuestion.Q0507,
          Constants.PeriodReportingQuestion.Q0508,
        ].includes(question.data.code as Constants.PeriodReportingQuestion) &&
        question.state.isShown &&
        question.data.userValue === Constants.YesNo.Yes
    );

  const separator = t("General.DocumentsSeparator");

  const contactPersonUserDetails = extractContactPersonUserDetails(
    globalState,
    questionDTOMap.Q0034
  );

  const allContactPersonsSigned = contactPersonUserDetails.every(
    (contactPerson) => contactPerson.IsSigned === true
  );
  const contactPersonUserDetailsSigned = contactPersonUserDetails.filter(
    (cp) => cp.IsSigned === true
  );
  const contactPersonUserDetailsUnsigned = contactPersonUserDetails.filter(
    (cp) => cp.IsSigned === false
  );

  const contactPersonUserDetailsUnsignedParsed = parseContactPersonList(
    contactPersonUserDetailsUnsigned,
    separator
  );
  const contactPersonUserDetailsSignedParsed = parseContactPersonList(
    contactPersonUserDetailsSigned,
    separator
  );

  const q0504 = findQuestionInService(
    periodReportingService,
    Constants.PeriodReportingQuestion.Q0504
  );
  const q0601 = findQuestionInService(
    annualReportingService,
    Constants.AnnualReportingQuestions.Q0601
  );
  const q0610 = findQuestionInService(
    annualReportingService,
    Constants.AnnualReportingQuestions.Q0610
  );
  const isCheckboxSelectedAnnualAccounts = isServiceTaskGroupSelected(
    q0610,
    Constants.PartOfAnnualAccounts.AnnualAccounts
  );
  const isCheckboxSelectedAnnualReport = isServiceTaskGroupSelected(
    q0610,
    Constants.PartOfAnnualAccounts.AnnualReport
  );
  const isCheckboxSelectedCompanyDeclaration = isServiceTaskGroupSelected(
    q0610,
    Constants.PartOfAnnualAccounts.CompanyDeclaration
  );
  const q0733 = findQuestionInService(
    payrollAndExpenseAndTravelInvoiceManagementService,
    Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions.Q0733
  );

  const isOneOfTheFirstServicesSelected =
    servicesMap.AccountsPayable.state.isSelected ||
    servicesMap.AccountsReceivable.state.isSelected ||
    servicesMap.CorporateCardManagement.state.isSelected ||
    servicesMap.OtherAccountAndReconciliation.state.isSelected;
  const isService4Or5Selected =
    servicesMap.OtherAccountAndReconciliation.state.isSelected ||
    servicesMap.PeriodReporting.state.isSelected;
  const isService5bSelected =
    servicesMap.InterimListedCompanies.state.isSelected;
  const isService6Or6BSelected =
    servicesMap.AnnualReporting.state.isSelected ||
    servicesMap.AnnualReportingListedCompanies.state.isSelected;
  const isService7Selected =
    payrollAndExpenseAndTravelInvoiceManagementService.state.isSelected;
  const isService8Selected = servicesMap.StartUpAccounting.state.isSelected;
  const isService9Selected = servicesMap.StartUpPayroll.state.isSelected;
  const isOneOfTheFirstFiveServicesSelected =
    isOneOfTheFirstServicesSelected ||
    isService4Or5Selected ||
    isService5bSelected;
  const isAnyServiceSelected =
    isOneOfTheFirstFiveServicesSelected ||
    isService6Or6BSelected ||
    isService8Selected ||
    isService7Selected ||
    isService9Selected;

  const isAccountingServiceSelected =
    isOneOfTheFirstFiveServicesSelected ||
    isService6Or6BSelected ||
    isService8Selected;
  let amountInEngagementLetter = "";
  if (questionDTOMap.Q0024.state.isShown) {
    amountInEngagementLetter = questionDTOMap.Q0024.data.userValue as string;
  } else {
    if (questionDTOMap.Q0025.state.isShown) {
      const [firstInput, secondInput] = questionDTOMap.Q0025.data
        .userValue as unknown as string[];
      amountInEngagementLetter = `${firstInput}-${secondInput}`;
    }
  }
  const isFeeTable =
    questionDTOMap.Q0023.state.isShown &&
    questionDTOMap.Q0023.data.userValue === Constants.FeeExpression.Table;
  const isActiveOptionForDigitalSigning =
    questionDTOMap.Q0017.state.isShown &&
    questionDTOMap.Q0017.data.userValue === Constants.YesNo.Yes;
  const isInactiveOptionForDigitalSigning =
    questionDTOMap.Q0017.state.isShown &&
    questionDTOMap.Q0017.data.userValue === Constants.YesNo.No;
  const validUntilDate = globalState.currentConfiguration.validUntilDate;
  const validUntil = validUntilDate
    ? formatDate(
        validUntilDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      )
    : "";
  let contactPersonInEngagementLetter: Array<string> = null;
  if (questionDTOMap.Q0036.state.isShown) {
    const q0036ContactPersons = mapUserResponseToCustomerContactPersons(
      globalState,
      questionDTOMap[Q0036]
    );
    contactPersonInEngagementLetter = q0036ContactPersons.map(
      (contactPersonItem: CoreInterfaces.ContactPersonItem) =>
        `${contactPersonItem.firstName} ${contactPersonItem.lastName}, ${contactPersonItem.email}`
    );
  }
  const contactPersonGT = globalState.remoteData.gtContactPersons.find(
    (eachCustomerContactPerson) => {
      return (
        eachCustomerContactPerson.key ==
        questionDTOMap[Q0003].data.userValue.toString()
      );
    }
  );
  let servicesPerformedForEmployees = null;
  if (questionDTOMap.Q0035.state.isShown) {
    servicesPerformedForEmployees = t(
      `Options.${questionDTOMap.Q0035.data.userValue}`
    );
  }
  let contactPersonGtName = "";
  let contactPersonGtNameWithEmail = "";
  if (!!contactPersonGT) {
    contactPersonGtName = `${contactPersonGT.firstName} ${contactPersonGT.lastName}`;
    contactPersonGtNameWithEmail = `${contactPersonGT.firstName} ${contactPersonGT.lastName}, ${contactPersonGT.email}`;
  }

  let q0018ContactPersonNames: Array<string> = [];

  let engagementClientSigneesLeftColumn: Array<string> = [];
  let engagementClientSigneesRightColumn: Array<string> = [];

  if (questionDTOMap.Q0018.state.isShown) {
    const q0018ContactPersons = mapUserResponseToCustomerContactPersons(
      globalState,
      questionDTOMap[Q0018]
    );
    q0018ContactPersonNames = q0018ContactPersons.map(
      (contactPersonItem: CoreInterfaces.ContactPersonItem) =>
        `${contactPersonItem.firstName} ${contactPersonItem.lastName}`
    );
    [engagementClientSigneesLeftColumn, engagementClientSigneesRightColumn] =
      distributeSigneesAcrossTwoArrays(q0018ContactPersonNames);
  }
  const digitallySigningUserName =
    resolveDigitalSignerNameForEngagementLetter(globalState);

  const templateDataEngagementLetter: CoreInterfaces.TemplateDataEngagementLetter =
    {
      accountancyTable: isFeeTable && isAccountingServiceSelected,
      address: globalState.generalVariables.address,
      allContactPersonsSigned: allContactPersonsSigned,
      amountInEngagementLetter: amountInEngagementLetter,
      area: globalState.generalVariables.area,
      isOneOfTheFirstFiveServicesSelected: isOneOfTheFirstFiveServicesSelected,
      assistanceFromGT:
        gTPreparesServices ||
        (isService7Selected &&
          q0733.state.isShown &&
          q0733.data.userValue === Constants.YesNo.Yes),
      companyName: globalState.currentConfiguration.companyName,
      contactPersonGTForContractor: contactPersonGtNameWithEmail,
      contactPersonGT: contactPersonGtName,
      contactPersonInEngagementLetter: parseContactPersonList(
        contactPersonInEngagementLetter,
        separator
      ),
      contactPersonUserDetails: contactPersonUserDetails,
      contactPersonUserDetailsSigned: contactPersonUserDetailsSigned,
      contactPersonUserDetailsUnsigned: contactPersonUserDetailsUnsigned,
      contactPersonUserDetailsSignedParsed:
        contactPersonUserDetailsSignedParsed,
      contactPersonUserDetailsUnsignedParsed:
        contactPersonUserDetailsUnsignedParsed,
      currentYear: new Date().getFullYear(),
      digitallySignedBy: digitallySigningUserName,
      feePeriodBeloppOrInterval:
        (questionDTOMap.Q0023.state.isShown &&
          questionDTOMap.Q0023.data.userValue ===
            Constants.FeeExpression.Interval) ||
        questionDTOMap.Q0023.data.userValue === Constants.FeeExpression.Amount,
      gTPreparesEcList:
        q0504.state.isShown && q0504.data.userValue === Constants.YesNo.Yes,
      gTPreparesTaxes:
        isService7Selected &&
        q0733.state.isShown &&
        q0733.data.userValue === Constants.YesNo.Yes,
      isActiveOptionForDigitalSigning: isActiveOptionForDigitalSigning,
      isAnnualReportsSelected: isCheckboxSelectedAnnualReport,
      isAnnualAccountsSelected: isCheckboxSelectedAnnualAccounts,
      isAnnualAccountAndReportSelected:
        isCheckboxSelectedAnnualAccounts && isCheckboxSelectedAnnualReport,
      isAnyServiceSelected: isAnyServiceSelected,
      isCompanyDeclarationSelected: isCheckboxSelectedCompanyDeclaration,
      isCompanyDeclarationSelectedOrShouldGTDoIncomeTax:
        isCheckboxSelectedCompanyDeclaration ||
        (questionDTOMap.Q0033.state.isShown &&
          questionDTOMap.Q0033.data.userValue === Constants.YesNo.Yes),
      isFeeOnCurrentAccount:
        questionDTOMap.Q0023.state.isShown &&
        questionDTOMap.Q0023.data.userValue ===
          Constants.FeeExpression.OnCurrentAccount,
      isFeeTable: isFeeTable,
      isInactiveOptionForDigitalSigning: isInactiveOptionForDigitalSigning,
      isOnlyAnnualAccountsSelected:
        isCheckboxSelectedAnnualAccounts &&
        !isCheckboxSelectedAnnualReport &&
        !isCheckboxSelectedCompanyDeclaration,
      isOnlyAnnualReportsSelected:
        isCheckboxSelectedAnnualReport &&
        !isCheckboxSelectedAnnualAccounts &&
        !isCheckboxSelectedCompanyDeclaration,
      isSelectedService4Or5: isService4Or5Selected,
      isService6Or6BSelected: isService6Or6BSelected,
      isService7Selected: isService7Selected,
      isService8Selected: isService8Selected,
      isService8Or9Selected: isService8Selected || isService9Selected,
      isService9Selected: isService9Selected,
      isOneOfFirst4ServicesSelectedOrService6:
        isOneOfTheFirstServicesSelected ||
        (q0601.state.isShown && q0601.data.userValue === Constants.YesNo.Yes),
      isUsedAnotherAccountingSystem:
        questionDTOMap.Q0014.state.isShown &&
        questionDTOMap.Q0014.data.userValue === Constants.YesNo.No,
      numberOfMonths:
        questionDTOMap.Q0032.state.isShown &&
        t(
          `Options.${questionDTOMap.Q0032.data.userValue as unknown as string}`
        ),
      notAllContactPersonsSigned: !allContactPersonsSigned,
      notOnlyService7Selected:
        isOneOfTheFirstFiveServicesSelected ||
        isService6Or6BSelected ||
        isService8Selected ||
        isService9Selected,
      onlyService7Selected:
        !(
          isOneOfTheFirstFiveServicesSelected ||
          isService6Or6BSelected ||
          isService8Selected ||
          isService9Selected
        ) && isService7Selected,
      organisationNo: formatOrganisationNo(
        globalState.currentConfiguration.organisationNumber
      ),
      postalNumber: globalState.generalVariables.postalNumber,
      payrollTable: isFeeTable && (isService7Selected || isService9Selected),
      servicesPerformedForEmployees: servicesPerformedForEmployees,
      shouldGTDoIncomeTax:
        questionDTOMap.Q0033.state.isShown &&
        questionDTOMap.Q0033.data.userValue === Constants.YesNo.Yes,
      shouldNotGTDoIncomeTax:
        questionDTOMap.Q0033.state.isShown &&
        questionDTOMap.Q0033.data.userValue === Constants.YesNo.No,
      shouldOwnerAnalysisBeUsed:
        questionDTOMap.Q0037.state.isShown &&
        questionDTOMap.Q0037.data.userValue === Constants.YesNo.Yes,
      validityCurrent:
        questionDTOMap.Q0029.state.isShown &&
        questionDTOMap.Q0029.data.userValue === Constants.FeeExpression.Current,
      validityPeriod:
        questionDTOMap.Q0029.state.isShown &&
        questionDTOMap.Q0029.data.userValue === Constants.FeeExpression.Period,
      validFrom: formatDate(
        globalState.currentConfiguration.validFromDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      ),
      validUntil: validUntil,
      engagementClientSigneesLeftColumn: engagementClientSigneesLeftColumn,
      engagementClientSigneesRightColumn: engagementClientSigneesRightColumn,
    };

  return templateDataEngagementLetter;
}

export function handleTemplateEngagementCounselingLetter(
  globalState: CoreInterfaces.AppState,
  genericService: DTOs.ServiceDTO,
  language: string
) {
  const {
    Q0003,
    Q0014,
    Q0017,
    Q0018,
    Q0031,
    Q0038,
    Q0039,
    Q0040,
    Q0041,
    Q0042,
  } = Constants.GeneralInformationQuestion;
  const questionDTOMap = mapQuestionCodesToQuestionDTOs(genericService, [
    Q0003,
    Q0014,
    Q0017,
    Q0018,
    Q0031,
    Q0038,
    Q0039,
    Q0040,
    Q0041,
    Q0042,
  ]);

  let contactPersonInEngagementLetter: Array<string> = [];
  let q0018ContactPersonNames: Array<string> = [];
  let contactPersonGTForContractor = "";
  let contactPersonGT = "";

  const contactPersonGTForContractorValue =
    globalState.remoteData.gtContactPersons.find(
      (eachCustomerContactPerson) => {
        return (
          eachCustomerContactPerson.key ==
          questionDTOMap[Q0003].data.userValue.toString()
        );
      }
    );
  if (contactPersonGTForContractorValue) {
    contactPersonGTForContractor = [contactPersonGTForContractorValue].map(
      contactPersonWithEmailMapper
    )[0];
    contactPersonGT = [contactPersonGTForContractorValue].map(
      contactPersonMapper
    )[0];
  }

  if (questionDTOMap.Q0018.state.isShown) {
    const q0018ContactPersons = mapUserResponseToCustomerContactPersons(
      globalState,
      questionDTOMap[Q0018]
    );
    q0018ContactPersonNames = q0018ContactPersons.map(contactPersonMapper);
    if (q0018ContactPersons.length > 0) {
      contactPersonInEngagementLetter = q0018ContactPersons.map(
        contactPersonWithEmailMapper
      );
    }
  }
  const digitallySigningUserName =
    resolveDigitalSignerNameForEngagementLetter(globalState);

  const separator = t("General.DocumentsSeparator");

  const templateDataEngagementLetter: CoreInterfaces.TemplateDataEngagementCounselingLetter =
    {
      address: globalState.generalVariables.address,
      area: globalState.generalVariables.area,
      companyName: globalState.generalVariables.companyName,
      contactPersonGT: contactPersonGT,
      contactPersonInEngagementLetter: parseContactPersonList(
        contactPersonInEngagementLetter,
        separator
      ),
      contactPersonGTForContractor: contactPersonGTForContractor,
      currentYear: new Date().getFullYear(),
      engagementClientSignees: questionDTOMap.Q0018.state.isShown
        ? q0018ContactPersonNames.slice(0, 2)
        : [],
      isActiveOptionForDigitalSigning:
        questionDTOMap.Q0017.state.isShown &&
        questionDTOMap.Q0017.data.userValue === Constants.YesNo.Yes,
      isAnalysisOfAdministrativeProcessesEngagementCounseling:
        questionDTOMap.Q0038.state.isShown &&
        questionDTOMap.Q0038.data.userValue ===
          Constants.CounselingAssignment.Analysis,
      digitallySignedBy: digitallySigningUserName,
      isEconomicsHandbookEngagementCounseling:
        questionDTOMap.Q0038.state.isShown &&
        questionDTOMap.Q0038.data.userValue ===
          Constants.CounselingAssignment.Economics,
      isGTActingAsDataProcessor:
        questionDTOMap.Q0040.state.isShown &&
        questionDTOMap.Q0040.data.userValue === Constants.YesNo.Yes,
      isGTWorkingInClientSystem:
        questionDTOMap.Q0039.state.isShown &&
        questionDTOMap.Q0039.data.userValue === Constants.YesNo.Yes,
      isInactiveOptionForDigitalSigning:
        questionDTOMap.Q0017.state.isShown &&
        questionDTOMap.Q0017.data.userValue === Constants.YesNo.No,
      isIntervalFormatOfTheFee:
        questionDTOMap.Q0042.state.isShown &&
        questionDTOMap.Q0042.data.userValue ===
          Constants.FeeExpression.IntervalFee,
      isOtherEngagementCounseling:
        questionDTOMap.Q0038.state.isShown &&
        questionDTOMap.Q0038.data.userValue ===
          Constants.CounselingAssignment.Other,
      isPeriodFormatOfTheFee:
        questionDTOMap.Q0042.state.isShown &&
        questionDTOMap.Q0042.data.userValue ===
          Constants.FeeExpression.PeriodFee,
      isProjectDescriptionAttached:
        questionDTOMap.Q0041.state.isShown &&
        questionDTOMap.Q0041.data.userValue === Constants.YesNo.Yes,
      organisationNo: formatOrganisationNo(
        globalState.currentConfiguration.organisationNumber
      ),
      postalNumber: globalState.generalVariables.postalNumber,
      validityPeriod: questionDTOMap.Q0031.state.isShown,
      validFrom: formatDate(
        globalState.currentConfiguration.validFromDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      ),
      validUntil: formatDate(
        globalState.currentConfiguration.validUntilDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      ),
    };

  return templateDataEngagementLetter;
}

function parseContactPersonList(
  contactPersonList: string[] | CoreInterfaces.SigneeDetailsDocxPack[],
  separator: string
) {
  if (contactPersonList.length === 0) return "";

  const getName = (item: string | CoreInterfaces.SigneeDetailsDocxPack) =>
    typeof item === "string" ? item : item.Name;

  const allButLast = contactPersonList.slice(0, -1).map(getName).join(", ");
  const last = getName(contactPersonList[contactPersonList.length - 1]);

  return allButLast + (allButLast ? ` ${separator} ` : "") + last;
}

export function handleTemplateEngagementLetterAppendix(
  globalState: CoreInterfaces.AppState
) {
  const isService7Active = globalState.services.find(
    (service) =>
      service.data.code ===
        Constants.ServiceCode.PayrollAndExpenseAndTravelInvoiceManagement &&
      service.state.isSelected
  );
  const isFirst6ServicesActive = globalState.services.some(
    (service) =>
      [
        Constants.ServiceCode.AccountsPayable,
        Constants.ServiceCode.AccountsReceivable,
        Constants.ServiceCode.CorporateCardManagement,
        Constants.ServiceCode.OtherAccountAndReconciliation,
        Constants.ServiceCode.PeriodReporting,
        Constants.ServiceCode.InterimListedCompanies,
        Constants.ServiceCode.AnnualReporting,
        Constants.ServiceCode.AnnualReportingListedCompanies,
      ].includes(service.data.code) && service.state.isSelected
  );
  const isService10Active = globalState.services.find(
    (service) =>
      Constants.ServiceCode.EngagementCounseling === service.data.code &&
      service.state.isSelected
  );
  const templateDataEngagementLetter = {
    CompanyName: globalState.currentConfiguration.companyName,
    Address: globalState.generalVariables.address,
    PostalNumber: globalState.generalVariables.postalNumber,
    Area: globalState.generalVariables.area,
    OrganisationNo: formatOrganisationNo(
      globalState.currentConfiguration.organisationNumber
    ),
    CurrentYear: new Date().getFullYear(),
    isService7Active: !!isService7Active,
    isFirst6ServicesOr10Active: isFirst6ServicesActive || !!isService10Active,
  };
  return templateDataEngagementLetter;
}
